import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";
import { RootState } from "../store";
import {
  EditMaintainPerfectContentRequest,
  MaintainPerfectContentFilterParameters,
} from "../../types/api/maintain-perfect";
import { AddActionPayload, UpdateActionPayload } from "../../types/common";

export const fetchContentList = createAsyncThunk("@@maintainPerfect/fetchContentList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.maintainPerfect.getList(state.maintainPerfect.parameters);
  return response.data;
});

export const setParameters = createAction(
  "@@maintainPerfect/setParameters",
  (payload: MaintainPerfectContentFilterParameters) => {
    return {
      payload,
    };
  },
);

export const fetchContent = createAsyncThunk("@@maintainPerfect/fetchContent", async (payload: UniqueId) => {
  const response = await api.maintainPerfect.get(payload);
  return response.data;
});

export const addContent = createAsyncThunk(
  "@@maintainPerfect/addContent",
  async (payload: AddActionPayload<EditMaintainPerfectContentRequest>) => {
    const response = await api.maintainPerfect.add(payload.data);
    return response.data;
  },
);

export const updateContent = createAsyncThunk(
  "@@maintainPerfect/updateContent",
  async (payload: UpdateActionPayload<EditMaintainPerfectContentRequest>) => {
    const response = await api.maintainPerfect.update(payload.id, payload.data);
    return response.data;
  },
);
