import { ChangeEvent, FC, MutableRefObject, useRef } from "react";
import { ToolProps } from "@progress/kendo-react-editor/dist/es/tools/ToolProps";
import { Button } from "@progress/kendo-react-buttons";
import { EditorUtils, EditorToolsSettings } from "@progress/kendo-react-editor";
import { insertImageFiles, ValidateImage } from "../../plugins/image";

const settings = EditorToolsSettings.image;

const extensions = [".jpeg", ".jpg", ".png", ".tiff", ".bmp", ".webp"];

export interface InsertImageToolsProps extends ToolProps {
  validate?: ValidateImage;
}

const InsertImage: FC<InsertImageToolsProps> = (props) => {
  const { view } = props;
  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const state = view && view.state;
  const nodeType = state ? state.schema.nodes[settings.node] : undefined;
  const disabled = !nodeType || !state || !EditorUtils.canInsert(state, nodeType);

  const handleInsertClick = () => {
    if (!disabled) {
      fileInputRef.current.click();
    }
  };

  const handleFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file && nodeType && view) {
      if (props.validate && !props.validate(file)) {
        return;
      }
      insertImageFiles({
        view,
        nodeType,
        file,
        attrs: { width: 500, alt: file.name },
      });
    }
    fileInputRef.current.value = "";
  };

  return (
    <>
      <Button onClick={handleInsertClick} disabled={disabled} {...settings.props} />
      <input
        ref={fileInputRef}
        hidden
        multiple
        type="file"
        accept={extensions.join(",")}
        onChange={handleFilesChange}
      />
    </>
  );
};

export default InsertImage;
