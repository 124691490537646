import { CommentDTO, CommentsFilterParameters, CommentSortField } from "../../types/api/comments";
import { LoadingState } from "../../types/common";
import { SortOrder } from "../../types/api/common";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { deleteCommentsList, fetchCommentsList, setIds, setParameters } from "../actions/comments";

type CommentsStateLoadingKeys = "fetchCommentsList" | "deleteCommentsList";
type CommentsLoadingState = LoadingState<CommentsStateLoadingKeys>;

interface CommentsState {
  comments: CommentDTO[];
  parameters: CommentsFilterParameters;
  totalPages: number;
  totalItems: number;
  ids: UniqueId[];
  loading: CommentsLoadingState;
}

const initialState: CommentsState = {
  comments: [],
  parameters: {
    page: 1,
    size: 10,
    sort: CommentSortField.CreationDate,
    order: SortOrder.Desc,
    userId: null,
    articleId: null,
  },
  totalPages: 0,
  totalItems: 0,
  ids: [],
  loading: {},
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommentsList.pending, (state) => {
        state.loading = { ...state.loading, fetchCommentsList: true };
      })
      .addCase(fetchCommentsList.fulfilled, (state, action) => {
        state.comments = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.ids = [];
        state.loading = { ...state.loading, fetchCommentsList: false };
      })
      .addCase(fetchCommentsList.rejected, (state) => {
        state.comments = [];
        state.loading = { ...state.loading, fetchCommentsList: false };
      })
      .addCase(deleteCommentsList.pending, (state) => {
        state.loading = { ...state.loading, deleteCommentsList: true };
      })
      .addCase(deleteCommentsList.fulfilled, (state) => {
        state.loading = { ...state.loading, deleteCommentsList: false };
      })
      .addCase(deleteCommentsList.rejected, (state) => {
        state.loading = { ...state.loading, deleteCommentsList: false };
      })
      .addCase(setParameters, (state, action) => {
        state.parameters = action.payload;
      })
      .addCase(setIds, (state, action) => {
        state.ids = action.payload;
      });
  },
});

export const commentsStateSelector = (state: RootState) => state.comments;

export default commentsSlice.reducer;
