import { FC, FormEvent, useState } from "react";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { questionsStateSelector } from "../../../../../store/reducers/questions";
import {
  activateQuestionsList,
  deactivateQuestionsList,
  fetchQuestionsList,
  setParameters,
} from "../../../../../store/actions/questions";
import { Box, Button, Grid } from "@mui/material";
import SearchField from "../../../../../ui-components/search-field/SearchField";
import { NavLink } from "react-router-dom";
import urlTemplate from "../../../../../url-template/urlTemplate";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import DownloadButton from "../../../../../ui-components/download-button/DownloadButton";
import api from "../../../../../services/api";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

interface State {
  openActivateQuestionsDialog: boolean;
  openDeactivateQuestionsDialog: boolean;
}

const initialState: State = {
  openActivateQuestionsDialog: false,
  openDeactivateQuestionsDialog: false,
};

const QuestionsPageHeader: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { parameters, ids, loading } = useTypedSelector(questionsStateSelector);
  const actionsDisabled = ids.length === 0;

  const handleSearchStart = () => {
    dispatch(fetchQuestionsList());
  };

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearchStart();
  };

  const handleSearchChange = (search: string) => {
    dispatch(setParameters({ ...parameters, search, page: 1 }));
  };

  const handleActivateConfirm = () => {
    dispatch(activateQuestionsList());
    handleActivateClose();
  };

  const handleActivateClose = () => {
    setState({ ...state, openActivateQuestionsDialog: false });
  };

  const handleDeactivateConfirm = () => {
    dispatch(deactivateQuestionsList());
    handleDeactivateClose();
  };

  const handleDeactivateClose = () => {
    setState({ ...state, openDeactivateQuestionsDialog: false });
  };

  return (
    <PageHeader breadCrumbs={[urlTemplate.questions.main]}>
      <Grid
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => setState({ ...state, openDeactivateQuestionsDialog: true })}
          >
            Hide
          </Button>
          <Button
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => setState({ ...state, openActivateQuestionsDialog: true })}
          >
            Display
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <DownloadButton func={api.documents.getAnswersDocument} title="Download answers excel document" />
          <Box component="form" noValidate onSubmit={handleSearchSubmit}>
            <SearchField
              placeholder="Question"
              value={parameters.search as string}
              handleChange={handleSearchChange}
              handleSearchStart={handleSearchStart}
              delayMillis={500}
              startSearchLength={2}
            />
          </Box>
          <Button variant="contained" component={NavLink} to={urlTemplate.questions.create.value}>
            Add New
          </Button>
        </Grid>
        <ConfirmDialog
          open={!!(state.openActivateQuestionsDialog || loading.activateQuestionsList)}
          handleClose={handleActivateClose}
          handleConfirm={handleActivateConfirm}
          loading={loading.activateQuestionsList}
          header="Display questions"
          body="Are you sure you want to display the selected questions?"
        />
        <ConfirmDialog
          open={!!(state.openDeactivateQuestionsDialog || loading.deactivateQuestionsList)}
          handleClose={handleDeactivateClose}
          handleConfirm={handleDeactivateConfirm}
          loading={loading.deactivateQuestionsList}
          header="Hide questions"
          body="Are you sure you want to hide the selected questions?"
        />
      </Grid>
    </PageHeader>
  );
};

export default QuestionsPageHeader;
