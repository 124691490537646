import { FC, ReactNode } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

export interface LoaderProps {
  children?: ReactNode;
}

const Loader: FC<LoaderProps> = (props) => {
  return (
    <Grid
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
        zIndex: 1,
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <CircularProgress />
        {props.children || <Typography>Loading...</Typography>}
      </Grid>
    </Grid>
  );
};

export default Loader;
