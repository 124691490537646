import { LoadingState } from "../../types/common";
import { UserDTO, UsersFilterParameters, UsersSortField } from "../../types/api/users";
import { createSlice } from "@reduxjs/toolkit";
import {
  deactivateUser,
  deactivateUsersList,
  fetchUser,
  fetchUsersList,
  reactivateUser,
  reactivateUsersList,
  setIds,
  setParameters,
} from "../actions/users";
import { RootState } from "../store";
import { SortOrder } from "../../types/api/common";

type UsersStateLoadingKeys =
  | "fetchUsersList"
  | "fetchUser"
  | "reactivateUsersList"
  | "reactivateUser"
  | "deactivateUsersList"
  | "deactivateUser";
type UsersLoadingState = LoadingState<UsersStateLoadingKeys>;

interface UsersState {
  users: UserDTO[];
  user: UserDTO | null;
  parameters: UsersFilterParameters;
  totalPages: number;
  totalItems: number;
  ids: UniqueId[];
  loading: UsersLoadingState;
}

const initialState: UsersState = {
  users: [],
  user: null,
  parameters: {
    page: 1,
    size: 10,
    sort: UsersSortField.Id,
    order: SortOrder.Asc,
    search: "",
    active: null,
    subscriptionActive: null,
  },
  totalPages: 0,
  totalItems: 0,
  ids: [],
  loading: {},
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersList.pending, (state) => {
        state.loading = { ...state.loading, fetchUsersList: true };
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.users = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.ids = [];
        state.loading = { ...state.loading, fetchUsersList: false };
      })
      .addCase(fetchUsersList.rejected, (state) => {
        state.users = [];
        state.loading = { ...state.loading, fetchUsersList: false };
      })
      .addCase(fetchUser.pending, (state) => {
        state.user = null;
        state.loading = { ...state.loading, fetchUser: true };
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = { ...state.loading, fetchUser: false };
      })
      .addCase(fetchUser.rejected, (state) => {
        state.user = null;
        state.loading = { ...state.loading, fetchUser: false };
      })
      .addCase(reactivateUsersList.pending, (state) => {
        state.loading = { ...state.loading, reactivateUsersList: true };
      })
      .addCase(reactivateUsersList.fulfilled, (state, action) => {
        action.payload.forEach((x) => {
          if (!x.success) {
            return;
          }
          const user = state.users.find((user) => user.id === x.id);
          user && (user.active = true);
        });
        state.ids = [];
        state.loading = { ...state.loading, reactivateUsersList: false };
      })
      .addCase(reactivateUsersList.rejected, (state) => {
        state.loading = { ...state.loading, reactivateUsersList: false };
      })
      .addCase(deactivateUsersList.pending, (state) => {
        state.loading = { ...state.loading, deactivateUsersList: true };
      })
      .addCase(deactivateUsersList.fulfilled, (state, action) => {
        action.payload.forEach((x) => {
          if (!x.success) {
            return;
          }
          const user = state.users.find((user) => user.id === x.id);
          user && (user.active = false);
        });
        state.ids = [];
        state.loading = { ...state.loading, deactivateUsersList: false };
      })
      .addCase(deactivateUsersList.rejected, (state) => {
        state.loading = { ...state.loading, deactivateUsersList: false };
      })
      .addCase(reactivateUser.pending, (state) => {
        state.loading = { ...state.loading, reactivateUser: true };
      })
      .addCase(reactivateUser.fulfilled, (state, action) => {
        if (state.user && action.payload.success) {
          state.user.active = true;
        }
        state.loading = { ...state.loading, reactivateUser: false };
      })
      .addCase(reactivateUser.rejected, (state) => {
        state.loading = { ...state.loading, reactivateUser: false };
      })
      .addCase(deactivateUser.pending, (state) => {
        state.loading = { ...state.loading, deactivateUser: true };
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        if (state.user && action.payload.success) {
          state.user.active = false;
        }
        state.loading = { ...state.loading, deactivateUser: false };
      })
      .addCase(deactivateUser.rejected, (state) => {
        state.loading = { ...state.loading, deactivateUser: false };
      })
      .addCase(setParameters, (state, action) => {
        state.parameters = action.payload;
      })
      .addCase(setIds, (state, action) => {
        state.ids = action.payload;
      });
  },
});

export const usersStateSelector = (state: RootState) => state.users;

export default usersSlice.reducer;
