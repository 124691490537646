import { FC } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination as MuiPagination,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { HandleChange } from "../../types/common";

export interface PaginationParameters {
  page: number;
  size: number;
}

export interface PaginationProps {
  page: number;
  size: number;
  totalPages: number;
  totalItems: number;
  handleChange: HandleChange<PaginationParameters>;

  pageSizeOptions?: number[];
  loading?: boolean;
}

const defaultPageSizeOptions = [5, 10, 15, 20, 25];

const Pagination: FC<PaginationProps> = (props) => {
  const pageSizeOptions = props.pageSizeOptions || defaultPageSizeOptions;

  const handlePageChange = (page: number) => {
    if (page !== props.page) {
      props.handleChange({ page: page, size: props.size });
    }
  };

  const handlePageSizeChange = (e: SelectChangeEvent) => {
    const size = Number(e.target.value);
    props.handleChange({ size, page: 1 });
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        padding: "16px 8px",
      }}
    >
      <FormControl>
        <InputLabel>Page size</InputLabel>
        <Select
          sx={{
            width: "100px",
          }}
          label="Page size"
          value={String(props.size)}
          onChange={handlePageSizeChange}
        >
          {pageSizeOptions.map((x) => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MuiPagination
        count={props.totalPages}
        page={props.page}
        onChange={(_, page) => handlePageChange(page)}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        disabled={props.loading}
      />
      <Typography variant="body2">{`Total items: ${props.totalItems}`}</Typography>
    </Grid>
  );
};

export default Pagination;
