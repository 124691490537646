import { FC, useEffect } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import MaintainPerfectPageHeader from "./header/MaintainPerfectPageHeader";
import MaintainPerfectContentTable from "./table/MaintainPerfectContentTable";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import { fetchContentList } from "../../../../store/actions/maintain-perfect";

const MaintainPerfectPage: FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchContentList());
  }, [dispatch]);

  return (
    <PageContainer>
      <MaintainPerfectPageHeader />
      <MaintainPerfectContentTable />
    </PageContainer>
  );
};

export default MaintainPerfectPage;
