import { CommentDTO, CommentsFilterParameters } from "../../types/api/comments";
import { AxiosResponse } from "axios";
import { DeleteEntityResponse, PageResponse } from "../../types/api/common";
import client from "./client/client";

export class CommentsApiService {
  async getList(parameters: CommentsFilterParameters): Promise<AxiosResponse<PageResponse<CommentDTO>>> {
    return await client.get("/api/v1/admin/comment/list", {
      params: parameters,
    });
  }

  async delete(ids: UniqueId[]): Promise<AxiosResponse<DeleteEntityResponse[]>> {
    return await client.delete("/api/v1/admin/comment", {
      params: {
        ids: ids.join(","),
      },
    });
  }
}

const commentApiService = new CommentsApiService();

export default commentApiService;
