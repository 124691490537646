import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e4ae95",
    },
    secondary: {
      main: "#882b24",
    },
  },
  typography: {
    fontFamily: "Poppins, serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: "none",
        },
        outlined: {
          textTransform: "none",
        },
        contained: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
