import { PageRequest } from "./common";

export interface QuoteDTO {
  id: UniqueId;
  textEn: string;
  textEs: string;
  editingDate: DateString;
}

export interface EditQuoteRequest {
  textEn: string;
  textEs: string;
}

export enum QuoteSortField {
  EditingDate = "editingDate",
}

export type QuotesFilterParameters = PageRequest<QuoteSortField>;
