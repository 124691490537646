import { PageRequest } from "./common";

export interface UserDTO {
  id: UniqueId;
  email: Email;
  firstname: string;
  country: string;
  city: string;
  registrationDate: DateString;
  active: boolean;
  subscriptionActive: boolean;
}

export enum UsersSortField {
  Id = "id",
  Firstname = "firstname",
  Country = "country",
}

export interface UsersFilterParameters extends PageRequest<UsersSortField> {
  search?: string;
  active: boolean | null;
  subscriptionActive: boolean | null;
}
