import { AnswerForm } from "./QuestionForm";
import { DaySegmentedAnswerContentDTO } from "../../../../../types/api/question";
import AnswerFormFields from "./AnswerFormFields";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

const DaySegmentedAnswerForm: AnswerForm = ({ formik }) => {
  return (
    <>
      <AnswerFormFields<DaySegmentedAnswerContentDTO>
        type={formik.values.answerType}
        formik={formik}
        fieldRows={[
          {
            step: {
              type: "number",
              label: "Value Of Step",
            },
            maxStep: {
              type: "number",
              label: "Value Of Maximum Step",
            },
          },
          {
            unitNameEn: {
              label: "Unit Label (EN)",
            },
            unitNameEs: {
              label: "Unit Label (ES)",
            },
          },
        ]}
        renderOther={({ values, getFieldName }) => (
          <FormControl>
            <FormLabel>All Days</FormLabel>
            <RadioGroup name={getFieldName("directly")} value={values.directly} onChange={formik.handleChange} row>
              <FormControlLabel control={<Radio />} label="Bad" value={false} />
              <FormControlLabel control={<Radio />} label="Good" value={true} />
            </RadioGroup>
          </FormControl>
        )}
      />
    </>
  );
};

export default DaySegmentedAnswerForm;
