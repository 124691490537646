import { FC, useEffect } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import AcneAttackContentTable from "./table/AcneAttackContentTable";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import { fetchContentList } from "../../../../store/actions/acne-attack";
import PageHeader from "../../../../ui-components/page-header/PageHeader";
import urlTemplate from "../../../../url-template/urlTemplate";

const AcneAttackPage: FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchContentList());
  }, [dispatch]);

  return (
    <PageContainer>
      <PageHeader breadCrumbs={[urlTemplate.acneAttack.main]} />
      <AcneAttackContentTable />
    </PageContainer>
  );
};

export default AcneAttackPage;
