import { EntityOptionDTO, PageRequest } from "./common";

export interface DaySelectorAnswerContentDTO {
  directly: boolean;
}

export interface DaySegmentedAnswerContentDTO {
  step: number;
  maxStep: number;
  unitNameEn: string;
  unitNameEs: string;
  directly: boolean;
}

export interface SliderAnswerContentDTO {
  points: number;
  pointWeight: Percent;
  pointNameEn?: string;
  pointNameEs?: string;
  startPointNameEn: string;
  startPointNameEs: string;
  endPointNameEn: string;
  endPointNameEs: string;
}

export interface DropdownAnswerContentItemDTO {
  weight: Percent;
  textEn: string;
  textEs: string;
}

export interface DropdownAnswerContentDTO {
  items: DropdownAnswerContentItemDTO[];
}

export interface FreeTextAnswerContentDTO {}

export enum CarouselSwipeAnswerContentItemType {
  Clear = "Clear",
  AlmostClear = "AlmostClear",
  Mild = "Mild",
  Moderate = "Moderate",
  Severe = "Severe",
}

export interface CarouselSwipeAnswerContentItemDTO {
  weight: Percent;
  type: CarouselSwipeAnswerContentItemType;
}

export interface CarouselSwipeAnswerContentDTO {
  items: CarouselSwipeAnswerContentItemDTO[];
}

export type AnswerContentDTO =
  | DaySelectorAnswerContentDTO
  | DaySegmentedAnswerContentDTO
  | SliderAnswerContentDTO
  | DropdownAnswerContentDTO
  | FreeTextAnswerContentDTO
  | CarouselSwipeAnswerContentDTO;

export type AnswerItemContentDTO = DropdownAnswerContentItemDTO | CarouselSwipeAnswerContentItemDTO;

export enum AnswerType {
  DaySelector = "DaySelector",
  DaySegmented = "DaySegmented",
  DaySegmentedAllergen = "DaySegmentedAllergen",
  Slider = "Slider",
  Dropdown = "Dropdown",
  FreeText = "FreeText",
  CarouselSwipe = "CarouselSwipe",
}

export interface EditQuestionRequest {
  textEn: string;
  textEs: string;
  hintEn?: string;
  hintEs?: string;
  recommendationEn?: string;
  recommendationEs?: string;
  sequenceNumber: number;
  answerType: AnswerType;
  categoryId: UniqueId;
  articleId?: UniqueId;
  answerContent: AnswerContentDTO;
}

export interface QuestionShortDTO {
  id: UniqueId;
  textEn: string;
  textEs: string;
  categoryTitleEn: string;
  categoryTitleEs: string;
  active: boolean;
  editingDate: DateString;
}

export interface QuestionDetailsDTO {
  id: UniqueId;
  sectionId: UniqueId;
  categoryId: UniqueId;
  articleId?: UniqueId;
  textEn: string;
  textEs: string;
  hintEn: string;
  hintEs: string;
  recommendationEn: string;
  recommendationEs: string;
  answerType: AnswerType;
  active: boolean;
  sequenceNumber: number;
  editingDate: DateString;
  categoryQuestionCount: number;
  answerContent: AnswerContentDTO;
}

export enum QuestionSortField {
  Id = "id",
  Text = "text",
  CategoryTitle = "categoryTitle",
  Status = "active",
  EditingDate = "editingDate",
}

export interface QuestionsFilterParameters extends PageRequest<QuestionSortField> {
  search?: string;
}

export interface QuestionOptionDTO extends EntityOptionDTO {
  active: boolean;
}
