import { FC } from "react";
import {
  MaintainPerfectContentFilterParameters,
  MaintainPerfectContentShortDTO,
  MaintainPerfectContentSortField,
} from "../../../../../types/api/maintain-perfect";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { maintainPerfectStateSelector } from "../../../../../store/reducers/maintain-perfect";
import { fetchContentList, setParameters } from "../../../../../store/actions/maintain-perfect";
import { PaginationParameters } from "../../../../../ui-components/pagination/Pagination";
import Table, { SortParameters, TableColumn } from "../../../../../ui-components/table/Table";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { prettyDate } from "../../../../../utils/date";
import { enumToArray } from "../../../../../utils/enum";
import { useNavigate } from "react-router-dom";
import { getAppUrl } from "../../../../../url-template/urlTemplate";

const MaintainPerfectContentTable: FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { contents, parameters, totalItems, totalPages, loading } = useTypedSelector(maintainPerfectStateSelector);

  const fetchContents = (parameters: MaintainPerfectContentFilterParameters) => {
    dispatch(setParameters(parameters));
    dispatch(fetchContentList());
  };

  const handlePaginationChange = ({ page, size }: PaginationParameters) => {
    fetchContents({ ...parameters, page, size });
  };

  const handleSortChange = ({ sort, order }: SortParameters<MaintainPerfectContentSortField>) => {
    fetchContents({ ...parameters, sort, order });
  };

  const handleRowClick = (item: MaintainPerfectContentShortDTO) => {
    navigate(getAppUrl.maintainPerfect.edit(item.id));
  };

  const columns: TableColumn<MaintainPerfectContentShortDTO>[] = [
    {
      field: "startDate",
      headerName: "Start Date",
      renderCell: (params: GridRenderCellParams) => prettyDate(params.value),
      flex: 0.75,
    },
    {
      field: "topicEn",
      headerName: "Topic (EN)",
      flex: 2,
    },
    {
      field: "topicEs",
      headerName: "Topic (ES)",
      flex: 2,
    },
    {
      field: "relatedArticles",
      headerName: "Related Articles",
      flex: 2.5,
    },
  ];

  return (
    <Table
      sx={{
        mt: "16px",
      }}
      data={contents}
      cols={columns}
      handleSortChange={handleSortChange}
      order={parameters.order}
      sort={parameters.sort}
      sortFields={enumToArray<MaintainPerfectContentSortField>(MaintainPerfectContentSortField)}
      pagination={{
        handlePaginationChange,
        page: parameters.page,
        size: parameters.size,
        totalPages,
        totalItems,
      }}
      loading={loading.fetchContentList}
      handleRowClick={handleRowClick}
    />
  );
};

export default MaintainPerfectContentTable;
