import { FC, ReactNode } from "react";
import { Grid, SxProps } from "@mui/material";

export interface PageContainerProps {
  children: ReactNode;

  sx?: SxProps;
}

const PageContainer: FC<PageContainerProps> = (props) => {
  return (
    <Grid
      component="main"
      sx={{
        width: "100%",
        padding: "24px",
        boxSizing: "border-box",
        ...props.sx,
      }}
    >
      {props.children}
    </Grid>
  );
};

export default PageContainer;
