import useApi, { ApiCall } from "../../hooks/useApi";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { EntityOptionDTO } from "../../types/api/common";
import { HandleChange } from "../../types/common";
import { ReactNode, useEffect } from "react";

export interface SelectEntityFieldProps<T extends EntityOptionDTO> {
  value?: UniqueId | UniqueId[];
  name: string;
  label: string;
  apiCall: ApiCall<T[]>;

  handleEntityChange?: HandleChange<T | T[]>;
  handleIdChange?: SelectInputProps<UniqueId | UniqueId[]>["onChange"];
  handleEntityFetch?: HandleChange<T | T[]>; // called when options are fetched
  multiple?: boolean;
  touched?: boolean;
  error?: string | string[];
  sx?: SxProps;
  renderEntity?: (entity: T) => ReactNode; // custom render of select item
}

const SelectEntityField = <T extends EntityOptionDTO>(props: SelectEntityFieldProps<T>): JSX.Element => {
  const { data: options } = useApi(props.apiCall, []);

  useEffect(() => {
    props.handleEntityFetch && props.handleEntityFetch(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleChange = (e: SelectChangeEvent<UniqueId | UniqueId[]>, child: ReactNode) => {
    if (props.handleIdChange) {
      props.handleIdChange(e, child);
    }
    if (props.handleEntityChange) {
      const value = e.target.value;
      if (Array.isArray(value)) {
        props.handleEntityChange([...options].filter((x) => value.includes(x.id)));
      } else {
        props.handleEntityChange(options.find((x) => x.id === value) as T);
      }
    }
  };

  return (
    <FormControl sx={props.sx} fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        name={props.name}
        label={props.label}
        onChange={handleChange}
        multiple={props.multiple}
        value={props.value}
        fullWidth
        error={!!(props.touched && props.error)}
      >
        {options.map((x) => (
          <MenuItem key={x.id} value={x.id}>
            {props.renderEntity ? props.renderEntity(x) : x.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!(props.touched && props.error)}>{(props.touched && props.error) || " "}</FormHelperText>
    </FormControl>
  );
};

export default SelectEntityField;
