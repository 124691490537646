import { FC, ReactNode, useCallback } from "react";
import {
  AnswerContentDTO,
  AnswerType,
  CarouselSwipeAnswerContentDTO,
  CarouselSwipeAnswerContentItemDTO,
  CarouselSwipeAnswerContentItemType,
  DaySegmentedAnswerContentDTO,
  DaySelectorAnswerContentDTO,
  DropdownAnswerContentDTO,
  DropdownAnswerContentItemDTO,
  FreeTextAnswerContentDTO,
  QuestionDetailsDTO,
  SliderAnswerContentDTO,
} from "../../../../../types/api/question";
import { enumToArray } from "../../../../../utils/enum";
import * as yup from "yup";
import { useFormik } from "formik";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SxProps, TextField } from "@mui/material";
import SelectEntityField from "../../../../common/SelectEntityField";
import api from "../../../../../services/api";
import { CategoryOptionDTO } from "../../../../../types/api/sections";
import { separateCamelCase } from "../../../../../utils/string";
import DaySelectorAnswerForm from "./DaySelectorAnswerForm";
import DaySegmentedAnswerForm from "./DaySegmentedAnswerForm";
import SliderAnswerForm from "./SliderAnswerForm";
import DropdownAnswerForm from "./DropdownAnswerForm";
import FreeTextAnswerForm from "./FreeTextAnswerForm";
import CarouselSwipeAnswerForm from "./CarouselSwipeAnswerForm";
import {
  getBooleanSchema,
  getIdSchema,
  getRequiredStringSchema,
  getPositiveNumberSchema,
  getPercentSchema,
  getOptionalStringSchema, getOptionalIdSchema,
} from "../../../../../validation/schemas";
import { StringSchema } from "yup";
import { ArticleOptionDTO } from "../../../../../types/api/articles";

export interface QuestionFormValuesAnswerContent {
  [AnswerType.DaySelector]: DaySelectorAnswerContentDTO;
  [AnswerType.DaySegmented]: DaySegmentedAnswerContentDTO;
  [AnswerType.DaySegmentedAllergen]: DaySegmentedAnswerContentDTO;
  [AnswerType.Slider]: SliderAnswerContentDTO;
  [AnswerType.Dropdown]: DropdownAnswerContentDTO;
  [AnswerType.FreeText]: FreeTextAnswerContentDTO;
  [AnswerType.CarouselSwipe]: CarouselSwipeAnswerContentDTO;
}

export interface QuestionFormValues {
  textEn: string;
  textEs: string;
  hintEn?: string;
  hintEs?: string;
  recommendationEn?: string;
  recommendationEs?: string;
  sequenceNumber: number;
  answerType: AnswerType;
  categoryId: UniqueId;
  articleId?: UniqueId;
  answerContent: QuestionFormValuesAnswerContent;
  // maxSequenceNumber, categories and articles used only for validation
  maxSequenceNumber: number;
  categories: CategoryOptionDTO[];
  articles: ArticleOptionDTO[];
}

export const getDefaultAnswerContent = (answerType: AnswerType): AnswerContentDTO => {
  switch (answerType) {
    case AnswerType.DaySelector:
      return {
        directly: true,
      } as DaySelectorAnswerContentDTO;
    case AnswerType.DaySegmentedAllergen:
    case AnswerType.DaySegmented: {
      return {
        maxStep: 1,
        step: 1,
        unitNameEn: "",
        unitNameEs: "",
        directly: true,
      } as DaySegmentedAnswerContentDTO;
    }
    case AnswerType.Slider: {
      return {
        points: 1,
        pointWeight: 0,
        startPointNameEn: "",
        startPointNameEs: "",
        endPointNameEn: "",
        endPointNameEs: "",
      } as SliderAnswerContentDTO;
    }
    case AnswerType.Dropdown: {
      return {
        items: [
          ...Array.from({ length: 2 }).map(
            () =>
              ({
                textEn: "",
                textEs: "",
                weight: 0,
              }) as DropdownAnswerContentItemDTO,
          ),
        ],
      } as DropdownAnswerContentDTO;
    }
    case AnswerType.FreeText: {
      return {} as FreeTextAnswerContentDTO;
    }
    case AnswerType.CarouselSwipe: {
      return {
        items: [
          ...enumToArray<CarouselSwipeAnswerContentItemType>(CarouselSwipeAnswerContentItemType).map(
            (x) =>
              ({
                type: x,
                weight: 0,
              }) as CarouselSwipeAnswerContentItemDTO,
          ),
        ],
      } as CarouselSwipeAnswerContentDTO;
    }
  }
};

export const getInitialValues = (question?: QuestionDetailsDTO): QuestionFormValues => {
  return {
    textEn: (question && question.textEn) || "",
    textEs: (question && question.textEs) || "",
    hintEn: (question && question.hintEn) || "",
    hintEs: (question && question.hintEs) || "",
    recommendationEn: (question && question.recommendationEn) || "",
    recommendationEs: (question && question.recommendationEs) || "",
    sequenceNumber: (question && question.sequenceNumber) || 1,
    answerType: (question && question.answerType) || AnswerType.DaySelector,
    categoryId: (question && question.categoryId) || 0,
    articleId: (question && question.articleId) || undefined,
    maxSequenceNumber: (question && question.categoryQuestionCount) || 1,
    answerContent: enumToArray<AnswerType>(AnswerType).reduce((result, type) => {
      return {
        ...result,
        [type]: question && question.answerType === type ? question.answerContent : getDefaultAnswerContent(type),
      };
    }, {} as QuestionFormValuesAnswerContent),
    categories: [],
    articles: [],
  };
};

const getAnswerSchema = (type: AnswerType) => {
  switch (type) {
    case AnswerType.DaySelector: {
      return yup.object().shape({
        directly: getBooleanSchema(),
      });
    }
    case AnswerType.DaySegmentedAllergen:
    case AnswerType.DaySegmented: {
      return yup.object().shape({
        step: getPositiveNumberSchema("Step must be greater than 0"),
        maxStep: getPositiveNumberSchema("Max step must be greater than 0"),
        unitNameEn: getRequiredStringSchema("Unit is required"),
        unitNameEs: getRequiredStringSchema("Unit is required"),
        directly: getBooleanSchema(),
      });
    }
    case AnswerType.Slider: {
      return yup.object().shape({
        points: getPositiveNumberSchema("Number of points must be a positive integer")
          .integer("Number of points must be a positive integer")
          .moreThan(0, "Number of points must be a positive integer"),
        pointWeight: getPercentSchema("Point weight must have a value between 0 and 100"),
        pointNameEn: getOptionalStringSchema(),
        pointNameEs: getOptionalStringSchema(),
        startPointNameEn: getRequiredStringSchema("Name of the point for the start of the slider is required"),
        startPointNameEs: getRequiredStringSchema("Name of the point for the start of the slider is required"),
        endPointNameEn: getRequiredStringSchema("Name of the point for the end of the slider is required"),
        endPointNameEs: getRequiredStringSchema("Name of the point for the end of the slider is required"),
      });
    }
    case AnswerType.Dropdown: {
      return yup.object().shape({
        items: yup
          .array()
          .of(
            yup.object().shape({
              weight: getPercentSchema("Weight must have a value between 0 and 100"),
              textEn: getRequiredStringSchema("Option text is required"),
              textEs: getRequiredStringSchema("Option text is required"),
            }),
          )
          .required()
          .min(2),
      });
    }
    case AnswerType.FreeText: {
      return yup.object();
    }
    case AnswerType.CarouselSwipe: {
      return yup.object().shape({
        items: yup
          .array()
          .of(
            yup.object().shape({
              weight: getPercentSchema("Weight must have a value between 0 and 100"),
              type: yup.string().required().oneOf(enumToArray(CarouselSwipeAnswerContentItemType)),
            }),
          )
          .required()
          .length(5),
      });
    }
  }
};

const textSchema: StringSchema = getRequiredStringSchema("Question text is required").max(
  250,
  "The length of the text must be no more than 250 characters",
);

export const validationSchema = yup.object().shape({
  textEn: textSchema,
  textEs: textSchema,
  hintEn: getOptionalStringSchema(),
  hintEs: getOptionalStringSchema(),
  recommendationEn: getOptionalStringSchema(),
  recommendationEs: getOptionalStringSchema(),
  sequenceNumber: yup
    .number()
    .required("Question sequence number is required")
    .moreThan(0, "Question sequence number must be greater than zero")
    .integer("Question sequence number must be an integer")
    .test({
      name: "maxSequenceNumber",
      test: (value, { parent }) => value <= parent.maxSequenceNumber,
      message: "Sequence number cannot be greater than category question count",
    }),
  answerType: yup.string().required().oneOf(enumToArray(AnswerType)),
  categoryId: getIdSchema("You must select a category"),
  articleId: getOptionalIdSchema(),
  answerContent: yup.lazy((_, { parent: { answerType } }) => {
    return yup.object().shape({
      ...enumToArray<AnswerType>(AnswerType).reduce((result, type) => {
        return {
          ...result,
          [type]: type === answerType ? getAnswerSchema(type) : yup.object({}),
        };
      }, {}),
    });
  }),
});

export type QuestionFormikHelpers = ReturnType<typeof useFormik<QuestionFormValues>>;

export type AnswerForm = FC<{ formik: QuestionFormikHelpers }>;

const answerForms: Record<AnswerType, AnswerForm> = {
  [AnswerType.DaySelector]: DaySelectorAnswerForm,
  [AnswerType.DaySegmented]: DaySegmentedAnswerForm,
  [AnswerType.DaySegmentedAllergen]: DaySegmentedAnswerForm,
  [AnswerType.Slider]: SliderAnswerForm,
  [AnswerType.Dropdown]: DropdownAnswerForm,
  [AnswerType.FreeText]: FreeTextAnswerForm,
  [AnswerType.CarouselSwipe]: CarouselSwipeAnswerForm,
} as Record<AnswerType, AnswerForm>;

interface Props {
  formik: QuestionFormikHelpers;
  question: QuestionDetailsDTO | null;

  children?: ReactNode;
  sx?: SxProps;
}

const QuestionForm: FC<Props> = (props) => {
  const { formik, question } = props;
  const CurrentAnswerForm = answerForms[formik.values.answerType];

  const fetchCategoryOptions = useCallback(() => {
    return api.sections.getCategoryOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const handleCategoryChange = (option: CategoryOptionDTO) => {
    let sequenceNumber = option.questionsCount + 1;
    let maxSequenceNumber = option.questionsCount + 1;
    if (question && question.categoryId === option.id) {
      sequenceNumber = question.sequenceNumber;
      maxSequenceNumber = question.categoryQuestionCount;
    }
    formik.setValues(
      {
        ...formik.values,
        sequenceNumber,
        maxSequenceNumber,
        categoryId: option.id,
      },
      true,
    );
  };

  return (
    <Box sx={props.sx} component="form" noValidate onSubmit={formik.handleSubmit}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "32px",
          width: "100%",
        }}
      >
        <SelectEntityField<CategoryOptionDTO>
          sx={{
            flex: 1,
          }}
          value={formik.values.categoryId}
          name="categoryId"
          label="Display in category"
          handleEntityChange={(option) => handleCategoryChange(option as CategoryOptionDTO)}
          apiCall={fetchCategoryOptions}
          touched={formik.touched.categoryId}
          error={formik.errors.categoryId}
          renderEntity={(x) => (x.active ? x.title : `${x.title} (hidden)`)}
          handleEntityFetch={(x) => formik.setFieldValue("categories", x)}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          name="sequenceNumber"
          label={`Sequence Number (for selected category maximum: ${formik.values.maxSequenceNumber})`}
          type="number"
          value={formik.values.sequenceNumber}
          onChange={formik.handleChange}
          error={!!(formik.touched.sequenceNumber && formik.errors.sequenceNumber)}
          helperText={formik.errors.sequenceNumber || " "}
        />
        <SelectEntityField
          sx={{
            flex: 1,
          }}
          value={formik.values.articleId || 0}
          name="articleId"
          label="Link Article"
          handleIdChange={formik.handleChange}
          apiCall={api.articles.getOptions}
          touched={formik.touched.articleId}
          error={formik.errors.articleId}
          renderEntity={(x) => (x.published ? x.title : `${x.title} (draft)`)}
          handleEntityFetch={(x) => formik.setFieldValue("articles", x)}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "32px",
          width: "100%",
          mt: "16px",
        }}
      >
        <TextField
          sx={{
            flex: 1,
          }}
          name="textEn"
          label="Question Text (EN)"
          value={formik.values.textEn}
          onChange={formik.handleChange}
          error={!!(formik.touched.textEn && formik.errors.textEn)}
          helperText={formik.errors.textEn || " "}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          name="textEs"
          label="Question Text (ES)"
          value={formik.values.textEs}
          onChange={formik.handleChange}
          error={!!(formik.touched.textEs && formik.errors.textEs)}
          helperText={formik.errors.textEs || " "}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "32px",
          width: "100%",
          mt: "16px",
        }}
      >
        <TextField
          sx={{
            flex: 1,
          }}
          name="hintEn"
          label="Display Hint (EN)"
          rows={3}
          multiline
          value={formik.values.hintEn}
          onChange={formik.handleChange}
          error={!!(formik.touched.hintEn && formik.errors.hintEn)}
          helperText={formik.errors.hintEn || " "}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          name="hintEs"
          label="Display Hint (ES)"
          rows={3}
          multiline
          value={formik.values.hintEs}
          onChange={formik.handleChange}
          error={!!(formik.touched.hintEs && formik.errors.hintEs)}
          helperText={formik.errors.hintEs || " "}
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "32px",
          width: "100%",
          mt: "16px",
        }}
      >
        <TextField
          sx={{
            flex: 1,
          }}
          name="recommendationEn"
          label="Instant Recommendation (EN)"
          rows={3}
          multiline
          value={formik.values.recommendationEn}
          onChange={formik.handleChange}
          error={!!(formik.touched.recommendationEn && formik.errors.recommendationEn)}
          helperText={formik.errors.recommendationEn || " "}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          name="recommendationEs"
          label="Instant Recommendation (ES)"
          rows={3}
          multiline
          value={formik.values.recommendationEs}
          onChange={formik.handleChange}
          error={!!(formik.touched.recommendationEs && formik.errors.recommendationEs)}
          helperText={formik.errors.recommendationEs || " "}
        />
      </Grid>
      <FormControl
        sx={{
          width: "50%",
          mt: "16px",
        }}
      >
        <InputLabel>Answer Type</InputLabel>
        <Select name="answerType" label="AnswerType" onChange={formik.handleChange} value={formik.values.answerType}>
          {enumToArray(AnswerType).map((x) => (
            <MenuItem key={x} value={x}>
              {separateCamelCase(x as string)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "24px",
          width: "50%",
        }}
      >
        <CurrentAnswerForm formik={formik} />
      </Grid>
      {props.children}
    </Box>
  );
};

export default QuestionForm;
