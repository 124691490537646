import { FC, useEffect } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import { fetchSectionsList } from "../../../../store/actions/sections";
import SectionsPageHeader from "./header/SectionsPageHeader";
import SectionsTable from "./table/SectionsTable";

const SectionsPage: FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchSectionsList());
  }, [dispatch]);

  return (
    <PageContainer>
      <SectionsPageHeader />
      <SectionsTable />
    </PageContainer>
  );
};

export default SectionsPage;
