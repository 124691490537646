import { EntityOptionDTO, PageRequest } from "./common";

export interface ArticleShortDTO {
  id: UniqueId;
  titleEn: string;
  titleEs: string;
  published: boolean;
  editingDate: DateString;
}

export interface ArticleDetailsDTO {
  id: UniqueId;
  titleEn: string;
  titleEs: string;
  contentEn: string;
  contentEs: string;
  linkEn: Link;
  linkEs: Link;
  published: boolean;
  editingDate: DateString;
  questions: EntityOptionDTO[];
  previewFilename: Filename;
}

export interface EditArticleRequest {
  titleEn: string;
  titleEs: string;
  contentEn: string;
  contentEs: string;
  linkEn: Link;
  linkEs: Link;
  questionIds: UniqueId[];
  publish: boolean | null;
}

export enum ArticleSortField {
  Published = "published",
  EditingDate = "editingDate",
  Title = "title",
}

export interface ArticlesFilterParameters extends PageRequest<ArticleSortField> {
  search?: string;
}

export interface ArticleOptionDTO extends EntityOptionDTO {
  published: boolean;
}
