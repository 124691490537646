import {
  CategoryOptionDTO,
  EditSectionRequest,
  SectionDetailsDTO,
  SectionsFilterParameters,
  SectionShortDTO,
} from "../../types/api/sections";
import { AxiosResponse } from "axios";
import { PageResponse, UpdateEntityStatusResponse } from "../../types/api/common";
import client from "./client/client";

export class SectionsApiService {
  async getList(parameters: SectionsFilterParameters): Promise<AxiosResponse<PageResponse<SectionShortDTO>>> {
    return await client.get("/api/v1/admin/section/list", {
      params: parameters,
    });
  }

  async getCategoryOptions(title?: string): Promise<AxiosResponse<CategoryOptionDTO[]>> {
    return await client.get("/api/v1/admin/section/category/options", {
      params: { title },
    });
  }

  async get(id: UniqueId): Promise<AxiosResponse<SectionDetailsDTO>> {
    return await client.get(`/api/v1/admin/section/${id}`);
  }

  async add(request: EditSectionRequest): Promise<AxiosResponse<SectionDetailsDTO>> {
    return await client.post("/api/v1/admin/section", request);
  }

  async update(id: UniqueId, request: EditSectionRequest): Promise<AxiosResponse<SectionDetailsDTO>> {
    return await client.post(`/api/v1/admin/section/${id}`, request);
  }

  async activate(ids: UniqueId[]): Promise<AxiosResponse<UpdateEntityStatusResponse[]>> {
    return await client.post("/api/v1/admin/section/activate", ids);
  }

  async deactivate(ids: UniqueId[]): Promise<AxiosResponse<UpdateEntityStatusResponse[]>> {
    return await client.post("/api/v1/admin/section/deactivate", ids);
  }
}

const sectionsApiService = new SectionsApiService();

export default sectionsApiService;
