import { LoadingState } from "../../types/common";
import { QuoteDTO, QuotesFilterParameters, QuoteSortField } from "../../types/api/quote";
import { SortOrder } from "../../types/api/common";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  addQuote,
  deleteQuotesList,
  fetchQuotesList,
  selectQuote,
  setIds,
  setParameters,
  updateQuote,
} from "../actions/quotes";

type QuotesStateLoadingKeys = "fetchQuoteList" | "addQuote" | "updateQuote" | "deleteQuotesList";
type QuotesLoadingState = LoadingState<QuotesStateLoadingKeys>;

interface QuotesState {
  quotes: QuoteDTO[];
  selectedQuote: QuoteDTO | null;
  parameters: QuotesFilterParameters;
  totalPages: number;
  totalItems: number;
  ids: UniqueId[];
  loading: QuotesLoadingState;
}

const initialState: QuotesState = {
  quotes: [],
  selectedQuote: null,
  parameters: {
    page: 1,
    size: 10,
    sort: QuoteSortField.EditingDate,
    order: SortOrder.Desc,
  },
  totalPages: 0,
  totalItems: 0,
  ids: [],
  loading: {},
};

const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotesList.pending, (state) => {
        state.loading = { ...state.loading, fetchQuoteList: true };
      })
      .addCase(fetchQuotesList.fulfilled, (state, action) => {
        state.quotes = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.ids = [];
        state.loading = { ...state.loading, fetchQuoteList: false };
      })
      .addCase(fetchQuotesList.rejected, (state) => {
        state.quotes = [];
        state.loading = { ...state.loading, fetchQuoteList: false };
      })
      .addCase(addQuote.pending, (state) => {
        state.loading = { ...state.loading, addQuote: true };
      })
      .addCase(addQuote.fulfilled, (state) => {
        state.loading = { ...state.loading, addQuote: false };
      })
      .addCase(addQuote.rejected, (state) => {
        state.loading = { ...state.loading, addQuote: false };
      })
      .addCase(updateQuote.pending, (state) => {
        state.loading = { ...state.loading, updateQuote: true };
      })
      .addCase(updateQuote.fulfilled, (state, action) => {
        state.quotes = state.quotes.map((x) => {
          if (x.id === action.payload.id) {
            return action.payload;
          }
          return x;
        });
        state.loading = { ...state.loading, updateQuote: false };
      })
      .addCase(updateQuote.rejected, (state) => {
        state.loading = { ...state.loading, updateQuote: false };
      })
      .addCase(deleteQuotesList.pending, (state) => {
        state.loading = { ...state.loading, deleteQuotesList: true };
      })
      .addCase(deleteQuotesList.fulfilled, (state) => {
        state.loading = { ...state.loading, deleteQuotesList: false };
      })
      .addCase(deleteQuotesList.rejected, (state) => {
        state.loading = { ...state.loading, deleteQuotesList: false };
      })
      .addCase(setParameters, (state, action) => {
        state.parameters = action.payload;
      })
      .addCase(setIds, (state, action) => {
        state.ids = action.payload;
      })
      .addCase(selectQuote, (state, action) => {
        state.selectedQuote = action.payload;
      });
  },
});

export const quotesSelectorState = (state: RootState) => state.quotes;

export default quotesSlice.reducer;
