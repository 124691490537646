import { FC, ReactNode, useState } from "react";
import { Checkbox, Grid, IconButton, Menu, MenuItem, SxProps } from "@mui/material";
import { HandleChange } from "../../types/common";
import { FilterAlt } from "@mui/icons-material";

export interface FilterMenuItem {
  value: any;
  label: ReactNode;
}

export interface FilterMenuProps {
  items: FilterMenuItem[];
  isItemSelected: (item: FilterMenuItem) => boolean;
  handleChange: HandleChange<FilterMenuItem>;

  title?: string;
  active?: boolean;
  sx?: SxProps;
}

const FilterMenu: FC<FilterMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Grid sx={props.sx}>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        title={props.title}
        sx={
          props.active
            ? {}
            : {
                "& svg": {
                  opacity: 0.25,
                },
                ":hover svg": {
                  opacity: 1,
                },
              }
        }
      >
        <FilterAlt fontSize="small" />
      </IconButton>
      <Menu open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {props.items.map((x, i) => {
          const isSelected = props.isItemSelected(x);

          return (
            <MenuItem key={i} onClick={() => props.handleChange(x)}>
              <Checkbox checked={isSelected} />
              {x.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Grid>
  );
};

export default FilterMenu;
