import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";
import { EditAcneAttackContentRequest } from "../../types/api/acne-attack";
import { UpdateActionPayload } from "../../types/common";

export const fetchContentList = createAsyncThunk("@@acneAttack/fetchContentList", async () => {
  const response = await api.acneAttack.getList();
  return response.data;
});

export const fetchContent = createAsyncThunk("@@acneAttack/fetchContent", async (payload: UniqueId) => {
  const response = await api.acneAttack.get(payload);
  return response.data;
});

export const updateContent = createAsyncThunk(
  "@@acneAttack/updateContent",
  async (payload: UpdateActionPayload<EditAcneAttackContentRequest>) => {
    const response = await api.acneAttack.update(payload.id, payload.data);
    return response.data;
  },
);
