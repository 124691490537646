import { FC, useState } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import MaintainPerfectContentForm, {
  initialValues,
  validationSchema,
} from "../common/forms/MaintainPerfectContentForm";
import { useFormik } from "formik";
import { Button, CircularProgress, Grid } from "@mui/material";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { maintainPerfectStateSelector } from "../../../../store/reducers/maintain-perfect";
import ConfirmDialog from "../../../../ui-components/confirm-dialog/ConfirmDialog";
import urlTemplate from "../../../../url-template/urlTemplate";
import { useNavigate } from "react-router-dom";
import { addContent } from "../../../../store/actions/maintain-perfect";
import PageHeader from "../../../../ui-components/page-header/PageHeader";
import { getArticlesRelatedEntities, validateRelatedEntities } from "../../../../utils/validation";

interface State {
  openCancelDialog: boolean;
  openSaveDialog: boolean;
  warningMessage: string | null;
}

const initialState: State = {
  openCancelDialog: false,
  openSaveDialog: false,
  warningMessage: null,
};

const CreateMaintainPerfectContentPage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { loading } = useTypedSelector(maintainPerfectStateSelector);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const message = validateRelatedEntities([getArticlesRelatedEntities(values.articles, values.articleIds)]);
      setState({ ...state, openSaveDialog: true, warningMessage: message });
    },
  });

  const handleSaveConfirm = () => {
    const { articles: _, ...data } = formik.values;
    dispatch(
      addContent({
        data: {
          ...data,
          startDate: new Date(formik.values.startDate).toISOString(),
        },
        back: () => navigate(urlTemplate.maintainPerfect.main.value),
      }),
    );
    handleSaveCancel();
  };

  const handleSaveCancel = () => {
    setState({ ...state, openSaveDialog: false });
  };

  return (
    <PageContainer>
      <PageHeader backButton breadCrumbs={[urlTemplate.maintainPerfect.main, urlTemplate.maintainPerfect.create]} />
      <MaintainPerfectContentForm
        sx={{
          width: "50%",
          m: "0 auto",
          mt: "24px",
        }}
        formik={formik}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            mt: "32px",
            "& button": {
              width: "175px",
            },
          }}
        >
          <Button variant="outlined" onClick={() => setState({ ...state, openCancelDialog: true })}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading.addContent} type="submit">
            {loading.addContent && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            Save
          </Button>
        </Grid>
      </MaintainPerfectContentForm>
      <ConfirmDialog
        open={state.openCancelDialog}
        handleClose={() => setState({ ...state, openCancelDialog: false })}
        handleConfirm={() => navigate(urlTemplate.maintainPerfect.main.value)}
        header="Cancel adding content"
        body="Do you really want to abort the process of adding content?"
      />
      <ConfirmDialog
        open={state.openSaveDialog}
        handleClose={handleSaveCancel}
        handleConfirm={handleSaveConfirm}
        loading={loading.updateContent}
        header="Confirmation of changes"
        body={state.warningMessage || "Do you really want to save your changes?"}
      />
    </PageContainer>
  );
};

export default CreateMaintainPerfectContentPage;
