import { AppLink } from "../types/routes";
import urlTemplate from "../url-template/urlTemplate";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const links: AppLink[] = Object.values(urlTemplate).reduce((acc, x) => {
  return [...acc, ...Object.values(x)];
}, [] as AppLink[]);

const useCurrentAppLink = (): AppLink => {
  const [currentLink, setCurrentLink] = useState<AppLink>(urlTemplate.login.main);
  const location = useLocation();

  useEffect(() => {
    for (const link of links) {
      if (location.pathname.match(link.regexp)) {
        setCurrentLink(link);
        break;
      }
    }
  }, [location.pathname]);

  return currentLink;
};

export default useCurrentAppLink;
