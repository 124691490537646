import { FC, useEffect } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import QuotesPageHeader from "./header/QuotesPageHeader";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import { fetchQuotesList } from "../../../../store/actions/quotes";
import QuotesTable from "./table/QuotesTable";

const QuotesPage: FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchQuotesList());
  }, [dispatch]);

  return (
    <PageContainer>
      <QuotesPageHeader />
      <QuotesTable />
    </PageContainer>
  );
};

export default QuotesPage;
