import { AppUrl } from "../types/routes";

const loginUrl: AppUrl<"main"> = {
  main: {
    value: "/login",
    title: "Login",
    documentTitle: "MHS Login",
    regexp: /^\/login$/,
  },
};

const usersUrl: AppUrl<"main" | "details"> = {
  main: {
    value: "/users",
    title: "Users",
    documentTitle: "MHS Users",
    regexp: /^\/users$/,
  },
  details: {
    value: "/users/:id",
    title: "User details",
    documentTitle: "MHS User Details",
    regexp: /^\/users\/\d$/,
  },
};

const acneAttackUrl: AppUrl<"main" | "edit"> = {
  main: {
    value: "/acne-attack",
    title: "Acne Attack",
    documentTitle: "MHS Acne Attack",
    regexp: /^\/acne-attack$/,
  },
  edit: {
    value: "/acne-attack/:id",
    title: "Edit Acne Attack Week Content",
    documentTitle: "MHS Edit Acne Attack",
    regexp: /^\/acne-attack\/\d$/,
  },
};

const maintainPerfectUrl: AppUrl<"main" | "edit" | "create"> = {
  main: {
    value: "/maintain-perfect",
    title: "Maintain & Perfect",
    documentTitle: "MHS Maintain & Perfect",
    regexp: /^\/maintain-perfect$/,
  },
  edit: {
    value: "/maintain-perfect/:id",
    title: "Edit Maintain & Perfect Week Content",
    documentTitle: "MHS Edit Maintain & Perfect",
    regexp: /^\/maintain-perfect\/\d$/,
  },
  create: {
    value: "/maintain-perfect/add",
    title: "Add New Maintain & Perfect Week Content",
    documentTitle: "MHS Add Maintain & Perfect",
    regexp: /^\/maintain-perfect\/add$/,
  },
};

const articlesUrl: AppUrl<"main" | "edit" | "create"> = {
  main: {
    value: "/newsstand",
    title: "Newsstand",
    documentTitle: "MHS Newsstand",
    regexp: /^\/newsstand$/,
  },
  edit: {
    value: "/newsstand/:id",
    title: "Edit Article",
    documentTitle: "MHS Edit Article",
    regexp: /^\/newsstand\/\d$/,
  },
  create: {
    value: "/newsstand/add",
    title: "Add New Article",
    documentTitle: "MHS Add Article",
    regexp: /^\/newsstand\/add$/,
  },
};

const sectionsUrl: AppUrl<"main" | "edit" | "create"> = {
  main: {
    value: "/sections",
    title: "Sections",
    documentTitle: "MHS Sections",
    regexp: /^\/sections$/,
  },
  edit: {
    value: "/sections/:id",
    title: "Edit Section",
    documentTitle: "MHS Edit Section",
    regexp: /^\/sections\/\d$/,
  },
  create: {
    value: "/sections/add",
    title: "Add New Section",
    documentTitle: "MHS Add New Section",
    regexp: /^\/sections\/add$/,
  },
};

const questionsUrl: AppUrl<"main" | "edit" | "create"> = {
  main: {
    value: "/questions",
    title: "Questions",
    documentTitle: "MHS Questions",
    regexp: /^\/questions$/,
  },
  edit: {
    value: "/questions/:id",
    title: "Edit Question",
    documentTitle: "MHS Edit Question",
    regexp: /^\/questions\/\d$/,
  },
  create: {
    value: "/questions/add",
    title: "Add New Question",
    documentTitle: "MHS Add Question",
    regexp: /^\/questions\/add$/,
  },
};

const quotesUrl: AppUrl<"main"> = {
  main: {
    value: "/quotes",
    title: "Quotes",
    documentTitle: "MHS Quotes",
    regexp: /^\/quotes$/,
  },
};

const getAppUrlByEntityId = (appUrl: AppUrl<string>, pageKey: keyof AppUrl, id: UniqueId, replaced = ":id"): URI => {
  const urlValue = appUrl[pageKey].value;
  return urlValue.replace(replaced, String(id));
};

export const getAppUrl = {
  users: {
    details: (id: UniqueId) => getAppUrlByEntityId(usersUrl, "details", id),
  },
  acneAttack: {
    edit: (id: UniqueId) => getAppUrlByEntityId(acneAttackUrl, "edit", id),
  },
  maintainPerfect: {
    edit: (id: UniqueId) => getAppUrlByEntityId(maintainPerfectUrl, "edit", id),
  },
  sections: {
    edit: (id: UniqueId) => getAppUrlByEntityId(sectionsUrl, "edit", id),
  },
  articles: {
    edit: (id: UniqueId) => getAppUrlByEntityId(articlesUrl, "edit", id),
  },
  questions: {
    edit: (id: UniqueId) => getAppUrlByEntityId(questionsUrl, "edit", id),
  },
};

const urlTemplate = {
  login: loginUrl,
  users: usersUrl,
  acneAttack: acneAttackUrl,
  maintainPerfect: maintainPerfectUrl,
  sections: sectionsUrl,
  articles: articlesUrl,
  questions: questionsUrl,
  quotes: quotesUrl,
};

export default urlTemplate;
