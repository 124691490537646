import { FC, useEffect, useState } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { maintainPerfectStateSelector } from "../../../../store/reducers/maintain-perfect";
import { uiStateSelector } from "../../../../store/reducers/ui";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import MaintainPerfectContentForm, {
  initialValues,
  MaintainPerfectContentFormValues,
  validationSchema,
} from "../common/forms/MaintainPerfectContentForm";
import { fetchContent, updateContent } from "../../../../store/actions/maintain-perfect";
import { toInputDateFormat } from "../../../../utils/date";
import { MaintainPerfectContentDetailsDTO } from "../../../../types/api/maintain-perfect";
import urlTemplate from "../../../../url-template/urlTemplate";
import Loader from "../../../../ui-components/loader/Loader";
import { Button, CircularProgress, Grid } from "@mui/material";
import ConfirmDialog from "../../../../ui-components/confirm-dialog/ConfirmDialog";
import { isNotFound } from "../../../../utils/api";
import PageHeader from "../../../../ui-components/page-header/PageHeader";
import { getArticlesRelatedEntities, validateRelatedEntities } from "../../../../utils/validation";

interface UrlParams {
  id: string;
}

interface State {
  openCancelDialog: boolean;
  openSaveDialog: boolean;
  warningMessage: string | null;
}

const initialState: State = {
  openCancelDialog: false,
  openSaveDialog: false,
  warningMessage: null,
};

const EditMaintainPerfectContentPage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { content, loading } = useTypedSelector(maintainPerfectStateSelector);
  const { error } = useTypedSelector(uiStateSelector);
  const params = useParams<keyof UrlParams>();
  const navigate = useNavigate();
  const formik = useFormik<MaintainPerfectContentFormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const message = validateRelatedEntities([getArticlesRelatedEntities(values.articles, values.articleIds)]);
      setState({ ...state, openSaveDialog: true, warningMessage: message });
    },
  });
  const { setValues } = formik;

  useEffect(() => {
    const id = Number(params.id);
    if (Number.isInteger(id)) {
      dispatch(fetchContent(id));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (content) {
      setValues({
        topicEn: content.topicEn,
        topicEs: content.topicEs,
        startDate: toInputDateFormat(content.startDate),
        articleIds: content.articles.map((x) => x.id),
        articles: [],
      });
    }
  }, [content, setValues]);

  const handleSaveConfirm = () => {
    const { articles: _, ...data } = formik.values;
    dispatch(
      updateContent({
        id: (content as MaintainPerfectContentDetailsDTO).id,
        data: {
          ...data,
          startDate: new Date(formik.values.startDate).toISOString(),
        },
        back: () => navigate(urlTemplate.maintainPerfect.main.value),
      }),
    );
    handleSaveCancel();
  };

  const handleSaveCancel = () => {
    setState({ ...state, openSaveDialog: false });
  };

  if (isNotFound(error)) {
    return <Navigate to={urlTemplate.maintainPerfect.main.value} />;
  }

  if (loading.fetchContent) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <PageHeader backButton breadCrumbs={[urlTemplate.maintainPerfect.main, urlTemplate.maintainPerfect.edit]} />
      <MaintainPerfectContentForm
        sx={{
          width: "50%",
          m: "0 auto",
          mt: "24px",
        }}
        formik={formik}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            mt: "32px",
            "& button": {
              width: "175px",
            },
          }}
        >
          <Button variant="outlined" onClick={() => setState({ ...state, openCancelDialog: true })}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading.updateContent} type="submit">
            {loading.updateContent && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            Save
          </Button>
        </Grid>
      </MaintainPerfectContentForm>
      <ConfirmDialog
        open={state.openCancelDialog}
        handleClose={() => setState({ ...state, openCancelDialog: false })}
        handleConfirm={() => navigate(urlTemplate.maintainPerfect.main.value)}
        header="Cancel editing content"
        body="Are you sure you want to undo editing the content? All unsaved changes will be lost"
      />
      <ConfirmDialog
        open={state.openSaveDialog}
        handleClose={handleSaveCancel}
        handleConfirm={handleSaveConfirm}
        loading={loading.updateContent}
        header="Confirmation of changes"
        body={state.warningMessage || "Do you really want to save your changes?"}
      />
    </PageContainer>
  );
};

export default EditMaintainPerfectContentPage;
