import { FC, useEffect, useState } from "react";
import { CommentDTO, CommentsFilterParameters, CommentSortField } from "../../../types/api/comments";
import { Box, Button, Grid, Link, SxProps } from "@mui/material";
import useTypedDispatch from "../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../hooks/useTypedSelector";
import { commentsStateSelector } from "../../../store/reducers/comments";
import { deleteCommentsList, fetchCommentsList, setIds, setParameters } from "../../../store/actions/comments";
import { PaginationParameters } from "../../../ui-components/pagination/Pagination";
import Table, { SortParameters, TableColumn } from "../../../ui-components/table/Table";
import { prettyDate } from "../../../utils/date";
import { GridRenderCellParams, GridRowHeightParams, GridRowHeightReturnValue } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import urlTemplate from "../../../url-template/urlTemplate";
import ConfirmDialog from "../../../ui-components/confirm-dialog/ConfirmDialog";
import { enumToArray } from "../../../utils/enum";

const getRowHeight = (params: GridRowHeightParams): GridRowHeightReturnValue => {
  const comment = params.model as CommentDTO;
  // 52px - standard row height in MUI x-data-grid
  return comment.text.includes("\n") ? "auto" : 52;
};

const filterColumns = (cols: TableColumn<CommentDTO>[], disabledColumns?: Array<keyof CommentDTO>) => {
  const disabled = disabledColumns || ([] as Array<keyof CommentDTO>);
  return cols.filter((x) => !disabled.includes(x.field));
};

export interface CommentsSectionProps {
  userId?: UniqueId;
  articleId?: UniqueId;
  disableTableColumns?: Array<keyof CommentDTO>;
  sx?: SxProps;
}

interface State {
  openDeleteCommentsDialog: boolean;
}

const initialState: State = {
  openDeleteCommentsDialog: false,
};

const CommentsSection: FC<CommentsSectionProps> = (props) => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { comments, parameters, ids, totalItems, totalPages, loading } = useTypedSelector(commentsStateSelector);
  const disableActions = ids.length === 0;

  useEffect(() => {
    if (props.userId || props.articleId) {
      dispatch(setIds([]));
      fetchComments({
        ...parameters,
        userId: props.userId || null,
        articleId: props.articleId || null,
        page: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId, props.articleId, dispatch]);

  const fetchComments = (parameters: CommentsFilterParameters) => {
    dispatch(setParameters(parameters));
    dispatch(fetchCommentsList());
  };

  const handlePaginationChange = ({ page, size }: PaginationParameters) => {
    fetchComments({ ...parameters, page, size });
  };

  const handleSortChange = ({ sort, order }: SortParameters<CommentSortField>) => {
    fetchComments({ ...parameters, sort, order });
  };

  const handleIdsChange = (ids: UniqueId[]) => {
    dispatch(setIds(ids));
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteCommentsList());
    handleDeleteClose();
  };

  const handleDeleteClose = () => {
    setState({ ...state, openDeleteCommentsDialog: false });
  };

  const columns: TableColumn<CommentDTO>[] = [
    {
      field: "username",
      headerName: "User",
      renderCell: (params: GridRenderCellParams) => (
        <Link
          component={NavLink}
          to={urlTemplate.users.details.value.replace(":id", params.row.userId)}
          underline="hover"
        >
          {params.value}
        </Link>
      ),
      flex: 1,
    },
    {
      field: "articleTitleEn",
      alias: "articleTitle",
      headerName: "Article",
      renderCell: (params: GridRenderCellParams) => (
        <Link
          component={NavLink}
          to={urlTemplate.articles.edit.value.replace(":id", params.row.articleId)}
          underline="hover"
        >
          {params.value}
        </Link>
      ),
      flex: 1,
    },
    {
      field: "text",
      headerName: "Comment",
      renderCell: (params: GridRenderCellParams) => (
        <Grid
          sx={{
            whiteSpace: "pre-line",
            p: "8px 0",
          }}
        >
          {params.value}
        </Grid>
      ),
      flex: 5,
    },
    {
      field: "creationDate",
      headerName: "Added",
      renderCell: (params: GridRenderCellParams) => prettyDate(params.value),
      flex: 1,
    },
  ];

  return (
    <Box component="section" sx={props.sx}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          disabled={disableActions}
          onClick={() => setState({ ...state, openDeleteCommentsDialog: true })}
          variant="outlined"
        >
          Delete
        </Button>
      </Grid>
      <Table
        sx={{
          mt: "16px",
        }}
        getRowId={(row) => row.id}
        data={comments}
        cols={filterColumns(columns, props.disableTableColumns)}
        handleSortChange={handleSortChange}
        order={parameters.order}
        sort={parameters.sort}
        sortFields={enumToArray<CommentSortField>(CommentSortField)}
        pagination={{
          handlePaginationChange,
          page: parameters.page,
          size: parameters.size,
          totalPages,
          totalItems,
        }}
        loading={loading.fetchCommentsList}
        selectedRows={ids}
        handleSelectedRowsChange={handleIdsChange}
        checkboxSelection
        getRowHeight={getRowHeight}
      />
      <ConfirmDialog
        open={!!(state.openDeleteCommentsDialog || loading.deleteCommentsList)}
        handleClose={handleDeleteClose}
        handleConfirm={handleDeleteConfirm}
        loading={loading.deleteCommentsList}
        header="Deleting comments"
        body="Are you sure you want to delete the selected comments?"
      />
    </Box>
  );
};

export default CommentsSection;
