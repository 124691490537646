import { createAction } from "@reduxjs/toolkit";
import { ErrorResponse } from "../../types/api/error";

export const showError = createAction("@@ui/showError", (error: ErrorResponse) => {
  return {
    payload: error,
  };
});

export const clearError = createAction("@@ui/clearError", () => {
  return {
    payload: null,
  };
});
