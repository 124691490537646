import { FC } from "react";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { sectionsStateSelector } from "../../../../../store/reducers/sections";
import { SectionsFilterParameters, SectionShortDTO, SectionSortField } from "../../../../../types/api/sections";
import { fetchSectionsList, setIds, setParameters } from "../../../../../store/actions/sections";
import { PaginationParameters } from "../../../../../ui-components/pagination/Pagination";
import Table, { SortParameters, TableColumn } from "../../../../../ui-components/table/Table";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { prettyDate } from "../../../../../utils/date";
import { useNavigate } from "react-router-dom";
import { getAppUrl } from "../../../../../url-template/urlTemplate";
import { enumToArray } from "../../../../../utils/enum";

const SectionsTable: FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { sections, parameters, ids, totalItems, totalPages, loading } = useTypedSelector(sectionsStateSelector);

  const fetchSections = (parameters: SectionsFilterParameters) => {
    dispatch(setParameters(parameters));
    dispatch(fetchSectionsList());
  };

  const handlePaginationChange = ({ page, size }: PaginationParameters) => {
    fetchSections({ ...parameters, page, size });
  };

  const handleSortChange = ({ sort, order }: SortParameters<SectionSortField>) => {
    fetchSections({ ...parameters, sort, order });
  };

  const handleIdsChange = (ids: UniqueId[]) => {
    dispatch(setIds(ids));
  };

  const handleRowClick = (item: SectionShortDTO) => {
    navigate(getAppUrl.sections.edit(item.id));
  };

  const columns: TableColumn<SectionShortDTO>[] = [
    {
      field: "titleEn",
      alias: "title",
      headerName: "Section",
      flex: 1,
    },
    {
      field: "relatedCategories",
      headerName: "Category",
      flex: 2,
    },
    {
      field: "active",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => (params.value ? "Displayed" : "Hidden"),
      flex: 1,
    },
    {
      field: "editingDate",
      headerName: "Last Edited",
      renderCell: (params: GridRenderCellParams) => prettyDate(params.value),
      flex: 1,
    },
  ];

  return (
    <Table
      sx={{
        mt: "16px",
      }}
      getRowId={(row) => row.id}
      data={sections}
      cols={columns}
      handleSortChange={handleSortChange}
      order={parameters.order}
      sort={parameters.sort}
      sortFields={enumToArray<SectionSortField>(SectionSortField)}
      pagination={{
        handlePaginationChange,
        page: parameters.page,
        size: parameters.size,
        totalPages,
        totalItems,
      }}
      loading={loading.fetchSectionsList}
      selectedRows={ids}
      handleSelectedRowsChange={handleIdsChange}
      checkboxSelection
      handleRowClick={handleRowClick}
    />
  );
};

export default SectionsTable;
