import { ArticleOptionDTO } from "../types/api/articles";
import { QuestionOptionDTO } from "../types/api/question";
import { CategoryOptionDTO } from "../types/api/sections";

interface RelatedEntities<T = any> {
  items: T[];
  relatedIds: UniqueId[];
  activityField: keyof T;
  titleField: keyof T;
  name: string;
  message: string;
}

export const getArticlesRelatedEntities = (
  items: ArticleOptionDTO[],
  relatedIds: UniqueId[],
): RelatedEntities<ArticleOptionDTO> => {
  return {
    items,
    relatedIds,
    activityField: "published",
    titleField: "title",
    message: "is draft now",
    name: "article",
  };
};

export const getQuestionsRelatedEntities = (
  items: QuestionOptionDTO[],
  relatedIds: UniqueId[],
): RelatedEntities<QuestionOptionDTO> => {
  return {
    items,
    relatedIds,
    activityField: "active",
    titleField: "title",
    message: "is hidden now",
    name: "question",
  };
};

export const getCategoriesRelatedEntities = (
  items: CategoryOptionDTO[],
  relatedIds: UniqueId[],
): RelatedEntities<CategoryOptionDTO> => {
  return {
    items,
    relatedIds,
    activityField: "active",
    titleField: "title",
    message: "is hidden now",
    name: "category",
  };
};

export const validateRelatedEntities = (entities: RelatedEntities[]): string | null => {
  const rows: string[] = [];
  for (const el of entities) {
    const inactive = el.items.filter((x) => !x[el.activityField] && el.relatedIds.includes(x.id));
    if (inactive.length) {
      const titles = inactive.map((x) => x[el.titleField]).join(", ");
      const row = `Related ${el.name}${inactive.length > 1 ? "s" : ""}: "${titles}" ${el.message}.`;
      rows.push(row);
    }
  }
  if (rows.length) {
    return [...rows, "Are you sure you want to continue?"].join("\n");
  }
  return null;
};
