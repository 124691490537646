import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { configureClient } from "../services/api/client/client";
import { showError } from "./actions/ui";
import { logout } from "./actions/account";

const store = configureStore({
  reducer: rootReducer,
});

configureClient(
  (error) => {
    store.dispatch(showError(error));
  },
  () => {
    store.dispatch(logout());
  },
);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
