import * as yup from "yup";
import { NumberSchema, StringSchema } from "yup";

export const getEntityIdsSchema = (message?: string) =>
  yup.array().of(yup.number().required().moreThan(0)).min(1, message);

export const getRequiredStringSchema = (message?: string): StringSchema => yup.string().required(message);

export const getOptionalStringSchema = (): StringSchema => yup.string().optional();

export const getIdSchema = (message?: string): NumberSchema => yup.number().required().moreThan(0, message);

export const getOptionalIdSchema = (message?: string): NumberSchema => yup.number().optional().moreThan(0, message);

export const getPositiveNumberSchema = (message?: string): NumberSchema =>
  yup.number().required(message).positive(message);

export const getPercentSchema = (message?: string): NumberSchema =>
  yup.number().required(message).min(0, message).max(100, message);

export const getBooleanSchema = (message?: string) =>
  yup.lazy((value) => {
    if (typeof value === "string") {
      return yup.string().required(message).oneOf(["true", "false"], message);
    } else {
      return yup.boolean().required(message);
    }
  });

export const getNumberArraySchema = (message?: string) => yup.array().of(yup.number()).required(message);
