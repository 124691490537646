import React, { FC, useState } from "react";
import { CategoryFormValues, SectionFormikHelpers, SectionFormValues } from "./SectionForm";
import { Button, Grid, Paper, TextField } from "@mui/material";
import { Action, Language, LocaleFields } from "../../../../../types/common";
import { changeFormikArrayItem, getDeepArrayFormikHelpers } from "../../../../../utils/form";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import OrderControls from "../../../../../ui-components/order-controlrs/OrderControls";

const titleFields: LocaleFields<CategoryFormValues, "titleEn" | "titleEs"> = {
  [Language.En]: "titleEn",
  [Language.Es]: "titleEs",
};

const increaseHintFields: LocaleFields<CategoryFormValues, "increaseHintEn" | "increaseHintEs"> = {
  [Language.En]: "increaseHintEn",
  [Language.Es]: "increaseHintEs",
};

const decreaseHintFields: LocaleFields<CategoryFormValues, "decreaseHintEn" | "decreaseHintEs"> = {
  [Language.En]: "decreaseHintEn",
  [Language.Es]: "decreaseHintEs",
};

interface Props {
  formik: SectionFormikHelpers;
  index: number;
  language: Language;
  handleDelete: Action;
  handleSequenceNumberIncrease: Action;
  handleSequenceNumberDecrease: Action;
}

interface State {
  openDeleteDialog: boolean;
  openActivateDialog: boolean;
  openDeactivateDialog: boolean;
}

const initialState: State = {
  openDeleteDialog: false,
  openActivateDialog: false,
  openDeactivateDialog: false,
};

const CategoryForm: FC<Props> = (props) => {
  const { formik, language, index } = props;
  const [state, setState] = useState<State>(initialState);
  const { values, touched, errors, getFieldName } = getDeepArrayFormikHelpers<SectionFormValues, CategoryFormValues>(
    formik,
    "categories",
    index,
  );
  const titleField = titleFields[language];
  const increaseHintField = increaseHintFields[language];
  const decreaseHintField = decreaseHintFields[language];
  const disableDelete = formik.values.categories.length === 1;
  const categoriesCount = formik.values.categories.length;
  const disableDecreaseSequenceNumber = index === categoriesCount - 1 || categoriesCount === 1;
  const disableIncreaseSequenceNumber = index === 0 || categoriesCount === 1;
  const isNewCategory = typeof values.id !== "number";

  const handleDeleteConfirm = () => {
    props.handleDelete();
    handleDeleteClose();
  };

  const handleDeleteClose = () => {
    setState({ ...state, openDeleteDialog: false });
  };

  const handleActivateClick = () => {
    setState({ ...state, openActivateDialog: true });
  };

  const handleActivateConfirm = () => {
    changeFormikArrayItem<SectionFormValues, CategoryFormValues>(
      formik,
      "categories",
      { ...values, active: true },
      index,
    );
    handleActivateClose();
  };

  const handleActivateClose = () => {
    setState({ ...state, openActivateDialog: false });
  };

  const handleDeactivateClick = () => {
    setState({ ...state, openDeactivateDialog: true });
  };

  const handleDeactivateConfirm = () => {
    changeFormikArrayItem<SectionFormValues, CategoryFormValues>(
      formik,
      "categories",
      { ...values, active: false },
      index,
    );
    handleDeactivateClose();
  };

  const handleDeactivateClose = () => {
    setState({ ...state, openDeactivateDialog: false });
  };

  return (
    <Paper
      elevation={5}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
        boxSizing: "border-box",
        padding: "16px",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <OrderControls
          increaseControl={{
            disabled: disableIncreaseSequenceNumber,
            handleClick: props.handleSequenceNumberIncrease,
          }}
          decreaseControl={{
            disabled: disableDecreaseSequenceNumber,
            handleClick: props.handleSequenceNumberDecrease,
          }}
        />
        {isNewCategory && (
          <Button
            variant="contained"
            disabled={disableDelete}
            onClick={() => setState({ ...state, openDeleteDialog: true })}
          >
            Delete Category
          </Button>
        )}
        {!isNewCategory && (
          <Button variant="contained" onClick={values.active ? handleDeactivateClick : handleActivateClick}>
            {values.active ? "Hide" : "Display"} Category
          </Button>
        )}
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "16px",
          width: "100%",
        }}
      >
        <TextField
          sx={{
            flex: 1,
          }}
          name={getFieldName(titleField)}
          label="Category Title"
          value={values[titleField]}
          onChange={props.formik.handleChange}
          error={!!(touched[titleField] && errors[titleField])}
          helperText={errors[titleField] || " "}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          multiline
          rows={3}
          name={getFieldName(decreaseHintField)}
          label="Score Decrease Hint"
          value={values[decreaseHintField]}
          onChange={props.formik.handleChange}
          error={!!(touched[decreaseHintField] && errors[decreaseHintField])}
          helperText={errors[decreaseHintField] || " "}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          multiline
          rows={3}
          name={getFieldName(increaseHintField)}
          label="Score Increase Hint"
          value={values[increaseHintField]}
          onChange={props.formik.handleChange}
          error={!!(touched[increaseHintField] && errors[increaseHintField])}
          helperText={errors[increaseHintField] || " "}
        />
      </Grid>
      <ConfirmDialog
        open={state.openDeleteDialog}
        handleClose={handleDeleteClose}
        handleConfirm={handleDeleteConfirm}
        header="Deleting a category"
        body="Are you sure you want to delete a category?"
      />
      <ConfirmDialog
        open={state.openActivateDialog}
        handleClose={handleActivateClose}
        handleConfirm={handleActivateConfirm}
        header="Display Category"
        body="Are you sure you want to display category of the survey?"
      />
      <ConfirmDialog
        open={state.openDeactivateDialog}
        handleClose={handleDeactivateClose}
        handleConfirm={handleDeactivateConfirm}
        header="Hide Category"
        body="Are you sure you want to hide category of the survey?"
      />
    </Paper>
  );
};

export default CategoryForm;
