import { ChangeEvent, FC, ReactNode } from "react";
import { getFirstDayOfWeek, toInputDateFormat } from "../../../../../utils/date";
import * as yup from "yup";
import { StringSchema } from "yup";
import { useFormik } from "formik";
import { Box, Grid, SxProps, TextField } from "@mui/material";
import SelectEntityField from "../../../../common/SelectEntityField";
import api from "../../../../../services/api";
import { getEntityIdsSchema, getRequiredStringSchema } from "../../../../../validation/schemas";
import { ArticleOptionDTO } from "../../../../../types/api/articles";

export interface MaintainPerfectContentFormValues {
  topicEn: string;
  topicEs: string;
  startDate: DateString;
  articleIds: UniqueId[];
  articles: ArticleOptionDTO[];
}

export const initialValues: MaintainPerfectContentFormValues = {
  topicEn: "",
  topicEs: "",
  startDate: "",
  articleIds: [],
  // articles used only for validation
  articles: [],
};

const topicSchema: StringSchema = getRequiredStringSchema("Topic is required").max(
  100,
  "The length of the topic must be no more than 100 characters",
);

export const validationSchema = yup.object().shape({
  topicEn: topicSchema,
  topicEs: topicSchema,
  startDate: getRequiredStringSchema("Start date is required"),
  articleIds: getEntityIdsSchema("You must select at least one article"),
});

export type MaintainPerfectContentFormikHelpers = ReturnType<typeof useFormik<MaintainPerfectContentFormValues>>;

interface Props {
  formik: MaintainPerfectContentFormikHelpers;

  children?: ReactNode;
  sx?: SxProps;
}

const MaintainPerfectContentForm: FC<Props> = (props) => {
  const { formik } = props;

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = getFirstDayOfWeek(e.target.value);
    formik.setFieldValue("startDate", toInputDateFormat(date), true);
  };

  return (
    <Box sx={props.sx} component="form" noValidate onSubmit={formik.handleSubmit}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "16px",
          width: "100%",
        }}
      >
        <TextField
          sx={{
            flex: 1,
          }}
          name="topicEn"
          label="Topic (EN)"
          value={formik.values.topicEn}
          onChange={formik.handleChange}
          error={!!(formik.touched.topicEn && formik.errors.topicEn)}
          helperText={formik.errors.topicEn || " "}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          name="topicEs"
          label="Topic (ES)"
          value={formik.values.topicEs}
          onChange={formik.handleChange}
          error={!!(formik.touched.topicEs && formik.errors.topicEs)}
          helperText={formik.errors.topicEs || " "}
        />
      </Grid>
      <SelectEntityField
        sx={{
          mt: "16px",
        }}
        value={formik.values.articleIds}
        name="articleIds"
        label="Related articles"
        handleIdChange={formik.handleChange}
        apiCall={api.articles.getOptions}
        multiple
        touched={formik.touched.articleIds}
        error={formik.errors.articleIds}
        renderEntity={(x) => (x.published ? x.title : `${x.title} (draft)`)}
        handleEntityFetch={(x) => formik.setFieldValue("articles", x)}
      />
      <TextField
        sx={{
          mt: "16px",
          width: "50%",
        }}
        type="date"
        name="startDate"
        value={formik.values.startDate}
        onChange={handleStartDateChange}
        error={!!(formik.touched.startDate && formik.errors.startDate)}
        helperText={formik.errors.startDate || " "}
      />
      {props.children}
    </Box>
  );
};

export default MaintainPerfectContentForm;
