export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export interface PageRequest<T extends string> {
  page: number;
  size: number;
  sort: T;
  order: SortOrder;
}

export interface PageResponse<T extends {}> {
  content: T[];
  page: number;
  size: number;
  totalItems: number;
  totalPages: number;
}

export interface UpdateEntityStatusResponse {
  id: UniqueId;
  success: boolean;
  message?: string;
}

export interface DeleteEntityResponse {
  id: UniqueId;
  success: boolean;
  message?: string;
}

export interface EntityOptionDTO {
  id: UniqueId;
  title: string;
}
