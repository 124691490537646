export enum ErrorCode {
  // main errors
  Unknown = 1000,
  Forbidden = 1001,
  NotFound = 1002,
  NotValid = 1003,

  // auth errors
  AuthBadCredentials = 2000,
  AuthBadToken = 2001,
  AuthTokenExpired = 2002,
}

export interface ErrorResponse {
  code: ErrorCode;
  message: string;
}
