import { LoadingState } from "../../types/common";
import {
  SectionDetailsDTO,
  SectionsFilterParameters,
  SectionShortDTO,
  SectionSortField,
} from "../../types/api/sections";
import { SortOrder } from "../../types/api/common";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  activateSection,
  activateSectionsList,
  addSection,
  deactivateSection,
  deactivateSectionsList,
  fetchSection,
  fetchSectionsList,
  setIds,
  setParameters,
  updateSection,
} from "../actions/sections";

type SectionsLoadingKeys =
  | "fetchSectionsList"
  | "fetchSection"
  | "addSection"
  | "updateSection"
  | "activateSectionsList"
  | "activateSection"
  | "deactivateSectionsList"
  | "deactivateSection";
type SectionsLoadingState = LoadingState<SectionsLoadingKeys>;

interface SectionsState {
  sections: SectionShortDTO[];
  section: SectionDetailsDTO | null;
  parameters: SectionsFilterParameters;
  totalPages: number;
  totalItems: number;
  ids: UniqueId[];
  loading: SectionsLoadingState;
}

const initialState: SectionsState = {
  sections: [],
  section: null,
  parameters: {
    page: 1,
    size: 10,
    sort: SectionSortField.EditingDate,
    order: SortOrder.Desc,
    search: "",
  },
  totalPages: 0,
  totalItems: 0,
  ids: [],
  loading: {},
};

const sectionsSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSectionsList.pending, (state) => {
        state.loading = { ...state.loading, fetchSectionsList: true };
      })
      .addCase(fetchSectionsList.fulfilled, (state, action) => {
        state.sections = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.ids = [];
        state.loading = { ...state.loading, fetchSectionsList: false };
      })
      .addCase(fetchSectionsList.rejected, (state) => {
        state.sections = [];
        state.loading = { ...state.loading, fetchSectionsList: false };
      })
      .addCase(fetchSection.pending, (state) => {
        state.section = null;
        state.loading = { ...state.loading, fetchSection: true };
      })
      .addCase(fetchSection.fulfilled, (state, action) => {
        state.section = action.payload;
        state.loading = { ...state.loading, fetchSection: false };
      })
      .addCase(fetchSection.rejected, (state) => {
        state.section = null;
        state.loading = { ...state.loading, fetchSection: false };
      })
      .addCase(addSection.pending, (state) => {
        state.loading = { ...state.loading, addSection: true };
      })
      .addCase(addSection.fulfilled, (state, action) => {
        state.loading = { ...state.loading, addSection: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(addSection.rejected, (state) => {
        state.loading = { ...state.loading, addSection: false };
      })
      .addCase(updateSection.pending, (state) => {
        state.loading = { ...state.loading, updateSection: true };
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        state.section = action.payload;
        state.loading = { ...state.loading, updateSection: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(updateSection.rejected, (state) => {
        state.loading = { ...state.loading, updateSection: false };
      })
      .addCase(activateSectionsList.pending, (state) => {
        state.loading = { ...state.loading, activateSectionsList: true };
      })
      .addCase(activateSectionsList.fulfilled, (state, action) => {
        action.payload.forEach((x) => {
          if (!x.success) {
            return;
          }
          const section = state.sections.find((section) => section.id === x.id);
          section && (section.active = true);
        });
        state.ids = [];
        state.loading = { ...state.loading, activateSectionsList: false };
      })
      .addCase(activateSectionsList.rejected, (state) => {
        state.loading = { ...state.loading, activateSectionsList: false };
      })
      .addCase(deactivateSectionsList.pending, (state) => {
        state.loading = { ...state.loading, deactivateSectionsList: true };
      })
      .addCase(deactivateSectionsList.fulfilled, (state, action) => {
        action.payload.forEach((x) => {
          if (!x.success) {
            return;
          }
          const section = state.sections.find((section) => section.id === x.id);
          section && (section.active = false);
        });
        state.ids = [];
        state.loading = { ...state.loading, deactivateSectionsList: false };
      })
      .addCase(deactivateSectionsList.rejected, (state) => {
        state.loading = { ...state.loading, deactivateSectionsList: false };
      })
      .addCase(activateSection.pending, (state) => {
        state.loading = { ...state.loading, activateSection: true };
      })
      .addCase(activateSection.fulfilled, (state, action) => {
        if (state.section && action.payload.success) {
          state.section.active = true;
          state.section.categories.forEach((category) => {
            category.active = true;
          });
        }
        state.loading = { ...state.loading, activateSection: false };
      })
      .addCase(activateSection.rejected, (state) => {
        state.loading = { ...state.loading, activateSection: false };
      })
      .addCase(deactivateSection.pending, (state) => {
        state.loading = { ...state.loading, deactivateSection: true };
      })
      .addCase(deactivateSection.fulfilled, (state, action) => {
        if (state.section && action.payload.success) {
          state.section.active = false;
          state.section.categories.forEach((category) => {
            category.active = false;
          });
        }
        state.loading = { ...state.loading, deactivateSection: false };
      })
      .addCase(deactivateSection.rejected, (state) => {
        state.loading = { ...state.loading, deactivateSection: false };
      })
      .addCase(setParameters, (state, action) => {
        state.parameters = action.payload;
      })
      .addCase(setIds, (state, action) => {
        state.ids = action.payload;
      });
  },
});

export const sectionsStateSelector = (state: RootState) => state.sections;

export default sectionsSlice.reducer;
