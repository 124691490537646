import { FC, useEffect } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import ArticlesTable from "./table/ArticlesTable";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import { fetchArticlesList } from "../../../../store/actions/articles";
import ArticlesPageHeader from "./header/ArticlesPageHeader";

const ArticlesPage: FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchArticlesList());
  }, [dispatch]);

  return (
    <PageContainer>
      <ArticlesPageHeader />
      <ArticlesTable />
    </PageContainer>
  );
};

export default ArticlesPage;
