import React, { FC } from "react";
import { UserDTO, UsersFilterParameters, UsersSortField } from "../../../../../types/api/users";
import { GridRenderCellParams } from "@mui/x-data-grid";
import Table, { SortParameters, TableColumn } from "../../../../../ui-components/table/Table";
import FilterMenu, { FilterMenuItem } from "../../../../../ui-components/filter-menu/FilterMenu";
import { prettyDate } from "../../../../../utils/date";
import { PaginationParameters } from "../../../../../ui-components/pagination/Pagination";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { usersStateSelector } from "../../../../../store/reducers/users";
import { fetchUsersList, setIds, setParameters } from "../../../../../store/actions/users";
import { enumToArray } from "../../../../../utils/enum";
import { useNavigate } from "react-router-dom";
import { getAppUrl } from "../../../../../url-template/urlTemplate";

const renderActivityStatusCell = (params: GridRenderCellParams) => (params.value ? "Active" : "Inactive");

const activityFilterItems: FilterMenuItem[] = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "Inactive",
  },
  {
    value: null,
    label: "Any",
  },
];

const UsersTable: FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { users, parameters, ids, totalItems, totalPages, loading } = useTypedSelector(usersStateSelector);

  const fetchUsers = (parameters: UsersFilterParameters) => {
    dispatch(setParameters(parameters));
    dispatch(fetchUsersList());
  };

  const handlePaginationChange = ({ page, size }: PaginationParameters) => {
    fetchUsers({ ...parameters, page, size });
  };

  const handleSortChange = ({ sort, order }: SortParameters<UsersSortField>) => {
    fetchUsers({ ...parameters, sort, order });
  };

  const handleIdsChange = (ids: UniqueId[]) => {
    dispatch(setIds(ids));
  };

  const handleRowClick = (item: UserDTO) => {
    navigate(getAppUrl.users.details(item.id));
  };

  const columns: TableColumn<UserDTO>[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "active",
      headerName: "User status",
      renderCell: renderActivityStatusCell,
      renderFilter: () => {
        const handleChange = ({ value }: FilterMenuItem) => {
          fetchUsers({ ...parameters, active: value, page: 1 });
        };

        return (
          <FilterMenu
            title="Filter by user status"
            items={activityFilterItems}
            isItemSelected={({ value }) => value === parameters.active}
            handleChange={handleChange}
            active={parameters.active !== null}
          />
        );
      },
      flex: 1,
    },
    {
      field: "subscriptionActive",
      headerName: "Subscription Status",
      renderCell: renderActivityStatusCell,
      renderFilter: () => {
        const handleChange = ({ value }: FilterMenuItem) => {
          fetchUsers({ ...parameters, subscriptionActive: value, page: 1 });
        };

        return (
          <FilterMenu
            title="Filter by subscription status"
            items={activityFilterItems}
            isItemSelected={({ value }) => value === parameters.subscriptionActive}
            handleChange={handleChange}
            active={parameters.subscriptionActive !== null}
          />
        );
      },
      flex: 1,
    },
    {
      field: "registrationDate",
      headerName: "Registration date",
      renderCell: (params: GridRenderCellParams) => prettyDate(params.value),
      flex: 1,
    },
  ];

  return (
    <Table
      sx={{
        mt: "16px",
      }}
      getRowId={(row) => row.id}
      data={users}
      cols={columns}
      handleSortChange={handleSortChange}
      order={parameters.order}
      sort={parameters.sort}
      sortFields={enumToArray<UsersSortField>(UsersSortField)}
      pagination={{
        handlePaginationChange,
        page: parameters.page,
        size: parameters.size,
        totalItems,
        totalPages,
      }}
      loading={loading.fetchUsersList}
      selectedRows={ids}
      handleSelectedRowsChange={handleIdsChange}
      checkboxSelection
      handleRowClick={handleRowClick}
    />
  );
};

export default UsersTable;
