import { combineReducers } from "redux";
import account from "./account";
import ui from "./ui";
import users from "./users";
import articles from "./articles";
import comments from "./comments";
import quotes from "./quotes";
import acneAttack from "./acne-attack";
import maintainPerfect from "./maintain-perfect";
import sections from "./sections";
import questions from "./questions";

const rootReducer = combineReducers({
  account,
  ui,
  users,
  articles,
  comments,
  quotes,
  acneAttack,
  maintainPerfect,
  sections,
  questions,
});

export default rootReducer;
