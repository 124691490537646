import { FormikErrors, FormikTouched, useFormik } from "formik";
import {
  AnswerContentDTO,
  AnswerItemContentDTO,
  AnswerType,
  CarouselSwipeAnswerContentDTO,
  DropdownAnswerContentDTO,
} from "../types/api/question";
import {
  QuestionFormikHelpers,
  QuestionFormValues,
  QuestionFormValuesAnswerContent,
} from "../components/pages/questions/common/forms/QuestionForm";

export interface DeepFormikHelpers<C extends {}> {
  values: C;
  touched: FormikTouched<C>;
  errors: FormikErrors<C>;
  getFieldName: (field: keyof C & string) => string;
}

export const getDeepArrayFormikHelpers = <P extends {}, C extends {}>(
  formik: ReturnType<typeof useFormik<P>>,
  parentKey: keyof P & string,
  index: number,
): DeepFormikHelpers<C> => {
  const values = formik.values[parentKey] as C[];
  const touchedArray = formik.touched[parentKey] as FormikTouched<C>[];
  const errorsArray = formik.errors[parentKey] as FormikErrors<C>[];
  return {
    values: (values && values[index]) || ({} as C),
    touched: (touchedArray && touchedArray[index]) || ({} as FormikTouched<C>),
    errors: (errorsArray && errorsArray[index]) || ({} as FormikErrors<C>),
    getFieldName: (field: keyof C & string): string => {
      return `${parentKey}[${index}].${field}`;
    },
  };
};

export const getDeepFormikHelpers = <P extends {}, C extends {}>(
  formik: ReturnType<typeof useFormik<P>>,
  parentKey: keyof P & string,
): DeepFormikHelpers<C> => {
  return {
    values: (formik.values[parentKey] || {}) as C,
    touched: (formik.touched[parentKey] || {}) as FormikTouched<C>,
    errors: (formik.errors[parentKey] || {}) as FormikErrors<C>,
    getFieldName: (field: keyof C & string): string => {
      return `${parentKey}.${field}`;
    },
  };
};

export const getAnswerFormikHelpers = <T extends {}>(
  formik: QuestionFormikHelpers,
  type: AnswerType,
): DeepFormikHelpers<T> => {
  const {
    values: deepValues,
    touched: deepTouched,
    errors: deepErrors,
  } = getDeepFormikHelpers<QuestionFormValues, QuestionFormValuesAnswerContent>(formik, "answerContent");
  return {
    values: (deepValues[type] || {}) as T & AnswerContentDTO,
    touched: (deepTouched[type] || {}) as FormikTouched<T>,
    errors: (deepErrors[type] || {}) as FormikErrors<T>,
    getFieldName: (field) => {
      const parentKey: keyof QuestionFormValues = "answerContent";
      return `${parentKey}.${type}.${field}`;
    },
  };
};

export const getAnswerItemFormikHelpers = <T extends {}>(
  formik: QuestionFormikHelpers,
  type: AnswerType,
  index: number,
): DeepFormikHelpers<T> => {
  const {
    values: deepValues,
    touched: deepTouched,
    errors: deepErrors,
  } = getAnswerFormikHelpers<DropdownAnswerContentDTO | CarouselSwipeAnswerContentDTO>(formik, type);
  return {
    values: ((deepValues.items && deepValues.items[index]) || {}) as T & AnswerItemContentDTO,
    touched: ((deepTouched.items && deepTouched.items[index]) || {}) as FormikTouched<T>,
    errors: ((deepErrors.items && deepErrors.items[index]) || {}) as FormikErrors<T>,
    getFieldName: (field) => {
      const parentKey: keyof QuestionFormValues = "answerContent";
      const childKey: keyof (DropdownAnswerContentDTO | CarouselSwipeAnswerContentDTO) = "items";
      return `${parentKey}.${type}.${childKey}.${index}.${field}`;
    },
  };
};

export const changeFormikArrayItem = <P extends {}, C extends {}>(
  formik: ReturnType<typeof useFormik<P>>,
  field: keyof P & string,
  item: C,
  index: number,
) => {
  const items = [...(formik.values[field] as C[])].map((x, i) => {
    if (i === index) {
      return item;
    }
    return x;
  });
  formik.setFieldValue(field, items);
};
