import {
  EditMaintainPerfectContentRequest,
  MaintainPerfectContentDetailsDTO,
  MaintainPerfectContentFilterParameters,
  MaintainPerfectContentPageResponse,
} from "../../types/api/maintain-perfect";
import { AxiosResponse } from "axios";
import client from "./client/client";

export class MaintainPerfectApiService {
  async getList(
    parameters: MaintainPerfectContentFilterParameters,
  ): Promise<AxiosResponse<MaintainPerfectContentPageResponse>> {
    return await client.get("/api/v1/admin/program/maintain-perfect/list", {
      params: parameters,
    });
  }

  async get(id: UniqueId): Promise<AxiosResponse<MaintainPerfectContentDetailsDTO>> {
    return await client.get(`/api/v1/admin/program/maintain-perfect/${id}`);
  }

  async add(request: EditMaintainPerfectContentRequest): Promise<AxiosResponse<MaintainPerfectContentDetailsDTO>> {
    return await client.post("/api/v1/admin/program/maintain-perfect", request);
  }

  async update(
    id: UniqueId,
    request: EditMaintainPerfectContentRequest,
  ): Promise<AxiosResponse<MaintainPerfectContentDetailsDTO>> {
    return await client.post(`/api/v1/admin/program/maintain-perfect/${id}`, request);
  }
}

const maintainPerfectApiService = new MaintainPerfectApiService();

export default maintainPerfectApiService;
