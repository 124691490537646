import { FC, ReactNode } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from "@mui/material";
import * as yup from "yup";
import { StringSchema } from "yup";
import { Language } from "../../../../../types/common";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import CategoryForm from "./CategoryForm";
import { getRequiredStringSchema } from "../../../../../validation/schemas";
import { sectionTitles } from "../../../../../types/api/sections";
import { getLocaleField } from "../../../../../utils/locale";

export interface CategoryFormValues {
  id?: number;
  titleEn: string;
  titleEs: string;
  increaseHintEn: string;
  increaseHintEs: string;
  decreaseHintEn: string;
  decreaseHintEs: string;
  sequenceNumber: number;
  active?: boolean;
}

export interface SectionFormValues {
  titleEn: string;
  titleEs: string;
  categories: Array<CategoryFormValues>;
}

const createDefaultCategory = (): CategoryFormValues => {
  return {
    titleEn: "",
    titleEs: "",
    increaseHintEn: "",
    increaseHintEs: "",
    decreaseHintEn: "",
    decreaseHintEs: "",
    sequenceNumber: 1,
  };
};

const getSectionTitle = (language: Language, value: string) => {
  for (const title of Object.values(sectionTitles)) {
    if (title[language] === value) {
      return title;
    }
  }
  return {} as Record<Language, string>;
};

export const initialValues: SectionFormValues = {
  titleEn: "",
  titleEs: "",
  categories: [createDefaultCategory()],
};

const titleSchema: StringSchema = getRequiredStringSchema("Title is required").max(
  150,
  "The length of the title must be no more than 150 characters",
);
const increaseHintSchema: StringSchema = getRequiredStringSchema("Increase hint is required");
const decreaseHintSchema: StringSchema = getRequiredStringSchema("Decrease hint is required");

export const validationSchema = yup.object().shape({
  titleEn: titleSchema,
  titleEs: titleSchema,
  categories: yup
    .array()
    .of(
      yup.object().shape({
        titleEn: titleSchema,
        titleEs: titleSchema,
        increaseHintEn: increaseHintSchema,
        increaseHintEs: increaseHintSchema,
        decreaseHintEn: decreaseHintSchema,
        decreaseHintEs: decreaseHintSchema,
        sequenceNumber: yup.number().required().moreThan(0),
      }),
    )
    .min(1),
});

export const getEnglishErrors = (allErrors: FormikErrors<SectionFormValues>): string[] => {
  const errors = [allErrors.titleEn];
  if (Array.isArray(allErrors.categories)) {
    allErrors.categories.forEach((x) => {
      const categoryErrors = (x || {}) as FormikErrors<CategoryFormValues>;
      errors.push(categoryErrors.titleEn, categoryErrors.increaseHintEn, categoryErrors.decreaseHintEn);
    });
  }
  return errors.filter((x) => !!x) as string[];
};

export const getSpanishErrors = (allErrors: FormikErrors<SectionFormValues>): string[] => {
  const errors = [allErrors.titleEs];
  if (Array.isArray(allErrors.categories)) {
    allErrors.categories.forEach((x) => {
      const categoryErrors = (x || {}) as FormikErrors<CategoryFormValues>;
      errors.push(categoryErrors.titleEs, categoryErrors.increaseHintEs, categoryErrors.decreaseHintEs);
    });
  }
  return errors.filter((x) => !!x) as string[];
};

export const setCategoriesSequenceNumbers = (categories: CategoryFormValues[]): CategoryFormValues[] => {
  return [...categories].map((x, i) => ({ ...x, sequenceNumber: i + 1 }));
};

export type SectionFormikHelpers = ReturnType<typeof useFormik<SectionFormValues>>;

interface Props {
  formik: SectionFormikHelpers;
  language: Language;

  children?: ReactNode;
  sx?: SxProps;
}

const SectionForm: FC<Props> = (props) => {
  const { language, formik } = props;
  const titleTouched = getLocaleField(formik.touched, language, "title");
  const titleError = getLocaleField(formik.errors, language, "title");
  const titleValue = getLocaleField(formik.values, language, "title");

  const handleTitleChange = (e: SelectChangeEvent) => {
    const title = getSectionTitle(language, e.target.value);
    formik.setFieldValue("titleEn", title[Language.En], true);
    formik.setFieldValue("titleEs", title[Language.Es], true);
  };

  return (
    <Box sx={props.sx} component="form" noValidate onSubmit={formik.handleSubmit}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          mt: "16px",
          width: "100%",
        }}
      >
        <FormControl sx={{ width: "50%" }}>
          <InputLabel>Title</InputLabel>
          <Select
            name="title"
            label="Title"
            value={titleValue}
            onChange={handleTitleChange}
            error={!!(titleTouched && titleError)}
          >
            {Object.values(sectionTitles).map((x, i) => (
              <MenuItem key={i} value={x[language]}>
                {x[language]}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!(titleTouched && titleError)}>{(titleTouched && titleError) || " "}</FormHelperText>
        </FormControl>
      </Grid>
      <FormikProvider value={formik}>
        <FieldArray name="categories">
          {({ remove, push, swap }) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "16px",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    width: "175px",
                  }}
                  variant="contained"
                  onClick={() => push(createDefaultCategory())}
                >
                  Add Category
                </Button>
              </Grid>
              {formik.values.categories.map((x, i) => (
                <CategoryForm
                  key={i}
                  formik={formik}
                  index={i}
                  language={language}
                  handleDelete={() => remove(i)}
                  handleSequenceNumberIncrease={() => swap(i, i - 1)}
                  handleSequenceNumberDecrease={() => swap(i, i + 1)}
                />
              ))}
            </Box>
          )}
        </FieldArray>
      </FormikProvider>
      {props.children}
    </Box>
  );
};

export default SectionForm;
