import { FC, useState } from "react";
import { useFormik } from "formik";
import SectionForm, {
  getEnglishErrors,
  getSpanishErrors,
  initialValues,
  SectionFormValues,
  setCategoriesSequenceNumbers,
  validationSchema,
} from "../common/forms/SectionForm";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import CreateSectionPageHeader from "./header/CreateSectionPageHeader";
import { Language } from "../../../../types/common";
import { Button, CircularProgress, Divider, Grid } from "@mui/material";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { sectionsStateSelector } from "../../../../store/reducers/sections";
import urlTemplate from "../../../../url-template/urlTemplate";
import ConfirmDialog from "../../../../ui-components/confirm-dialog/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import { addSection } from "../../../../store/actions/sections";

export enum CreateSectionTab {
  SectionEn,
  SectionEs,
}

interface State {
  tab: CreateSectionTab;
  openCancelDialog: boolean;
  openSaveDialog: boolean;
}

const initialState: State = {
  tab: CreateSectionTab.SectionEn,
  openCancelDialog: false,
  openSaveDialog: false,
};

const CreateSectionPage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const navigate = useNavigate();
  const formik = useFormik<SectionFormValues>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {
      // ignored
    },
  });
  const dispatch = useTypedDispatch();
  const { loading } = useTypedSelector(sectionsStateSelector);
  const englishErrors = getEnglishErrors(formik.errors);
  const spanishErrors = getSpanishErrors(formik.errors);

  const handleSaveConfirm = () => {
    if (!formik.isValid) {
      return;
    }
    dispatch(
      addSection({
        data: {
          ...formik.values,
          categories: setCategoriesSequenceNumbers(formik.values.categories),
        },
        back: () => navigate(urlTemplate.sections.main.value),
      }),
    );
    setState({ ...state, openSaveDialog: false });
  };

  const handleSaveClick = async () => {
    await formik.submitForm();
    if (formik.isValid) {
      setState({ ...state, openSaveDialog: true });
    }
  };

  return (
    <PageContainer>
      <CreateSectionPageHeader
        tab={state.tab}
        handleTabChange={(tab) => setState({ ...state, tab })}
        errors={{
          [Language.En]: englishErrors.length,
          [Language.Es]: spanishErrors.length,
        }}
      />
      <Divider />
      <SectionForm
        sx={{
          width: "75%",
          m: "0 auto",
          mt: "24px",
        }}
        language={state.tab === CreateSectionTab.SectionEn ? Language.En : Language.Es}
        formik={formik}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            mt: "32px",
            "& button": {
              width: "175px",
            },
          }}
        >
          <Button variant="outlined" onClick={() => setState({ ...state, openCancelDialog: true })}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading.addSection} onClick={handleSaveClick}>
            {loading.addSection && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            Save Changes
          </Button>
        </Grid>
      </SectionForm>
      <ConfirmDialog
        open={state.openCancelDialog}
        handleClose={() => setState({ ...state, openCancelDialog: false })}
        handleConfirm={() => navigate(urlTemplate.sections.main.value)}
        header="Cancel adding an survey section"
        body="Do you really want to abort the process of adding an survey section?"
      />
      <ConfirmDialog
        open={state.openSaveDialog}
        handleClose={() => setState({ ...state, openSaveDialog: false })}
        handleConfirm={handleSaveConfirm}
        loading={loading.addSection}
        header="Confirmation of changes"
        body="Do you really want to save your changes?"
      />
    </PageContainer>
  );
};

export default CreateSectionPage;
