import { FC } from "react";
import { Grid, IconButton, SxProps } from "@mui/material";
import { Action } from "../../types/common";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

interface Control {
  handleClick: Action;
  disabled?: boolean;
}

export interface OrderControlProps {
  increaseControl: Control;
  decreaseControl: Control;

  sx?: SxProps;
}

const OrderControls: FC<OrderControlProps> = (props) => {
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <IconButton disabled={props.increaseControl.disabled} onClick={props.increaseControl.handleClick}>
        <ArrowUpward />
      </IconButton>
      <IconButton disabled={props.decreaseControl.disabled} onClick={props.decreaseControl.handleClick}>
        <ArrowDownward />
      </IconButton>
    </Grid>
  );
};

export default OrderControls;
