import { HandleChange, Language } from "../../../../../types/common";
import React, { FC, useState } from "react";
import { Badge, Button, Grid, Tab, Tabs } from "@mui/material";
import urlTemplate from "../../../../../url-template/urlTemplate";
import { EditSectionTab } from "../EditSectionPage";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { sectionsStateSelector } from "../../../../../store/reducers/sections";
import { activateSection, deactivateSection } from "../../../../../store/actions/sections";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

interface Props {
  tab: EditSectionTab;
  handleTabChange: HandleChange<EditSectionTab>;
  errors: Record<Language, number>;
}

interface State {
  openActivateSectionDialog: boolean;
  openDeactivateSectionDialog: boolean;
}

const initialState: State = {
  openActivateSectionDialog: false,
  openDeactivateSectionDialog: false,
};

const EditSectionPageHeader: FC<Props> = (props) => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { section, loading } = useTypedSelector(sectionsStateSelector);

  const handleActivateClick = () => {
    setState({ ...state, openActivateSectionDialog: true });
  };

  const handleActivateConfirm = () => {
    dispatch(activateSection());
    handleActivateClose();
  };

  const handleActivateClose = () => {
    setState({ ...state, openActivateSectionDialog: false });
  };

  const handleDeactivateClick = () => {
    setState({ ...state, openDeactivateSectionDialog: true });
  };

  const handleDeactivateConfirm = () => {
    dispatch(deactivateSection());
    handleDeactivateClose();
  };

  const handleDeactivateClose = () => {
    setState({ ...state, openDeactivateSectionDialog: false });
  };

  return (
    <PageHeader backButton breadCrumbs={[urlTemplate.sections.main, urlTemplate.sections.edit]}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "16px",
          "& button": {
            width: "175px",
          },
        }}
      >
        <Button variant="contained" onClick={section?.active ? handleDeactivateClick : handleActivateClick}>
          {section?.active ? "Hide" : "Display"} Section
        </Button>
      </Grid>
      <Tabs
        value={props.tab}
        onChange={(_, value) => props.handleTabChange(value)}
        sx={{
          width: "75%",
          display: "flex",
          margin: "0 auto",
        }}
      >
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            maxWidth: "initial",
            "& .MuiBadge-badge": {
              right: "-32px",
            },
          }}
          label={
            <Badge
              sx={{
                textTransform: "none",
              }}
              badgeContent={`Errors: ${props.errors[Language.En]}`}
              color="error"
              invisible={props.tab !== EditSectionTab.SectionEs || !props.errors[Language.En]}
            >
              English Section
            </Badge>
          }
        />
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            maxWidth: "initial",
            "& .MuiBadge-badge": {
              right: "-32px",
            },
          }}
          label={
            <Badge
              sx={{
                textTransform: "none",
              }}
              badgeContent={`Errors ${props.errors[Language.Es]}`}
              color="error"
              invisible={props.tab !== EditSectionTab.SectionEn || !props.errors[Language.Es]}
            >
              Spanish Section
            </Badge>
          }
        />
      </Tabs>
      <ConfirmDialog
        open={!!(state.openActivateSectionDialog || loading.activateSection)}
        handleClose={handleActivateClose}
        handleConfirm={handleActivateConfirm}
        loading={loading.activateSection}
        header="Display survey section"
        body="Are you sure you want to display section of the survey? Related categories and questions will also be displayed"
      />
      <ConfirmDialog
        open={!!(state.openDeactivateSectionDialog || loading.deactivateSection)}
        handleClose={handleDeactivateClose}
        handleConfirm={handleDeactivateConfirm}
        loading={loading.deactivateSection}
        header="Hide survey section"
        body="Are you sure you want to hide section of the survey? Related categories and questions will also be hidden"
      />
    </PageHeader>
  );
};

export default EditSectionPageHeader;
