import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../../services/api";
import { EditSectionRequest, SectionDetailsDTO, SectionsFilterParameters } from "../../types/api/sections";
import { AddActionPayload, UpdateActionPayload } from "../../types/common";

export const fetchSectionsList = createAsyncThunk("@@sections/fetchSectionsList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.sections.getList(state.sections.parameters);
  return response.data;
});

export const setParameters = createAction("@@sections/setParameters", (payload: SectionsFilterParameters) => {
  return {
    payload,
  };
});

export const setIds = createAction("@@sections/setIds", (payload: UniqueId[]) => {
  return {
    payload,
  };
});

export const fetchSection = createAsyncThunk("@@sections/fetchSection", async (payload: UniqueId) => {
  const response = await api.sections.get(payload);
  return response.data;
});

export const addSection = createAsyncThunk(
  "@@sections/addSection",
  async (payload: AddActionPayload<EditSectionRequest>) => {
    const response = await api.sections.add(payload.data);
    return response.data;
  },
);

export const updateSection = createAsyncThunk(
  "@@sections/updateSection",
  async (payload: UpdateActionPayload<EditSectionRequest>) => {
    const response = await api.sections.update(payload.id, payload.data);
    return response.data;
  },
);

export const activateSectionsList = createAsyncThunk("@@sections/activateSectionsList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.sections.activate(state.sections.ids);
  return response.data;
});

export const deactivateSectionsList = createAsyncThunk("@@sections/deactivateSectionsList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.sections.deactivate(state.sections.ids);
  return response.data;
});

export const activateSection = createAsyncThunk("@@sections/activateSection", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.sections.activate([(state.sections.section as SectionDetailsDTO).id]);
  return response.data[0];
});

export const deactivateSection = createAsyncThunk("@@sections/deactivateSection", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.sections.deactivate([(state.sections.section as SectionDetailsDTO).id]);
  return response.data[0];
});
