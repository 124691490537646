import { FC } from "react";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { acneAttackStateSelector } from "../../../../../store/reducers/acne-attack";
import Table, { TableColumn } from "../../../../../ui-components/table/Table";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { AcneAttackContentShortDTO } from "../../../../../types/api/acne-attack";
import { useNavigate } from "react-router-dom";
import { getAppUrl } from "../../../../../url-template/urlTemplate";

const AcneAttackContentTable: FC = () => {
  const navigate = useNavigate();
  const { contents, loading } = useTypedSelector(acneAttackStateSelector);

  const handleRowClick = (item: AcneAttackContentShortDTO) => {
    navigate(getAppUrl.acneAttack.edit(item.id));
  };

  const columns: TableColumn<AcneAttackContentShortDTO>[] = [
    {
      field: "week",
      headerName: "Week",
      flex: 0.75,
    },
    {
      field: "habitEn",
      headerName: "Habit (EN)",
      flex: 4,
    },
    {
      field: "habitEs",
      headerName: "Habit (ES)",
      flex: 4,
    },
    {
      field: "relatedArticles",
      headerName: "Related Articles",
      renderCell: (params: GridRenderCellParams) => params.value || "No related articles yet",
      flex: 5,
    },
    {
      field: "relatedQuestions",
      headerName: "Related Questions",
      renderCell: (params: GridRenderCellParams) => params.value || "No related questions yet",
      flex: 5,
    },
  ];

  return (
    <Table
      sx={{
        mt: "16px",
      }}
      data={contents}
      cols={columns}
      loading={loading.fetchContentList}
      hideFooter
      handleRowClick={handleRowClick}
    />
  );
};

export default AcneAttackContentTable;
