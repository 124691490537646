import { FC } from "react";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { questionsStateSelector } from "../../../../../store/reducers/questions";
import { QuestionsFilterParameters, QuestionShortDTO, QuestionSortField } from "../../../../../types/api/question";
import { fetchQuestionsList, setIds, setParameters } from "../../../../../store/actions/questions";
import { PaginationParameters } from "../../../../../ui-components/pagination/Pagination";
import Table, { SortParameters, TableColumn } from "../../../../../ui-components/table/Table";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { prettyDate } from "../../../../../utils/date";
import { enumToArray } from "../../../../../utils/enum";
import { useNavigate } from "react-router-dom";
import { getAppUrl } from "../../../../../url-template/urlTemplate";

const QuestionsTable: FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { questions, parameters, ids, totalItems, totalPages, loading } = useTypedSelector(questionsStateSelector);

  const fetchQuestions = (parameters: QuestionsFilterParameters) => {
    dispatch(setParameters(parameters));
    dispatch(fetchQuestionsList());
  };

  const handlePaginationChange = ({ page, size }: PaginationParameters) => {
    fetchQuestions({ ...parameters, page, size });
  };

  const handleSortChange = ({ sort, order }: SortParameters<QuestionSortField>) => {
    fetchQuestions({ ...parameters, sort, order });
  };

  const handleIdsChange = (ids: UniqueId[]) => {
    dispatch(setIds(ids));
  };

  const handleRowClick = (item: QuestionShortDTO) => {
    navigate(getAppUrl.questions.edit(item.id));
  };

  const columns: TableColumn<QuestionShortDTO>[] = [
    {
      field: "id",
      headerName: "id",
      flex: 0.5,
    },
    {
      field: "textEn",
      alias: "text",
      headerName: "Text",
      flex: 2,
    },
    {
      field: "categoryTitleEn",
      alias: "categoryTitle",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "active",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => (params.value ? "Displayed" : "Hidden"),
      flex: 1,
    },
    {
      field: "editingDate",
      headerName: "Last Edited",
      renderCell: (params: GridRenderCellParams) => prettyDate(params.value),
      flex: 1,
    },
  ];

  return (
    <Table
      sx={{
        mt: "16px",
      }}
      getRowId={(row) => row.id}
      data={questions}
      cols={columns}
      handleSortChange={handleSortChange}
      order={parameters.order}
      sort={parameters.sort}
      sortFields={enumToArray<QuestionSortField>(QuestionSortField)}
      pagination={{
        handlePaginationChange,
        page: parameters.page,
        size: parameters.size,
        totalPages,
        totalItems,
      }}
      loading={loading.fetchQuestionsList}
      selectedRows={ids}
      handleSelectedRowsChange={handleIdsChange}
      checkboxSelection
      handleRowClick={handleRowClick}
    />
  );
};

export default QuestionsTable;
