import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import urlTemplate from "../../url-template/urlTemplate";
import UsersPage from "../pages/users/main/UsersPage";
import UserDetailsPage from "../pages/users/details/UserDetailsPage";
import AcneAttackPage from "../pages/acne-attack/main/AcneAttackPage";
import MaintainPerfectPage from "../pages/maintain-perfect/main/MaintainPerfectPage";
import SectionsPage from "../pages/sections/main/SectionsPage";
import ArticlesPage from "../pages/newsstand/main/ArticlesPage";
import CreateArticlePage from "../pages/newsstand/create/CreateArticlePage";
import EditArticlePage from "../pages/newsstand/edit/EditArticlePage";
import QuestionsPage from "../pages/questions/main/QuestionsPage";
import QuotesPage from "../pages/quotes/main/QuotesPage";
import EditAcneAttackPage from "../pages/acne-attack/edit/EditAcneAttackPage";
import CreateMaintainPerfectContentPage from "../pages/maintain-perfect/create/CreateMaintainPerfectContentPage";
import EditMaintainPerfectContentPage from "../pages/maintain-perfect/edit/EditMaintainPerfectContentPage";
import CreateSectionPage from "../pages/sections/create/CreateSectionPage";
import EditSectionPage from "../pages/sections/edit/EditSectionPage";
import CreateQuestionPage from "../pages/questions/create/CreateQuestionPage";
import EditQuestionPage from "../pages/questions/edit/EditQuestionPage";

const PrivateRoutes: FC = () => {
  return (
    <Routes>
      <Route path={urlTemplate.users.main.value} element={<UsersPage />} />
      <Route path={urlTemplate.users.details.value} element={<UserDetailsPage />} />
      <Route path={urlTemplate.acneAttack.main.value} element={<AcneAttackPage />} />
      <Route path={urlTemplate.acneAttack.edit.value} element={<EditAcneAttackPage />} />
      <Route path={urlTemplate.maintainPerfect.main.value} element={<MaintainPerfectPage />} />
      <Route path={urlTemplate.maintainPerfect.create.value} element={<CreateMaintainPerfectContentPage />} />
      <Route path={urlTemplate.maintainPerfect.edit.value} element={<EditMaintainPerfectContentPage />} />
      <Route path={urlTemplate.sections.main.value} element={<SectionsPage />} />
      <Route path={urlTemplate.sections.create.value} element={<CreateSectionPage />} />
      <Route path={urlTemplate.sections.edit.value} element={<EditSectionPage />} />
      <Route path={urlTemplate.articles.main.value} element={<ArticlesPage />} />
      <Route path={urlTemplate.articles.create.value} element={<CreateArticlePage />} />
      <Route path={urlTemplate.articles.edit.value} element={<EditArticlePage />} />
      <Route path={urlTemplate.questions.main.value} element={<QuestionsPage />} />
      <Route path={urlTemplate.questions.create.value} element={<CreateQuestionPage />} />
      <Route path={urlTemplate.questions.edit.value} element={<EditQuestionPage />} />
      <Route path={urlTemplate.quotes.main.value} element={<QuotesPage />} />
      <Route path="*" element={<Navigate to={urlTemplate.users.main.value} />} />
    </Routes>
  );
};

export default PrivateRoutes;
