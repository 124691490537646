import { FC, useEffect, useState } from "react";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { sectionsStateSelector } from "../../../../store/reducers/sections";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import SectionForm, {
  getEnglishErrors,
  getSpanishErrors,
  initialValues,
  SectionFormValues,
  setCategoriesSequenceNumbers,
  validationSchema,
} from "../common/forms/SectionForm";
import { fetchSection, updateSection } from "../../../../store/actions/sections";
import { SectionDetailsDTO } from "../../../../types/api/sections";
import { uiStateSelector } from "../../../../store/reducers/ui";
import urlTemplate from "../../../../url-template/urlTemplate";
import Loader from "../../../../ui-components/loader/Loader";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import { Button, CircularProgress, Divider, Grid } from "@mui/material";
import { Language } from "../../../../types/common";
import EditSectionPageHeader from "./header/EditSectionPageHeader";
import ConfirmDialog from "../../../../ui-components/confirm-dialog/ConfirmDialog";
import { isNotFound } from "../../../../utils/api";

interface UrlParams {
  id: string;
}

export enum EditSectionTab {
  SectionEn,
  SectionEs,
}

interface State {
  tab: EditSectionTab;
  openCancelDialog: boolean;
  openSaveDialog: boolean;
}

const initialState: State = {
  tab: EditSectionTab.SectionEn,
  openCancelDialog: false,
  openSaveDialog: false,
};

const EditSectionPage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { section, loading } = useTypedSelector(sectionsStateSelector);
  const { error } = useTypedSelector(uiStateSelector);
  const navigate = useNavigate();
  const params = useParams<keyof UrlParams>();
  const formik = useFormik<SectionFormValues>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      // ignored
    },
  });
  const { setValues } = formik;
  const englishErrors = getEnglishErrors(formik.errors);
  const spanishErrors = getSpanishErrors(formik.errors);

  useEffect(() => {
    const id = Number(params.id);
    if (Number.isInteger(id)) {
      dispatch(fetchSection(id));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (section) {
      setValues(
        {
          titleEn: section.titleEn,
          titleEs: section.titleEs,
          categories: section.categories.map((x) => ({
            id: x.id,
            titleEn: x.titleEn,
            titleEs: x.titleEs,
            increaseHintEn: x.increaseHintEn,
            increaseHintEs: x.increaseHintEs,
            decreaseHintEn: x.decreaseHintEn,
            decreaseHintEs: x.decreaseHintEs,
            sequenceNumber: x.sequenceNumber,
            active: x.active,
          })),
        },
        true,
      );
    }
  }, [section, setValues]);

  const handleSaveConfirm = () => {
    if (!formik.isValid) {
      return;
    }
    dispatch(
      updateSection({
        id: (section as SectionDetailsDTO).id,
        data: {
          ...formik.values,
          categories: setCategoriesSequenceNumbers(formik.values.categories),
        },
        back: () => navigate(urlTemplate.sections.main.value),
      }),
    );
    setState({ ...state, openSaveDialog: false });
  };

  const handleSaveClick = async () => {
    await formik.submitForm();
    if (formik.isValid) {
      setState({ ...state, openSaveDialog: true });
    }
  };

  if (isNotFound(error)) {
    return <Navigate to={urlTemplate.sections.main.value} />;
  }

  if (loading.fetchSection) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <EditSectionPageHeader
        tab={state.tab}
        handleTabChange={(tab) => setState({ ...state, tab })}
        errors={{
          [Language.En]: englishErrors.length,
          [Language.Es]: spanishErrors.length,
        }}
      />
      <Divider />
      <SectionForm
        sx={{
          width: "75%",
          m: "0 auto",
          mt: "24px",
        }}
        formik={formik}
        language={state.tab === EditSectionTab.SectionEn ? Language.En : Language.Es}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            mt: "32px",
            "& button": {
              width: "175px",
            },
          }}
        >
          <Button variant="outlined" onClick={() => setState({ ...state, openCancelDialog: true })}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading.updateSection} onClick={handleSaveClick}>
            {loading.updateSection && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            Save Changes
          </Button>
        </Grid>
      </SectionForm>
      <ConfirmDialog
        open={state.openCancelDialog}
        handleClose={() => setState({ ...state, openCancelDialog: false })}
        handleConfirm={() => navigate(urlTemplate.sections.main.value)}
        header="Cancel editing an survey section"
        body="Do you really want to abort the process of editing an survey section?"
      />
      <ConfirmDialog
        open={state.openSaveDialog}
        handleClose={() => setState({ ...state, openSaveDialog: false })}
        handleConfirm={handleSaveConfirm}
        loading={loading.addSection}
        header="Confirmation of changes"
        body="Do you really want to save your changes?"
      />
    </PageContainer>
  );
};

export default EditSectionPage;
