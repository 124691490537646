import { FC } from "react";
import Table, { SortParameters, TableColumn } from "../../../../../ui-components/table/Table";
import { EditQuoteRequest, QuoteDTO, QuotesFilterParameters, QuoteSortField } from "../../../../../types/api/quote";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { quotesSelectorState } from "../../../../../store/reducers/quotes";
import { fetchQuotesList, selectQuote, setIds, setParameters, updateQuote } from "../../../../../store/actions/quotes";
import { PaginationParameters } from "../../../../../ui-components/pagination/Pagination";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { prettyDate } from "../../../../../utils/date";
import EditQuoteDialog from "../../common/dialogs/EditQuoteDialog";
import { enumToArray } from "../../../../../utils/enum";

const QuotesTable: FC = () => {
  const dispatch = useTypedDispatch();
  const { quotes, parameters, ids, totalItems, totalPages, loading, selectedQuote } =
    useTypedSelector(quotesSelectorState);

  const fetchQuotes = (parameters: QuotesFilterParameters) => {
    dispatch(setParameters(parameters));
    dispatch(fetchQuotesList());
  };

  const handlePaginationChange = ({ page, size }: PaginationParameters) => {
    fetchQuotes({ ...parameters, page, size });
  };

  const handleSortChange = ({ sort, order }: SortParameters<QuoteSortField>) => {
    fetchQuotes({ ...parameters, sort, order });
  };

  const handleIdsChange = (ids: UniqueId[]) => {
    dispatch(setIds(ids));
  };

  const handleUpdateConfirm = (data: EditQuoteRequest) => {
    if (!selectedQuote) {
      return;
    }
    dispatch(updateQuote({ id: selectedQuote.id, data }));
    handleUpdateClose();
  };

  const handleUpdateClose = () => {
    dispatch(selectQuote(null));
  };

  const handleRowClick = (item: QuoteDTO) => {
    dispatch(selectQuote(item));
  };

  const columns: TableColumn<QuoteDTO>[] = [
    {
      field: "textEn",
      headerName: "Text",
      flex: 1,
    },
    {
      field: "editingDate",
      headerName: "Last Edited",
      renderCell: (params: GridRenderCellParams) => prettyDate(params.value),
      flex: 0.25,
    },
  ];

  return (
    <>
      <Table
        sx={{
          mt: "16px",
        }}
        getRowId={(row) => row.id}
        data={quotes}
        cols={columns}
        handleSortChange={handleSortChange}
        order={parameters.order}
        sort={parameters.sort}
        sortFields={enumToArray<QuoteSortField>(QuoteSortField)}
        pagination={{
          handlePaginationChange,
          page: parameters.page,
          size: parameters.size,
          totalPages,
          totalItems,
        }}
        loading={loading.fetchQuoteList}
        selectedRows={ids}
        handleSelectedRowsChange={handleIdsChange}
        checkboxSelection
        handleRowClick={handleRowClick}
      />
      <EditQuoteDialog
        header="Edit Quote"
        open={!!(selectedQuote || loading.updateQuote)}
        quote={selectedQuote}
        handleClose={handleUpdateClose}
        handleSave={handleUpdateConfirm}
        loading={!!loading.updateQuote}
      />
    </>
  );
};

export default QuotesTable;
