import { Language } from "../types/common";

const localePostfixes: Record<Language, string> = {
  [Language.En]: "En",
  [Language.Es]: "Es",
};

export const getLocaleField = <T1 = {}, T2 = any>(data: T1, language: Language, fieldBaseName: string): T2 => {
  const localePostfix = localePostfixes[language];
  const fieldName = fieldBaseName + localePostfix;
  return data[fieldName as keyof T1] as T2;
};
