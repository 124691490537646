import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../../services/api";
import { EditQuestionRequest, QuestionDetailsDTO, QuestionsFilterParameters } from "../../types/api/question";
import { AddActionPayload, UpdateActionPayload } from "../../types/common";

export const fetchQuestionsList = createAsyncThunk("@@questions/fetchQuestionsList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.questions.getList(state.questions.parameters);
  return response.data;
});

export const setParameters = createAction("@@questions/setParameters", (payload: QuestionsFilterParameters) => {
  return {
    payload,
  };
});

export const setIds = createAction("@@questions/setIds", (payload: UniqueId[]) => {
  return {
    payload,
  };
});

export const fetchQuestion = createAsyncThunk("@@questions/fetchQuestion", async (payload: UniqueId) => {
  const response = await api.questions.get(payload);
  return response.data;
});

export const addQuestion = createAsyncThunk(
  "@@questions/addQuestion",
  async (payload: AddActionPayload<EditQuestionRequest>) => {
    const response = await api.questions.add(payload.data);
    return response.data;
  },
);

export const updateQuestion = createAsyncThunk(
  "@@questions/updateQuestion",
  async (payload: UpdateActionPayload<EditQuestionRequest>) => {
    const response = await api.questions.update(payload.id, payload.data);
    return response.data;
  },
);

export const activateQuestionsList = createAsyncThunk("@@questions/activateQuestionsList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.questions.activate(state.questions.ids);
  return response.data;
});

export const deactivateQuestionsList = createAsyncThunk(
  "@@questions/deactivateQuestionsList",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const response = await api.questions.deactivate(state.questions.ids);
    return response.data;
  },
);

export const activateQuestion = createAsyncThunk("@@questions/activateQuestion", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.questions.activate([(state.questions.question as QuestionDetailsDTO).id]);
  return response.data[0];
});

export const deactivateQuestion = createAsyncThunk("@@questions/deactivateQuestion", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.questions.deactivate([(state.questions.question as QuestionDetailsDTO).id]);
  return response.data[0];
});
