import { FC, useEffect, useState } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { articlesStateSelector } from "../../../../store/reducers/articles";
import { uiStateSelector } from "../../../../store/reducers/ui";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { fetchArticle, updateArticle } from "../../../../store/actions/articles";
import urlTemplate from "../../../../url-template/urlTemplate";
import Loader from "../../../../ui-components/loader/Loader";
import EditArticlePageHeader from "./header/EditArticlePageHeader";
import { Language } from "../../../../types/common";
import { useFormik } from "formik";
import ArticleForm, { ArticleFormValues, initialValues, validationSchema } from "../common/forms/ArticleForm";
import CommentsSection from "../../../common/comments/CommentsSection";
import { Button, CircularProgress, Divider, Grid } from "@mui/material";
import { ArticleDetailsDTO } from "../../../../types/api/articles";
import ConfirmDialog from "../../../../ui-components/confirm-dialog/ConfirmDialog";
import { isNotFound } from "../../../../utils/api";
import { getQuestionsRelatedEntities, validateRelatedEntities } from "../../../../utils/validation";

interface UrlParams {
  id: string;
}

export enum EditArticleTab {
  ArticleEn,
  ArticleEs,
  Comments,
}

interface State {
  tab: EditArticleTab;
  openCancelDialog: boolean;
  openSaveDialog: boolean;
  openSaveAndPublishDialog: boolean;
  warningMessage: string | null;
}

type SaveDialogKeys = keyof Pick<State, "openSaveDialog" | "openSaveAndPublishDialog">;

const initialState: State = {
  tab: EditArticleTab.ArticleEn,
  openCancelDialog: false,
  openSaveDialog: false,
  openSaveAndPublishDialog: false,
  warningMessage: null,
};

const EditArticlePage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { article, loading } = useTypedSelector(articlesStateSelector);
  const { error } = useTypedSelector(uiStateSelector);
  const navigate = useNavigate();
  const params = useParams<keyof UrlParams>();
  const formik = useFormik<ArticleFormValues>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      // ignored
    },
  });
  const { setValues } = formik;
  const mainErrors = [formik.errors.preview];
  const englishErrors = [...mainErrors, formik.errors.titleEn, formik.errors.contentEn, formik.errors.linkEn].filter(
    (x) => !!x,
  );
  const spanishErrors = [...mainErrors, formik.errors.titleEs, formik.errors.contentEs, formik.errors.linkEs].filter(
    (x) => !!x,
  );

  useEffect(() => {
    const id = Number(params.id);
    if (Number.isInteger(id)) {
      dispatch(fetchArticle(id));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (article) {
      setValues(
        {
          id: article.id,
          titleEn: article.titleEn,
          titleEs: article.titleEs,
          contentEn: article.contentEn,
          contentEs: article.contentEs,
          linkEn: article.linkEn,
          linkEs: article.linkEs,
          questionIds: article.questions.map((x) => x.id),
          questions: [],
        },
        true,
      );
    }
  }, [article, setValues]);

  const handleSaveConfirm = (publish: boolean | null = null) => {
    if (!formik.isValid) {
      return;
    }
    const { preview, id: _, questions: __, ...values } = formik.values;
    dispatch(
      updateArticle({
        id: (article as ArticleDetailsDTO).id,
        data: {
          request: {
            ...values,
            publish,
          },
          preview,
        },
        back: () => navigate(urlTemplate.articles.main.value),
      }),
    );
    setState({ ...state, openSaveAndPublishDialog: false, openSaveDialog: false });
  };

  const handleSaveClick = async (dialogKey: SaveDialogKeys) => {
    await formik.submitForm();
    if (formik.isValid) {
      const message = validateRelatedEntities([
        getQuestionsRelatedEntities(formik.values.questions, formik.values.questionIds),
      ]);
      setState({ ...state, [dialogKey]: true, warningMessage: message });
    }
  };

  if (isNotFound(error)) {
    return <Navigate to={urlTemplate.articles.main.value} />;
  }

  if (loading.fetchArticle) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <EditArticlePageHeader
        tab={state.tab}
        handleTabChange={(tab) => setState({ ...state, tab })}
        errors={{
          [Language.En]: englishErrors.length,
          [Language.Es]: spanishErrors.length,
        }}
      />
      <Divider />
      {article && (
        <>
          {state.tab !== EditArticleTab.Comments && (
            <ArticleForm
              sx={{
                width: "75%",
                m: "0 auto",
                mt: "24px",
              }}
              formik={formik}
              language={state.tab === EditArticleTab.ArticleEn ? Language.En : Language.Es}
            >
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "16px",
                  mt: "32px",
                  "& button": {
                    width: "175px",
                  },
                }}
              >
                <Button variant="outlined" onClick={() => setState({ ...state, openCancelDialog: true })}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={loading.updateArticle}
                  onClick={() => handleSaveClick("openSaveDialog")}
                >
                  {loading.updateArticle && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
                  Save Changes
                </Button>
                {!article.published && (
                  <Button
                    variant="contained"
                    disabled={loading.updateArticle}
                    onClick={() => handleSaveClick("openSaveAndPublishDialog")}
                  >
                    {loading.updateArticle && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
                    Save & Publish
                  </Button>
                )}
              </Grid>
            </ArticleForm>
          )}
          {state.tab === EditArticleTab.Comments && (
            <CommentsSection
              sx={{
                width: "75%",
                m: "0 auto",
                mt: "16px",
              }}
              articleId={article.id}
              disableTableColumns={["articleTitleEn"]}
            />
          )}
        </>
      )}
      <ConfirmDialog
        open={state.openCancelDialog}
        handleClose={() => setState({ ...state, openCancelDialog: false })}
        handleConfirm={() => navigate(urlTemplate.articles.main.value)}
        header="Cancel editing the article"
        body="Are you sure you want to undo editing the article? All unsaved changes will be lost"
      />
      <ConfirmDialog
        open={state.openSaveDialog}
        handleClose={() => setState({ ...state, openSaveDialog: false })}
        handleConfirm={() => handleSaveConfirm()}
        loading={loading.updateArticle}
        header="Confirmation of changes"
        body={state.warningMessage || "Do you really want to save your changes?"}
      />
      <ConfirmDialog
        open={state.openSaveAndPublishDialog}
        handleClose={() => setState({ ...state, openSaveAndPublishDialog: false })}
        handleConfirm={() => handleSaveConfirm(true)}
        loading={loading.updateArticle}
        header="Confirmation of changes"
        body={state.warningMessage || "Do you really want to save your changes and publish the article?"}
      />
    </PageContainer>
  );
};

export default EditArticlePage;
