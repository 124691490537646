import React, { FC, useState } from "react";
import { Badge, Button, Grid, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import urlTemplate from "../../../../../url-template/urlTemplate";
import { HandleChange, Language } from "../../../../../types/common";
import { EditArticleTab } from "../EditArticlePage";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { articlesStateSelector } from "../../../../../store/reducers/articles";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import { deleteArticle, publishArticle, unpublishArticle } from "../../../../../store/actions/articles";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

interface Props {
  tab: EditArticleTab;
  handleTabChange: HandleChange<EditArticleTab>;
  errors: Record<Language, number>;
}

interface State {
  openDeleteDialog: boolean;
  openPublishDialog: boolean;
  openUnpublishDialog: boolean;
}

const initialState: State = {
  openDeleteDialog: false,
  openPublishDialog: false,
  openUnpublishDialog: false,
};

const EditArticlePageHeader: FC<Props> = (props) => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { article, loading } = useTypedSelector(articlesStateSelector);
  const navigate = useNavigate();

  const handlePublishClick = () => {
    setState({ ...state, openPublishDialog: true });
  };

  const handlePublishConfirm = () => {
    dispatch(publishArticle());
    handlePublishClose();
  };

  const handlePublishClose = () => {
    setState({ ...state, openPublishDialog: false });
  };

  const handleUnpublishClick = () => {
    setState({ ...state, openUnpublishDialog: true });
  };

  const handleUnpublishConfirm = () => {
    dispatch(unpublishArticle());
    handleUnpublishClose();
  };

  const handleUnpublishClose = () => {
    setState({ ...state, openUnpublishDialog: false });
  };

  const handleDeleteClick = () => {
    setState({ ...state, openDeleteDialog: true });
  };

  const handleDeleteConfirm = () => {
    dispatch(
      deleteArticle({
        redirect: () => navigate(urlTemplate.articles.main.value),
      }),
    );
    handleDeleteClose();
  };

  const handleDeleteClose = () => {
    setState({ ...state, openDeleteDialog: false });
  };

  return (
    <PageHeader backButton breadCrumbs={[urlTemplate.articles.main, urlTemplate.articles.edit]}>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "16px",
          "& button": {
            width: "175px",
          },
        }}
      >
        <Button variant="contained" onClick={article?.published ? handleUnpublishClick : handlePublishClick}>
          {article?.published ? "Unpublish" : "Publish"} Article
        </Button>
        <Button variant="outlined" onClick={handleDeleteClick}>
          Delete Article
        </Button>
      </Grid>
      <Tabs
        value={props.tab}
        onChange={(_, value) => props.handleTabChange(value)}
        sx={{
          width: "75%",
          display: "flex",
          m: "0 auto",
        }}
      >
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            maxWidth: "initial",
            "& .MuiBadge-badge": {
              right: "-32px",
            },
          }}
          label={
            <Badge
              sx={{
                textTransform: "none",
              }}
              badgeContent={`Errors: ${props.errors[Language.En]}`}
              color="error"
              invisible={props.tab !== EditArticleTab.ArticleEs || !props.errors[Language.En]}
            >
              English article
            </Badge>
          }
        />
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            maxWidth: "initial",
            "& .MuiBadge-badge": {
              right: "-32px",
            },
          }}
          label={
            <Badge
              sx={{
                textTransform: "none",
              }}
              badgeContent={`Errors: ${props.errors[Language.Es]}`}
              color="error"
              invisible={props.tab !== EditArticleTab.ArticleEn || !props.errors[Language.Es]}
            >
              Spanish article
            </Badge>
          }
        />
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            maxWidth: "initial",
            textTransform: "none",
          }}
          label="Comments"
        />
      </Tabs>
      <ConfirmDialog
        open={!!(state.openPublishDialog || loading.publishArticle)}
        handleClose={handlePublishClose}
        handleConfirm={handlePublishConfirm}
        loading={loading.publishArticle}
        header="Article publication"
        body="Do you really want to publish this article?"
      />
      <ConfirmDialog
        open={!!(state.openUnpublishDialog || loading.unpublishArticle)}
        handleClose={handleUnpublishClose}
        handleConfirm={handleUnpublishConfirm}
        loading={loading.unpublishArticle}
        header="Article unpublication"
        body="Do you really want to unpublish this article?"
      />
      <ConfirmDialog
        open={!!(state.openDeleteDialog || loading.deleteArticle)}
        handleClose={handleDeleteClose}
        handleConfirm={handleDeleteConfirm}
        loading={loading.deleteArticle}
        header="Deleting an article"
        body="Are you sure you want to delete this article? All related comments will also be removed"
      />
    </PageHeader>
  );
};

export default EditArticlePageHeader;
