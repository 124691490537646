import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../../services/api";
import { ArticleDetailsDTO, ArticlesFilterParameters, EditArticleRequest } from "../../types/api/articles";
import { Action, AddActionPayload, UpdateActionPayload } from "../../types/common";

export const fetchArticlesList = createAsyncThunk("@@articles/fetchArticlesList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.articles.getList(state.articles.parameters);
  return response.data;
});

export const setParameters = createAction("@@articles/setParameters", (payload: ArticlesFilterParameters) => {
  return {
    payload,
  };
});

export const setIds = createAction("@@articles/setIds", (payload: UniqueId[]) => {
  return {
    payload,
  };
});

export const fetchArticle = createAsyncThunk("@@articles/fetchArticle", async (payload: UniqueId) => {
  const response = await api.articles.get(payload);
  return response.data;
});

export const addArticle = createAsyncThunk(
  "@@articles/addArticle",
  async (payload: AddActionPayload<{ request: EditArticleRequest; preview: File }>) => {
    const response = await api.articles.add(payload.data.request, payload.data.preview);
    return response.data;
  },
);

export const updateArticle = createAsyncThunk(
  "@@articles/updateArticle",
  async (payload: UpdateActionPayload<{ request: EditArticleRequest; preview?: File }>) => {
    const response = await api.articles.update(payload.id, payload.data.request, payload.data.preview);
    return response.data;
  },
);

export const publishArticlesList = createAsyncThunk("@@articles/publishArticlesList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.articles.publish(state.articles.ids);
  return response.data;
});

export const unpublishArticlesList = createAsyncThunk("@@articles/unpublishArticlesList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.articles.unpublish(state.articles.ids);
  return response.data;
});

export const publishArticle = createAsyncThunk("@@articles/publishArticle", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.articles.publish([(state.articles.article as ArticleDetailsDTO).id]);
  return response.data[0];
});

export const unpublishArticle = createAsyncThunk("@@articles/unpublishArticle", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.articles.unpublish([(state.articles.article as ArticleDetailsDTO).id]);
  return response.data[0];
});

export const deleteArticlesList = createAsyncThunk(
  "@@articles/deleteArticlesList",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const response = await api.articles.delete(state.articles.ids);
    dispatch(fetchArticlesList());
    return response.data;
  },
);

export const deleteArticle = createAsyncThunk(
  "@@articles/deleteArticle",
  async (payload: { redirect: Action }, { getState }) => {
    const state = getState() as RootState;
    const response = await api.articles.delete([(state.articles.article as ArticleDetailsDTO).id]);
    return response.data[0];
  },
);
