import { EditorUtils, ProseMirror } from "@progress/kendo-react-editor";
import { readFile } from "../../../../utils/file";

export type ValidateImage = (file: File) => boolean;

export type HandleInsertImage = (
  payload: {
    view: any;
    event: any;
    file: File;
  },
  validate?: ValidateImage,
) => void;

export interface InsertImagesPayload {
  view: any;
  file: File;
  nodeType: any;
  position?: { pos: number; inside: number } | null;
  attrs?: {};
}

export const insertImageFiles = (payload: InsertImagesPayload, validate?: ValidateImage) => {
  if (EditorUtils.canInsert(payload.view.state, payload.nodeType)) {
    if (validate && !validate(payload.file)) {
      return;
    }

    readFile(payload.file)
      .then(({ src }) => {
        const node = payload.nodeType.createAndFill({
          ...payload.attrs,
          width: 500,
          src,
        });
        if (payload.position) {
          payload.view.dispatch(payload.view.state.tr.insert(payload.position.pos, node));
        } else {
          EditorUtils.insertNode(payload.view, node, true);
        }
      })
      .catch((error) => {
        console.error("Cannot read file", error);
      });
  }
};

export const handleInsert: HandleInsertImage = ({ view, event, file }, validate) => {
  if (file && validate && !validate(file)) {
    return;
  }

  const nodeType = view.state.schema.nodes.image;

  const position = event.type === "drop" ? view.posAtCoords({ left: event.clientX, top: event.clientY }) : null;

  file &&
    insertImageFiles({
      view,
      file,
      position,
      nodeType,
    });
};

const insertImagePlugin = (handleInsert: HandleInsertImage, validate?: ValidateImage) =>
  new ProseMirror.Plugin({
    props: {
      handleDOMEvents: {
        paste: (view, event) => {
          insertImages(view, event, handleInsert, validate);
        },
        drop: (view, event) => {
          insertImages(view, event, handleInsert, validate);
        },
      },
    },
    key: new ProseMirror.PluginKey("insert-image-plugin"),
  });

const insertImages = (view: any, event: any, handleInsert: HandleInsertImage, validate?: ValidateImage) => {
  const items = (event.clipboardData && event.clipboardData.items) || (event.dataTransfer && event.dataTransfer.files);

  if (items) {
    const item = items[0];
    const file = item.type.indexOf("image") !== -1 ? (item.getAsFile ? item.getAsFile() : item) : null;

    if (file) {
      event.preventDefault();
    }

    return handleInsert({ view, event, file }, validate);
  }
};

export default insertImagePlugin;
