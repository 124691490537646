import React, { FC, useState } from "react";
import { Button, Grid } from "@mui/material";
import urlTemplate from "../../../../../url-template/urlTemplate";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { questionsStateSelector } from "../../../../../store/reducers/questions";
import InfoField from "../../../../../ui-components/info-field/InfoField";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import { activateQuestion, deactivateQuestion } from "../../../../../store/actions/questions";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

interface State {
  openActivateQuestionDialog: boolean;
  openDeactivateQuestionDialog: boolean;
}

const initialState: State = {
  openActivateQuestionDialog: false,
  openDeactivateQuestionDialog: false,
};

const EditQuestionPageHeader: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { question, loading } = useTypedSelector(questionsStateSelector);

  const handleActivateClick = () => {
    setState({ ...state, openActivateQuestionDialog: true });
  };

  const handleActivateConfirm = () => {
    dispatch(activateQuestion());
    handleActivateClose();
  };

  const handleActivateClose = () => {
    setState({ ...state, openActivateQuestionDialog: false });
  };

  const handleDeactivateClick = () => {
    setState({ ...state, openDeactivateQuestionDialog: true });
  };

  const handleDeactivateConfirm = () => {
    dispatch(deactivateQuestion());
    handleDeactivateClose();
  };

  const handleDeactivateClose = () => {
    setState({ ...state, openDeactivateQuestionDialog: false });
  };

  return (
    <PageHeader backButton breadCrumbs={[urlTemplate.questions.main, urlTemplate.questions.edit]}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "space-between",
          width: "75%",
        }}
      >
        <InfoField label="Question ID" value={question?.id} />
        <Button variant="contained" onClick={question?.active ? handleDeactivateClick : handleActivateClick}>
          {question?.active ? "Hide Question" : "Display Question"}
        </Button>
      </Grid>
      <ConfirmDialog
        open={!!(state.openActivateQuestionDialog || loading.activateQuestion)}
        handleClose={handleActivateClose}
        handleConfirm={handleActivateConfirm}
        loading={loading.activateQuestion}
        header="Display question"
        body="Are you sure you want to display question?"
      />
      <ConfirmDialog
        open={!!(state.openDeactivateQuestionDialog || loading.deactivateQuestion)}
        handleClose={handleDeactivateClose}
        handleConfirm={handleDeactivateConfirm}
        loading={loading.deactivateQuestion}
        header="Hide question"
        body="Are you sure you want to hide question?"
      />
    </PageHeader>
  );
};

export default EditQuestionPageHeader;
