import { EditQuoteRequest, QuoteDTO, QuotesFilterParameters } from "../../types/api/quote";
import { AxiosResponse } from "axios";
import { DeleteEntityResponse, PageResponse } from "../../types/api/common";
import client from "./client/client";

export class QuotesApiService {
  async getList(parameters: QuotesFilterParameters): Promise<AxiosResponse<PageResponse<QuoteDTO>>> {
    return await client.get("/api/v1/admin/quote/list", {
      params: parameters,
    });
  }

  async add(request: EditQuoteRequest): Promise<AxiosResponse<QuoteDTO>> {
    return await client.post("/api/v1/admin/quote", request);
  }

  async update(id: UniqueId, request: EditQuoteRequest): Promise<AxiosResponse<QuoteDTO>> {
    return await client.post(`/api/v1/admin/quote/${id}`, request);
  }

  async delete(ids: UniqueId[]): Promise<AxiosResponse<DeleteEntityResponse[]>> {
    return await client.delete("/api/v1/admin/quote", {
      params: {
        ids: ids.join(","),
      },
    });
  }
}

const quotesApiService = new QuotesApiService();

export default quotesApiService;
