import { LoadingState } from "../../types/common";
import {
  ArticleDetailsDTO,
  ArticlesFilterParameters,
  ArticleShortDTO,
  ArticleSortField,
} from "../../types/api/articles";
import { SortOrder } from "../../types/api/common";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  addArticle,
  deleteArticle,
  deleteArticlesList,
  fetchArticle,
  fetchArticlesList,
  publishArticle,
  publishArticlesList,
  setIds,
  setParameters,
  unpublishArticle,
  unpublishArticlesList,
  updateArticle,
} from "../actions/articles";

type ArticlesStateLoadingKeys =
  | "fetchArticlesList"
  | "fetchArticle"
  | "publishArticlesList"
  | "unpublishArticlesList"
  | "addArticle"
  | "updateArticle"
  | "publishArticle"
  | "unpublishArticle"
  | "deleteArticlesList"
  | "deleteArticle";
type ArticlesLoadingState = LoadingState<ArticlesStateLoadingKeys>;

interface ArticlesState {
  articles: ArticleShortDTO[];
  article: ArticleDetailsDTO | null;
  parameters: ArticlesFilterParameters;
  totalPages: number;
  totalItems: number;
  ids: UniqueId[];
  loading: ArticlesLoadingState;
}

const initialState: ArticlesState = {
  articles: [],
  article: null,
  parameters: {
    page: 1,
    size: 10,
    sort: ArticleSortField.EditingDate,
    order: SortOrder.Desc,
    search: "",
  },
  totalPages: 0,
  totalItems: 0,
  ids: [],
  loading: {},
};

const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticlesList.pending, (state) => {
        state.loading = { ...state.loading, fetchArticlesList: true };
      })
      .addCase(fetchArticlesList.fulfilled, (state, action) => {
        state.articles = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.ids = [];
        state.loading = { ...state.loading, fetchArticlesList: false };
      })
      .addCase(fetchArticlesList.rejected, (state) => {
        state.articles = [];
        state.loading = { ...state.loading, fetchArticlesList: false };
      })
      .addCase(fetchArticle.pending, (state) => {
        state.article = null;
        state.loading = { ...state.loading, fetchArticle: true };
      })
      .addCase(fetchArticle.fulfilled, (state, action) => {
        state.article = action.payload;
        state.loading = { ...state.loading, fetchArticle: false };
      })
      .addCase(fetchArticle.rejected, (state) => {
        state.article = null;
        state.loading = { ...state.loading, fetchArticle: false };
      })
      .addCase(publishArticlesList.pending, (state) => {
        state.loading = { ...state.loading, publishArticlesList: true };
      })
      .addCase(publishArticlesList.fulfilled, (state, action) => {
        action.payload.forEach((x) => {
          if (!x.success) {
            return;
          }
          const article = state.articles.find((article) => article.id === x.id);
          article && (article.published = true);
        });
        state.ids = [];
        state.loading = { ...state.loading, publishArticlesList: false };
      })
      .addCase(publishArticlesList.rejected, (state) => {
        state.loading = { ...state.loading, publishArticlesList: false };
      })
      .addCase(unpublishArticlesList.pending, (state) => {
        state.loading = { ...state.loading, unpublishArticlesList: true };
      })
      .addCase(unpublishArticlesList.fulfilled, (state, action) => {
        action.payload.forEach((x) => {
          if (!x.success) {
            return;
          }
          const article = state.articles.find((article) => article.id === x.id);
          article && (article.published = false);
        });
        state.ids = [];
        state.loading = { ...state.loading, unpublishArticlesList: false };
      })
      .addCase(unpublishArticlesList.rejected, (state) => {
        state.loading = { ...state.loading, unpublishArticlesList: false };
      })
      .addCase(publishArticle.pending, (state) => {
        state.loading = { ...state.loading, publishArticle: true };
      })
      .addCase(publishArticle.fulfilled, (state, action) => {
        if (state.article && action.payload.success) {
          state.article.published = true;
        }
        state.loading = { ...state.loading, publishArticle: false };
      })
      .addCase(publishArticle.rejected, (state) => {
        state.loading = { ...state.loading, publishArticle: false };
      })
      .addCase(unpublishArticle.pending, (state) => {
        state.loading = { ...state.loading, unpublishArticle: true };
      })
      .addCase(unpublishArticle.fulfilled, (state, action) => {
        if (state.article && action.payload.success) {
          state.article.published = false;
        }
        state.loading = { ...state.loading, unpublishArticle: false };
      })
      .addCase(unpublishArticle.rejected, (state) => {
        state.loading = { ...state.loading, unpublishArticle: false };
      })
      .addCase(addArticle.pending, (state) => {
        state.loading = { ...state.loading, addArticle: true };
      })
      .addCase(addArticle.fulfilled, (state, action) => {
        state.article = action.payload;
        state.loading = { ...state.loading, addArticle: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(addArticle.rejected, (state) => {
        state.loading = { ...state.loading, addArticle: false };
      })
      .addCase(updateArticle.pending, (state) => {
        state.loading = { ...state.loading, updateArticle: true };
      })
      .addCase(updateArticle.fulfilled, (state, action) => {
        state.article = action.payload;
        state.loading = { ...state.loading, updateArticle: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(updateArticle.rejected, (state) => {
        state.loading = { ...state.loading, updateArticle: false };
      })
      .addCase(deleteArticlesList.pending, (state) => {
        state.loading = { ...state.loading, deleteArticlesList: true };
      })
      .addCase(deleteArticlesList.fulfilled, (state) => {
        state.ids = [];
        state.loading = { ...state.loading, deleteArticlesList: false };
      })
      .addCase(deleteArticlesList.rejected, (state) => {
        state.loading = { ...state.loading, deleteArticlesList: false };
      })
      .addCase(deleteArticle.pending, (state) => {
        state.loading = { ...state.loading, deleteArticle: true };
      })
      .addCase(deleteArticle.fulfilled, (state, action) => {
        state.article = null;
        state.loading = { ...state.loading, deleteArticle: false };
        action.meta.arg.redirect();
      })
      .addCase(deleteArticle.rejected, (state) => {
        state.loading = { ...state.loading, deleteArticle: false };
      })
      .addCase(setParameters, (state, action) => {
        state.parameters = action.payload;
      })
      .addCase(setIds, (state, action) => {
        state.ids = action.payload;
      });
  },
});

export const articlesStateSelector = (state: RootState) => state.articles;

export default articlesSlice.reducer;
