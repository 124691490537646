import { FC, FormEvent, useState } from "react";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { sectionsStateSelector } from "../../../../../store/reducers/sections";
import { Box, Button, Grid } from "@mui/material";
import {
  activateSectionsList,
  deactivateSectionsList,
  fetchSectionsList,
  setParameters,
} from "../../../../../store/actions/sections";
import SearchField from "../../../../../ui-components/search-field/SearchField";
import { NavLink } from "react-router-dom";
import urlTemplate from "../../../../../url-template/urlTemplate";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

interface State {
  openActivateSectionsDialog: boolean;
  openDeactivateSectionsDialog: boolean;
}

const initialState: State = {
  openActivateSectionsDialog: false,
  openDeactivateSectionsDialog: false,
};

const SectionsPageHeader: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { parameters, ids, loading } = useTypedSelector(sectionsStateSelector);
  const actionsDisabled = ids.length === 0;

  const handleSearchStart = () => {
    dispatch(fetchSectionsList());
  };

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearchStart();
  };

  const handleSearchChange = (search: string) => {
    dispatch(setParameters({ ...parameters, search, page: 1 }));
  };

  const handleActivateConfirm = () => {
    dispatch(activateSectionsList());
    handleActivateClose();
  };

  const handleActivateClose = () => {
    setState({ ...state, openActivateSectionsDialog: false });
  };

  const handleDeactivateConfirm = () => {
    dispatch(deactivateSectionsList());
    handleDeactivateClose();
  };

  const handleDeactivateClose = () => {
    setState({ ...state, openDeactivateSectionsDialog: false });
  };

  return (
    <PageHeader breadCrumbs={[urlTemplate.sections.main]}>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => setState({ ...state, openDeactivateSectionsDialog: true })}
          >
            Hide
          </Button>
          <Button
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => setState({ ...state, openActivateSectionsDialog: true })}
          >
            Display
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box component="form" noValidate onSubmit={handleSearchSubmit}>
            <SearchField
              placeholder="Section or category"
              value={parameters.search as string}
              handleChange={handleSearchChange}
              handleSearchStart={handleSearchStart}
              delayMillis={500}
              startSearchLength={2}
            />
          </Box>
          <Button variant="contained" component={NavLink} to={urlTemplate.sections.create.value}>
            Add New
          </Button>
        </Grid>
        <ConfirmDialog
          open={!!(state.openActivateSectionsDialog || loading.activateSectionsList)}
          handleClose={handleActivateClose}
          handleConfirm={handleActivateConfirm}
          loading={loading.activateSectionsList}
          header="Display survey sections"
          body="Are you sure you want to display the selected sections of the survey? Related categories and questions will also be displayed"
        />
        <ConfirmDialog
          open={!!(state.openDeactivateSectionsDialog || loading.deactivateSectionsList)}
          handleClose={handleDeactivateClose}
          handleConfirm={handleDeactivateConfirm}
          loading={loading.deactivateSectionsList}
          header="Hide survey sections"
          body="Are you sure you want to hide the selected sections of the survey? Related categories and questions will also be hidden"
        />
      </Grid>
    </PageHeader>
  );
};

export default SectionsPageHeader;
