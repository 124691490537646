import { FC, useEffect, useState } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { usersStateSelector } from "../../../../store/reducers/users";
import { useParams, Navigate } from "react-router-dom";
import { fetchUser } from "../../../../store/actions/users";
import Loader from "../../../../ui-components/loader/Loader";
import { Box, Divider } from "@mui/material";
import urlTemplate from "../../../../url-template/urlTemplate";
import UserProfile from "./profile/UserProfile";
import UserActivity from "./activity/UserActivity";
import { uiStateSelector } from "../../../../store/reducers/ui";
import UserDetailsPageHeader from "./header/UserDetailsPageHeader";
import { isNotFound } from "../../../../utils/api";

interface UrlParams {
  id: string;
}

export enum UserTab {
  Profile,
  Activity,
}

interface State {
  tab: UserTab;
}

const initialState: State = {
  tab: UserTab.Profile,
};

const UserDetailsPage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { user, loading } = useTypedSelector(usersStateSelector);
  const { error } = useTypedSelector(uiStateSelector);
  const params = useParams<keyof UrlParams>();

  useEffect(() => {
    const id = Number(params.id);
    if (Number.isInteger(id)) {
      dispatch(fetchUser(id));
    }
  }, [params, dispatch]);

  if (isNotFound(error)) {
    return <Navigate to={urlTemplate.users.main.value} />;
  }

  if (loading.fetchUser) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <UserDetailsPageHeader tab={state.tab} handleTabChange={(tab) => setState({ ...state, tab })} />
      {user && (
        <Box
          component="section"
          sx={{
            width: "100%",
          }}
        >
          <Divider />
          <UserProfile tab={state.tab} />
          <UserActivity tab={state.tab} />
        </Box>
      )}
    </PageContainer>
  );
};

export default UserDetailsPage;
