import React, { FC, useEffect } from "react";
import AppContainer from "./components/container/AppContainer";
import "@progress/kendo-theme-default/dist/all.css";
import useCurrentAppLink from "./hooks/useCurrentAppLink";

const App: FC = () => {
  const currentLink = useCurrentAppLink();

  useEffect(() => {
    document.title = currentLink.documentTitle;
  }, [currentLink]);

  return <AppContainer />;
};

export default App;
