import { ReactNode } from "react";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowId,
} from "@mui/x-data-grid";
import { Grid, IconButton, SxProps } from "@mui/material";
import { SortOrder } from "../../types/api/common";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { HandleChange } from "../../types/common";
import Pagination, { PaginationParameters } from "../pagination/Pagination";

interface Column<T extends {}> {
  renderFilter?: (column: GridColDef) => ReactNode;
  alias?: string;
  field: keyof T;
}

export type TableColumn<T extends {}> = GridColDef & Column<T>;

export interface SortParameters<S extends string> {
  sort: S;
  order: SortOrder;
}

export interface TableProps<T extends {}, S extends string> {
  data: T[];
  cols: TableColumn<T>[];
  handleSortChange?: HandleChange<SortParameters<S>>;
  order?: SortOrder;
  sort?: S;
  sortFields?: Array<S>;
  pagination?: {
    handlePaginationChange: HandleChange<PaginationParameters>;
    page: number;
    size: number;
    totalItems: number;
    totalPages: number;
  };
  getRowId?: (row: T) => UniqueId;
  selectedRows?: UniqueId[];
  handleSelectedRowsChange?: (rows: UniqueId[]) => void;
  pageSizeOptions?: number[];
  loading?: boolean;
  checkboxSelection?: boolean;
  handleRowClick?: HandleChange<T>;
  hideFooter?: boolean;
  sx?: SxProps;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
}

const Table = <T extends {}, S extends string>(props: TableProps<T, S>): JSX.Element => {
  const renderSortControl = (col: TableColumn<T>): ReactNode => {
    const field = (col.alias || col.field) as S;
    const isCurrentSort = props.sort === field;
    const isDesc = isCurrentSort && props.order === SortOrder.Desc;
    const order = isDesc ? SortOrder.Asc : SortOrder.Desc;

    const handleClick = () => {
      props.handleSortChange && props.handleSortChange({ sort: field, order });
    };

    return (
      <IconButton
        onClick={handleClick}
        title={`Sort by ${(col.headerName || col.field).toLowerCase()} ${order}`}
        sx={
          isCurrentSort
            ? {}
            : {
                "& svg": {
                  opacity: 0.25,
                },
                ":hover svg": {
                  opacity: 1,
                },
              }
        }
      >
        {isDesc ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />}
      </IconButton>
    );
  };

  const cols = props.cols.map((x) => {
    if (!x.renderHeader) {
      const filter = x.renderFilter ? x.renderFilter(x) : null;
      const isSortable = props.sortFields && props.sortFields.includes((x.alias || x.field) as S);
      const sortControl = isSortable ? renderSortControl(x) : null;

      x.renderHeader = () => {
        return (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {x.headerName}
            {filter}
            {sortControl}
          </Grid>
        );
      };
    }

    return {
      ...x,
      sortable: false,
      filterable: false,
    };
  }) as TableColumn<T>[];

  const handleSelectedRowsChange = (rowIds: GridRowId[]) => {
    props.handleSelectedRowsChange && props.handleSelectedRowsChange(rowIds as UniqueId[]);
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    props.handleRowClick && props.handleRowClick(params.row);
  };

  return (
    <DataGrid
      getRowId={props.getRowId}
      sx={{
        ...props.sx,
        "& div.MuiDataGrid-row": {
          cursor: props.handleRowClick ? "pointer" : "initial",
        },
      }}
      rows={props.data}
      columns={cols}
      rowCount={(props.pagination && props.pagination.totalItems) || props.data.length}
      loading={props.loading}
      checkboxSelection={props.checkboxSelection}
      rowSelectionModel={props.selectedRows}
      onRowSelectionModelChange={handleSelectedRowsChange}
      slots={{
        pagination: () =>
          props.pagination ? (
            <Pagination
              page={props.pagination.page}
              size={props.pagination.size}
              totalPages={props.pagination.totalPages}
              totalItems={props.pagination.totalItems}
              handleChange={props.pagination.handlePaginationChange}
            />
          ) : (
            <></>
          ),
      }}
      paginationMode="server"
      autoHeight
      disableRowSelectionOnClick
      onRowClick={handleRowClick}
      hideFooter={props.hideFooter}
      getRowHeight={props.getRowHeight}
    />
  );
};

export default Table;
