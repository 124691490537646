import { FC } from "react";
import useTypedSelector from "../../hooks/useTypedSelector";
import { accountStateSelector } from "../../store/reducers/account";
import Loader from "../../ui-components/loader/Loader";
import Header from "../header/Header";
import PrivateRoutes from "../routes/PrivateRoutes";
import PublicRoutes from "../routes/PublicRoutes";
import ErrorDialog from "../common/dialogs/ErrorDialog";

const AppContainer: FC = () => {
  const { loading, account } = useTypedSelector(accountStateSelector);

  if (loading.fetchAccount) {
    return <Loader />;
  }

  return (
    <>
      {account ? (
        <>
          <Header />
          <PrivateRoutes />
        </>
      ) : (
        <PublicRoutes />
      )}
      <ErrorDialog />
    </>
  );
};

export default AppContainer;
