import { FC, FormEvent } from "react";
import { Box, Button, Grid } from "@mui/material";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { maintainPerfectStateSelector } from "../../../../../store/reducers/maintain-perfect";
import { fetchContentList, setParameters } from "../../../../../store/actions/maintain-perfect";
import SearchField from "../../../../../ui-components/search-field/SearchField";
import { NavLink } from "react-router-dom";
import urlTemplate from "../../../../../url-template/urlTemplate";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

const MaintainPerfectPageHeader: FC = () => {
  const dispatch = useTypedDispatch();
  const { parameters, currentWeekPage } = useTypedSelector(maintainPerfectStateSelector);

  const handleSearchStart = () => {
    dispatch(fetchContentList());
  };

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearchStart();
  };

  const handleSearchChange = (search: string) => {
    dispatch(setParameters({ ...parameters, search, page: 1 }));
  };

  const handleCurrentWeekClick = () => {
    if (currentWeekPage && currentWeekPage !== parameters.page) {
      dispatch(setParameters({ ...parameters, page: currentWeekPage }));
      handleSearchStart();
    }
  };

  return (
    <PageHeader breadCrumbs={[urlTemplate.maintainPerfect.main]}>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button variant="contained" disabled={!currentWeekPage} onClick={handleCurrentWeekClick}>
            Current Week
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box component="form" noValidate onSubmit={handleSearchSubmit}>
            <SearchField
              placeholder="Topic (EN)"
              value={parameters.search as string}
              handleChange={handleSearchChange}
              handleSearchStart={handleSearchStart}
              delayMillis={500}
              startSearchLength={2}
            />
          </Box>
          <Button variant="contained" component={NavLink} to={urlTemplate.maintainPerfect.create.value}>
            Add New
          </Button>
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default MaintainPerfectPageHeader;
