import {
  ArticleDetailsDTO,
  ArticleOptionDTO,
  ArticlesFilterParameters,
  ArticleShortDTO,
  EditArticleRequest,
} from "../../types/api/articles";
import { AxiosResponse } from "axios";
import { DeleteEntityResponse, PageResponse, UpdateEntityStatusResponse } from "../../types/api/common";
import client from "./client/client";

export class ArticlesApiService {
  async getList(parameters: ArticlesFilterParameters): Promise<AxiosResponse<PageResponse<ArticleShortDTO>>> {
    return await client.get("/api/v1/admin/article/list", {
      params: parameters,
    });
  }

  async getOptions(title?: string): Promise<AxiosResponse<ArticleOptionDTO[]>> {
    return await client.get("/api/v1/admin/article/options", {
      params: { title },
    });
  }

  async get(id: UniqueId): Promise<AxiosResponse<ArticleDetailsDTO>> {
    return await client.get(`/api/v1/admin/article/${id}`);
  }

  preview(id: UniqueId): URI {
    return `/api/v1/admin/article/preview/${id}`;
  }

  async add(request: EditArticleRequest, preview: File): Promise<AxiosResponse<ArticleDetailsDTO>> {
    const data = new FormData();
    data.set(
      "request",
      new Blob([JSON.stringify(request)], {
        type: "application/json",
      }),
    );
    data.set("preview", preview);
    return await client.post("/api/v1/admin/article", data);
  }

  async update(id: UniqueId, request: EditArticleRequest, preview?: File): Promise<AxiosResponse<ArticleDetailsDTO>> {
    const data = new FormData();
    data.set(
      "request",
      new Blob([JSON.stringify(request)], {
        type: "application/json",
      }),
    );
    preview && data.set("preview", preview);
    return await client.post(`/api/v1/admin/article/${id}`, data);
  }

  async publish(ids: UniqueId[]): Promise<AxiosResponse<UpdateEntityStatusResponse[]>> {
    return await client.post("/api/v1/admin/article/publish", ids);
  }

  async unpublish(ids: UniqueId[]): Promise<AxiosResponse<UpdateEntityStatusResponse[]>> {
    return await client.post("/api/v1/admin/article/unpublish", ids);
  }

  async delete(ids: UniqueId[]): Promise<AxiosResponse<DeleteEntityResponse[]>> {
    return await client.delete("/api/v1/admin/article", {
      params: {
        ids: ids.join(","),
      },
    });
  }
}

const articlesApiService = new ArticlesApiService();

export default articlesApiService;
