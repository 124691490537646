import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";

export type ApiCall<D> = () => Promise<AxiosResponse<D>>;

const useApi = <D extends {}>(apiCall: ApiCall<D>, initialData: D) => {
  const [data, setData] = useState<D>(initialData);
  const [loading, setLoading] = useState(false);

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const response = await apiCall();
      setData(response.data);
    } catch (error) {
      // ignored
    } finally {
      setLoading(false);
    }
  }, [apiCall]);

  useEffect(() => {
    void refresh();
  }, [refresh]);

  return { data, loading, refresh };
};

export default useApi;
