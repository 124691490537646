import { AnswerForm } from "./QuestionForm";
import {
  AnswerType,
  CarouselSwipeAnswerContentDTO,
  CarouselSwipeAnswerContentItemDTO,
} from "../../../../../types/api/question";
import { FieldArray, FormikProvider } from "formik";
import { Grid, Paper } from "@mui/material";
import AnswerFormFields from "./AnswerFormFields";
import { separateCamelCase } from "../../../../../utils/string";
import { getAnswerFormikHelpers } from "../../../../../utils/form";

const CarouselSwipeAnswerForm: AnswerForm = ({ formik }) => {
  const { values, getFieldName } = getAnswerFormikHelpers<CarouselSwipeAnswerContentDTO>(
    formik,
    AnswerType.CarouselSwipe,
  );

  return (
    <FormikProvider value={formik}>
      <FieldArray name={getFieldName("items")}>
        {() => (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            {values.items.map((x, i) => (
              <Paper key={i} elevation={5} sx={{ padding: "16px" }}>
                <AnswerFormFields<CarouselSwipeAnswerContentItemDTO>
                  type={AnswerType.CarouselSwipe}
                  formik={formik}
                  fieldRows={[
                    {
                      type: {
                        label: `Option ${i + 1} Type`,
                        readonly: true,
                        renderValue: separateCamelCase,
                      },
                      weight: {
                        type: "Number",
                        label: `Option ${i + 1} Weight`,
                      },
                    },
                  ]}
                  isArrayItem
                  itemIndex={i}
                />
              </Paper>
            ))}
          </Grid>
        )}
      </FieldArray>
    </FormikProvider>
  );
};

export default CarouselSwipeAnswerForm;
