import { AnswerForm } from "./QuestionForm";
import { AnswerType, DaySelectorAnswerContentDTO } from "../../../../../types/api/question";
import AnswerFormFields from "./AnswerFormFields";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

const DaySelectorAnswerForm: AnswerForm = ({ formik }) => {
  return (
    <AnswerFormFields<DaySelectorAnswerContentDTO>
      type={AnswerType.DaySelector}
      formik={formik}
      renderOther={({ values, getFieldName }) => (
        <FormControl>
          <FormLabel>All Days</FormLabel>
          <RadioGroup name={getFieldName("directly")} value={values.directly} onChange={formik.handleChange} row>
            <FormControlLabel control={<Radio />} label="Bad" value={false} />
            <FormControlLabel control={<Radio />} label="Good" value={true} />
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export default DaySelectorAnswerForm;
