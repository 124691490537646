import { UsersFilterParameters, UserDTO } from "../../types/api/users";
import { PageResponse, UpdateEntityStatusResponse } from "../../types/api/common";
import client from "./client/client";
import { AxiosResponse } from "axios";

export class UsersApiService {
  async getList(parameters: UsersFilterParameters): Promise<AxiosResponse<PageResponse<UserDTO>>> {
    return await client.get("/api/v1/admin/user/list", {
      params: parameters,
    });
  }

  async get(id: UniqueId): Promise<AxiosResponse<UserDTO>> {
    return await client.get(`/api/v1/admin/user/${id}`);
  }

  async reactivate(ids: UniqueId[]): Promise<AxiosResponse<UpdateEntityStatusResponse[]>> {
    return await client.post("/api/v1/admin/user/reactivate", ids);
  }

  async deactivate(ids: UniqueId[]): Promise<AxiosResponse<UpdateEntityStatusResponse[]>> {
    return await client.post("/api/v1/admin/user/deactivate", ids);
  }
}

const usersApiService = new UsersApiService();

export default usersApiService;
