export type ValidationFunction<P> = (payload: P) => boolean;

type ValidateFileSizePayload = {
  file: File;
  // size in megabytes
  maxSize: number;
};

export const validateFileSize: ValidationFunction<ValidateFileSizePayload> = (payload) => {
  const { file, maxSize } = payload;
  const size = file.size / 1024 / 1024;
  return size < maxSize;
};
