import { ChangeEvent, FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { Button, Grid, SxProps, Typography } from "@mui/material";
import { ImageNotSupported } from "@mui/icons-material";
import { isFile, readFile } from "../../utils/file";
import theme from "../../theme/theme";

const accept = [".jpeg", ".jpg", ".png"];

interface Text {
  placeholder?: string;
  upload?: string;
}

export interface ImageInputProps {
  handleChange: (value: File) => void;

  accept?: string[];
  text?: Text;
  preview?: URI | File;
  imageHeight?: string;
  sx?: SxProps;
}

const ImageUploader: FC<ImageInputProps> = (props) => {
  const text = props.text || ({} as Text);
  const [preview, setPreview] = useState<string | null>(null);
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (isFile(props.preview)) {
      readFile(props.preview)
        .then(({ src }) => setPreview(src))
        .catch((error) => console.error("Cannot read image", error));
    } else if (props.preview) {
      setPreview(props.preview);
    } else {
      setPreview(null);
    }
  }, [props.preview]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files.item(0);
    file && props.handleChange(file);
    e.target.value = "";
  };

  return (
    <Grid
      sx={{
        width: "max-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: `solid ${theme.palette.primary.main} 1px`,
        boxShadow: ` 0 0 4px 2px rgba(0, 0, 0, 0.05)`,
        borderRadius: "16px",
        overflow: "hidden",
        boxSizing: "border-box",
        ...props.sx,
      }}
    >
      <Grid
        sx={{
          height: props.imageHeight || "400px",
        }}
      >
        {preview ? (
          <img src={preview} alt="article preview" style={{ height: "100%" }} />
        ) : (
          <Grid
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              width: "200px",
            }}
          >
            <Typography>{text.placeholder || "No image"}</Typography>
            <ImageNotSupported />
          </Grid>
        )}
      </Grid>
      <Button fullWidth onClick={() => inputRef.current.click()}>
        {text.upload || "Upload image"}
      </Button>
      <input ref={inputRef} onChange={handleChange} type="file" accept={(props.accept || accept).join(",")} hidden />
    </Grid>
  );
};

export default ImageUploader;
