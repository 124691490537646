import { FC, useState } from "react";
import { Button, Grid } from "@mui/material";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { quotesSelectorState } from "../../../../../store/reducers/quotes";
import { addQuote, deleteQuotesList } from "../../../../../store/actions/quotes";
import EditQuoteDialog from "../../common/dialogs/EditQuoteDialog";
import { EditQuoteRequest } from "../../../../../types/api/quote";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";
import urlTemplate from "../../../../../url-template/urlTemplate";

interface State {
  openDeleteQuotesDialog: boolean;
  openAddQuoteDialog: boolean;
}

const initialState: State = {
  openDeleteQuotesDialog: false,
  openAddQuoteDialog: false,
};

const QuotesPageHeader: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { ids, loading } = useTypedSelector(quotesSelectorState);
  const actionsDisabled = ids.length === 0;

  const handleDeleteConfirm = () => {
    dispatch(deleteQuotesList());
    handleDeleteClose();
  };

  const handleDeleteClose = () => {
    setState({ ...state, openDeleteQuotesDialog: false });
  };

  const handleAddConfirm = (data: EditQuoteRequest) => {
    dispatch(addQuote(data));
    handleAddClose();
  };

  const handleAddClose = () => {
    setState({ ...state, openAddQuoteDialog: false });
  };

  return (
    <PageHeader breadCrumbs={[urlTemplate.quotes.main]}>
      <Grid
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          disabled={actionsDisabled}
          onClick={() => setState({ ...state, openDeleteQuotesDialog: true })}
        >
          Delete
        </Button>
        <Button variant="contained" onClick={() => setState({ ...state, openAddQuoteDialog: true })}>
          Add New
        </Button>
        <ConfirmDialog
          open={!!(state.openDeleteQuotesDialog || loading.deleteQuotesList)}
          handleClose={handleDeleteClose}
          handleConfirm={handleDeleteConfirm}
          loading={loading.deleteQuotesList}
          header="Deleting quotes"
          body="Are you sure you want to delete the selected quotes?"
        />
        <EditQuoteDialog
          header="Add Quote"
          open={!!(state.openAddQuoteDialog || loading.addQuote)}
          handleClose={handleAddClose}
          handleSave={handleAddConfirm}
          loading={!!loading.addQuote}
        />
      </Grid>
    </PageHeader>
  );
};

export default QuotesPageHeader;
