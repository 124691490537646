import { FC } from "react";
import { NavLink } from "react-router-dom";
import { Box, Button, Drawer, Grid, List, ListItem } from "@mui/material";
import { Action } from "../../types/common";
import urlTemplate from "../../url-template/urlTemplate";
import { AppLink } from "../../types/routes";
import { KeyboardDoubleArrowLeft } from "@mui/icons-material";

interface Props {
  open: boolean;
  handleClose: Action;
}

const navigationUrls: AppLink[] = [
  urlTemplate.users.main,
  urlTemplate.acneAttack.main,
  urlTemplate.maintainPerfect.main,
  urlTemplate.articles.main,
  urlTemplate.sections.main,
  urlTemplate.questions.main,
  urlTemplate.quotes.main,
];

const Navigation: FC<Props> = (props) => {
  return (
    <Drawer open={props.open} onClose={props.handleClose} anchor="left">
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box
          component="nav"
          sx={{
            width: "250px",
          }}
        >
          <List>
            {navigationUrls.map((x) => (
              <ListItem key={x.value}>
                <Button
                  component={NavLink}
                  to={x.value}
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    textTransform: "inherit",
                    width: "100%",
                    "&.active": {
                      background: theme.palette.primary.main,
                      color: theme.palette.common.white,
                    },
                  })}
                >
                  {x.title}
                </Button>
              </ListItem>
            ))}
          </List>
        </Box>
        <Button
          onClick={props.handleClose}
          sx={{
            textTransform: "inherit",
            justifyContent: "flex-start",
          }}
        >
          <KeyboardDoubleArrowLeft
            sx={{
              mr: "8px",
            }}
          />
          Collapse sidebar
        </Button>
      </Grid>
    </Drawer>
  );
};

export default Navigation;
