import { FC, ReactNode } from "react";
import { Grid, SxProps, Typography } from "@mui/material";

export interface InfoFieldProps {
  label: ReactNode;
  value: ReactNode;

  sx?: SxProps;
}

const InfoField: FC<InfoFieldProps> = (props) => {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        ...props.sx,
      }}
    >
      <Typography
        sx={(theme) => ({
          color: theme.palette.grey.A700,
        })}
      >
        {props.label}
      </Typography>
      <Typography
        sx={{
          fontWeight: 600,
        }}
      >
        {props.value}
      </Typography>
    </Grid>
  );
};

export default InfoField;
