import { FC } from "react";
import { SxProps, Breadcrumbs as MuiBreadCrumbs, Typography, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import { AppLink } from "../../types/routes";

export interface BreadCrumbsProps {
  items: Array<AppLink>;

  sx?: SxProps;
}

const Breadcrumbs: FC<BreadCrumbsProps> = (props) => {
  return (
    <MuiBreadCrumbs aria-label="breadcrumbs" sx={props.sx}>
      {props.items.map((x, i) => {
        const isNotLast = i !== props.items.length - 1;

        if (isNotLast) {
          return (
            <Link key={i} component={NavLink} to={x.value} title={x.title} underline="hover">
              {x.title}
            </Link>
          );
        }

        return (
          <Typography key={i} color="text.primary">
            {x.title}
          </Typography>
        );
      })}
    </MuiBreadCrumbs>
  );
};

export default Breadcrumbs;
