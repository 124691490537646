import { FC, useState } from "react";
import { Button, Grid, Tab, Tabs } from "@mui/material";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { usersStateSelector } from "../../../../../store/reducers/users";
import { deactivateUser, reactivateUser } from "../../../../../store/actions/users";
import urlTemplate from "../../../../../url-template/urlTemplate";
import { UserTab } from "../UserDetailsPage";
import { HandleChange } from "../../../../../types/common";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

interface Props {
  tab: UserTab;
  handleTabChange: HandleChange<UserTab>;
}

interface State {
  openReactivateUserDialog: boolean;
  openDeactivateUserDialog: boolean;
}

const initialState: State = {
  openReactivateUserDialog: false,
  openDeactivateUserDialog: false,
};

const UserDetailsPageHeader: FC<Props> = (props) => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { user, loading } = useTypedSelector(usersStateSelector);

  const handleReactivateClick = () => {
    setState({ ...state, openReactivateUserDialog: true });
  };

  const handleReactivateConfirm = () => {
    dispatch(reactivateUser());
    handleReactivateClose();
  };

  const handleReactivateClose = () => {
    setState({ ...state, openReactivateUserDialog: false });
  };

  const handleDeactivateClick = () => {
    setState({ ...state, openDeactivateUserDialog: true });
  };

  const handleDeactivateConfirm = () => {
    dispatch(deactivateUser());
    handleDeactivateClose();
  };

  const handleDeactivateClose = () => {
    setState({ ...state, openDeactivateUserDialog: false });
  };

  return (
    <PageHeader backButton breadCrumbs={[urlTemplate.users.main, urlTemplate.users.details]}>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "16px",
          "& button": {
            width: "175px",
          },
        }}
      >
        <Button variant="contained" onClick={user?.active ? handleDeactivateClick : handleReactivateClick}>
          {user?.active ? "Deactivate" : "Reactivate"}
        </Button>
      </Grid>
      <Tabs
        value={props.tab}
        onChange={(_, value) => props.handleTabChange(value)}
        sx={{
          width: "50%",
          display: "flex",
          m: "0 auto",
        }}
      >
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            textTransform: "none",
            maxWidth: "initial",
          }}
          label="Profile"
        />
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            textTransform: "none",
            maxWidth: "initial",
          }}
          label="Activity"
        />
      </Tabs>
      <ConfirmDialog
        open={!!(state.openReactivateUserDialog || loading.reactivateUser)}
        handleClose={handleReactivateClose}
        handleConfirm={handleReactivateConfirm}
        loading={loading.reactivateUser}
        header="User reactivation"
        body="Do you really want to reactivate this user?"
      />
      <ConfirmDialog
        open={!!(state.openDeactivateUserDialog || loading.deactivateUser)}
        handleClose={handleDeactivateClose}
        handleConfirm={handleDeactivateConfirm}
        loading={loading.deactivateUser}
        header="User deactivation"
        body="Do you really want to deactivate this user?"
      />
    </PageHeader>
  );
};

export default UserDetailsPageHeader;
