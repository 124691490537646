import { AxiosResponse } from "axios";
import {
  AcneAttackContentDetailsDTO,
  AcneAttackContentShortDTO,
  EditAcneAttackContentRequest,
} from "../../types/api/acne-attack";
import client from "./client/client";

export class AcneAttackApiService {
  async getList(): Promise<AxiosResponse<AcneAttackContentShortDTO[]>> {
    return await client.get("/api/v1/admin/program/acne-attack/list");
  }

  async get(id: UniqueId): Promise<AxiosResponse<AcneAttackContentDetailsDTO>> {
    return await client.get(`/api/v1/admin/program/acne-attack/${id}`);
  }

  async update(
    id: UniqueId,
    request: EditAcneAttackContentRequest,
  ): Promise<AxiosResponse<AcneAttackContentDetailsDTO>> {
    return await client.post(`/api/v1/admin/program/acne-attack/${id}`, request);
  }
}

const acneAttackApiService = new AcneAttackApiService();

export default acneAttackApiService;
