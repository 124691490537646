import { ChangeEvent, FC, useEffect, useState } from "react";
import { IconButton, InputAdornment, SxProps, TextField } from "@mui/material";
import { HandleChange } from "../../types/common";
import { Search } from "@mui/icons-material";

export interface SearchFieldProps {
  value: string;
  handleChange: HandleChange<string>;
  handleSearchStart: HandleChange<string>;

  delayMillis?: number;
  startSearchLength?: number;
  name?: string;
  label?: string;
  placeholder?: string;
  sx?: SxProps;
}

const defaultStartSearchLength = 2;
const defaultDelayMillis = 500;

const SearchField: FC<SearchFieldProps> = (props) => {
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
  }, [timerId]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    props.handleChange(value);
    if (value.length >= (props.startSearchLength || defaultStartSearchLength) || value.length === 0) {
      const id = setTimeout(() => {
        props.handleSearchStart(value);
      }, props.delayMillis || defaultDelayMillis);
      setTimerId(id);
    }
  };

  const handleSearchStart = () => {
    props.handleSearchStart(props.value);
  };

  return (
    <TextField
      sx={props.sx}
      label={props.label}
      placeholder={props.placeholder}
      name={props.name}
      value={props.value}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={handleSearchStart}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
