import { FC } from "react";
import { Action } from "../../../types/common";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface LogoutDialogProps {
  open: boolean;
  handleClose: Action;
  handleLogout: Action;
}

export const LogoutDialog: FC<LogoutDialogProps> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Logout</DialogTitle>
      <DialogContent>
        <DialogContentText>Do you really want to log out?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={props.handleLogout}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
