import { AnswerForm } from "./QuestionForm";
import { AnswerType, SliderAnswerContentDTO } from "../../../../../types/api/question";
import AnswerFormFields from "./AnswerFormFields";

const SliderAnswerForm: AnswerForm = ({ formik }) => {
  return (
    <AnswerFormFields<SliderAnswerContentDTO>
      type={AnswerType.Slider}
      formik={formik}
      fieldRows={[
        {
          points: {
            type: "number",
            label: "Number Of Points",
          },
          pointWeight: {
            type: "number",
            label: "Point Weight (%)",
          },
        },
        {
          startPointNameEn: {
            label: "Start Slider Point Label (EN)",
          },
          startPointNameEs: {
            label: "Start Slider Point Label (ES)",
          },
        },
        {
          pointNameEn: {
            label: "Point Label (EN)",
          },
          pointNameEs: {
            label: "Point Label (ES)",
          },
        },
        {
          endPointNameEn: {
            label: "End Slider Point Label (EN)",
          },
          endPointNameEs: {
            label: "End Slider Point Label (ES)",
          },
        },
      ]}
    />
  );
};

export default SliderAnswerForm;
