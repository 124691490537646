import { EntityOptionDTO, PageRequest } from "./common";
import { Language } from "../common";

export interface SectionShortDTO {
  id: UniqueId;
  titleEn: string;
  titleEs: string;
  editingDate: DateString;
  relatedCategories: string;
  active: boolean;
}

export interface CategoryDTO {
  id: UniqueId;
  sectionId: UniqueId;
  titleEn: string;
  titleEs: string;
  increaseHintEn: string;
  increaseHintEs: string;
  decreaseHintEn: string;
  decreaseHintEs: string;
  sequenceNumber: number;
  active: boolean;
}

export interface CategoryOptionDTO extends EntityOptionDTO {
  questionsCount: number;
  active: boolean;
}

export interface SectionDetailsDTO {
  id: UniqueId;
  titleEn: string;
  titleEs: string;
  editingDate: DateString;
  active: boolean;
  categories: CategoryDTO[];
}

export interface EditSectionRequest {
  titleEn: string;
  titleEs: string;
  categories: Array<{
    id?: UniqueId;
    titleEn: string;
    titleEs: string;
    increaseHintEn: string;
    increaseHintEs: string;
    decreaseHintEn: string;
    decreaseHintEs: string;
    sequenceNumber: number;
    active?: boolean;
  }>;
}

export enum SectionSortField {
  Title = "title",
  EditingDate = "editingDate",
}

export interface SectionsFilterParameters extends PageRequest<SectionSortField> {
  search?: string;
}

export enum SectionTitle {
  SkinScore = "Skin score",
  Skincare = "Skincare",
  Nutrition = "Nutrition",
  Lifestyle = "Lifestyle",
}

export const sectionTitles: Record<string, Record<Language, string>> = {
  [SectionTitle.SkinScore]: {
    [Language.En]: "Skin score",
    [Language.Es]: "Puntaje de la piel",
  },
  [SectionTitle.Skincare]: {
    [Language.En]: "Skincare",
    [Language.Es]: "Cuidado de la piel",
  },
  [SectionTitle.Nutrition]: {
    [Language.En]: "Nutrition",
    [Language.Es]: "Nutrición",
  },
  [SectionTitle.Lifestyle]: {
    [Language.En]: "Lifestyle",
    [Language.Es]: "Estilo de vida",
  },
};
