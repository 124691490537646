import { FC, ReactNode, useState } from "react";
import { Box, Grid, SxProps, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Language, LocaleFields } from "../../../../../types/common";
import Editor from "../../../../../ui-components/editor/Editor";
import { StringSchema } from "yup";
import { validateFileSize } from "../../../../../validation/functions";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import { getFileSizeMb } from "../../../../../utils/file";
import { getNumberArraySchema, getRequiredStringSchema } from "../../../../../validation/schemas";
import SelectEntityField from "../../../../common/SelectEntityField";
import api from "../../../../../services/api";
import ImageUploader from "../../../../../ui-components/image-uploader/ImageUploader";
import theme from "../../../../../theme/theme";
import { QuestionOptionDTO } from "../../../../../types/api/question";

// TODO move value to build argument?
const articleBaseLink = "https://www.myhealthyskin.org/";

export interface ArticleFormValues {
  id?: UniqueId;
  titleEn: string;
  titleEs: string;
  contentEn: HTMLString;
  contentEs: HTMLString;
  linkEn: Link;
  linkEs: Link;
  questionIds: UniqueId[];
  preview?: File;
  // questions used only for validation
  questions: QuestionOptionDTO[];
}

export const initialValues: ArticleFormValues = {
  titleEn: "",
  titleEs: "",
  contentEn: "",
  contentEs: "",
  linkEn: articleBaseLink,
  linkEs: articleBaseLink,
  questionIds: [],
  questions: [],
};

const titleSchema: StringSchema = getRequiredStringSchema("Title is required").max(
  500,
  "The length of the title must be no more than 500 characters",
);
const contentSchema: StringSchema = getRequiredStringSchema("Article text is required");
const linkSchema: StringSchema = yup
  .string()
  .required("Website link is required")
  .url("Invalid link value")
  .test({
    test: (value) => value.startsWith(articleBaseLink),
    name: "websiteLink",
    message: `Link must be starts with: "${articleBaseLink}"`,
  });

export const validationSchema = yup.object().shape({
  titleEn: titleSchema,
  titleEs: titleSchema,
  contentEn: contentSchema,
  contentEs: contentSchema,
  linkEn: linkSchema,
  linkEs: linkSchema,
  questionIds: getNumberArraySchema(),
  preview: yup.mixed().test({
    name: "required",
    message: "Preview is required",
    test: (value, context) => {
      // if article has already been created, then the preview should already be loaded
      const id = context.parent.id;
      return !!(id || value);
    },
  }),
});

const titleFields: LocaleFields<ArticleFormValues, "titleEn" | "titleEs"> = {
  [Language.En]: "titleEn",
  [Language.Es]: "titleEs",
};

const contentFields: LocaleFields<ArticleFormValues, "contentEn" | "contentEs"> = {
  [Language.En]: "contentEn",
  [Language.Es]: "contentEs",
};

const linkFields: LocaleFields<ArticleFormValues, "linkEn" | "linkEs"> = {
  [Language.En]: "linkEn",
  [Language.Es]: "linkEs",
};

export type ArticleFormikHelpers = ReturnType<typeof useFormik<ArticleFormValues>>;

interface Props {
  formik: ArticleFormikHelpers;
  language: Language;

  children?: ReactNode;
  sx?: SxProps;
}

interface State {
  imageValidationError: string | null;
}

const initialState: State = {
  imageValidationError: null,
};

const ArticleForm: FC<Props> = (props) => {
  const [state, setState] = useState<State>(initialState);
  const { language, formik } = props;
  const titleField = titleFields[language];
  const contentField = contentFields[language];
  const linkField = linkFields[language];

  const handleContentChange = (content: HTMLString) => {
    formik.setFieldValue(contentField, content, true);
  };

  const handlePreviewChange = (preview: File) => {
    if (validateImage(preview)) {
      formik.setFieldValue("preview", preview, true);
    }
  };

  const validateImage = (file: File): boolean => {
    const maxSize = 2;
    const isValid = validateFileSize({ file, maxSize });
    if (!isValid) {
      const size = getFileSizeMb(file).toFixed(2);
      setState({
        ...state,
        imageValidationError: `Maximum size of an uploaded image is ${maxSize} mb, current size is ${size} mb.`,
      });
    }
    return isValid;
  };

  const preview = formik.values.preview || (formik.values.id && api.articles.preview(formik.values.id)) || undefined;

  return (
    <Box sx={props.sx} component="form" noValidate onSubmit={formik.handleSubmit}>
      <SelectEntityField
        value={formik.values.questionIds}
        name="questionIds"
        label="Link to questions"
        apiCall={api.questions.getOptions}
        handleIdChange={formik.handleChange}
        touched={formik.touched.questionIds}
        error={formik.errors.questionIds}
        multiple
        renderEntity={(x) => (x.active ? x.title : `${x.title} (hidden)`)}
        handleEntityFetch={(x) => formik.setFieldValue("questions", x)}
      />
      <Grid
        sx={{
          width: "max-content",
          m: "8px auto 32px auto",
        }}
      >
        <ImageUploader
          preview={preview}
          handleChange={handlePreviewChange}
          text={{
            placeholder: "No preview",
            upload: "Upload preview",
          }}
        />
        <Typography
          sx={{
            mt: "8px",
            color: theme.palette.error.main,
          }}
          variant="caption"
        >
          {formik.errors.preview}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "16px",
          width: "100%",
          mt: "16px",
        }}
      >
        <TextField
          sx={{
            flex: 1,
          }}
          name={titleField}
          label="Title"
          value={formik.values[titleField]}
          onChange={formik.handleChange}
          error={!!(formik.touched[titleField] && formik.errors[titleField])}
          helperText={formik.errors[titleField] || " "}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          name={linkField}
          label="Website link"
          type="url"
          value={formik.values[linkField]}
          onChange={formik.handleChange}
          error={!!(formik.touched[linkField] && formik.errors[linkField])}
          helperText={formik.errors[linkField] || " "}
        />
      </Grid>
      <Editor
        sx={{
          mt: "16px",
        }}
        content={formik.values[contentField]}
        handleChange={handleContentChange}
        validateImage={validateImage}
      >
        {formik.touched[contentField] && formik.errors[contentField] && (
          <Typography
            sx={{
              mt: "8px",
              ml: "14px",
            }}
            variant="caption"
            color="error"
          >
            {formik.errors[contentField]}
          </Typography>
        )}
      </Editor>
      <ConfirmDialog
        open={!!state.imageValidationError}
        header="Validation error"
        body={state.imageValidationError}
        handleClose={() => setState({ ...state, imageValidationError: null })}
      />
      {props.children}
    </Box>
  );
};

export default ArticleForm;
