import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../../services/api";
import { CommentsFilterParameters } from "../../types/api/comments";

export const fetchCommentsList = createAsyncThunk("@@comments/fetchCommentsList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.comments.getList(state.comments.parameters);
  return response.data;
});

export const setParameters = createAction("@@comments/setParameters", (payload: CommentsFilterParameters) => {
  return {
    payload,
  };
});

export const setIds = createAction("@@comments/setIds", (payload: UniqueId[]) => {
  return {
    payload,
  };
});

export const deleteCommentsList = createAsyncThunk(
  "@@comments/deleteCommentsList",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const response = await api.comments.delete(state.comments.ids);
    dispatch(fetchCommentsList());
    return response.data;
  },
);
