import { FC } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { UserTab } from "../UserDetailsPage";
import { prettyDate } from "../../../../../utils/date";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { usersStateSelector } from "../../../../../store/reducers/users";

interface Props {
  tab: UserTab;
}

const UserProfile: FC<Props> = (props) => {
  const { user } = useTypedSelector(usersStateSelector);

  if (props.tab !== UserTab.Profile || !user) {
    return <></>;
  }

  return (
    <Box
      component="section"
      sx={{
        mt: "24px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: "32px",
      }}
    >
      <Grid
        sx={{
          width: "300px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <TextField label="Firstname" name="firstname" disabled value={user.firstname} />
        <TextField label="Email" name="email" disabled value={user.email} />
        <TextField label="Country" name="country" disabled value={user.country} />
        <TextField label="City" name="city" disabled value={user.city} />
      </Grid>
      <Grid
        sx={{
          width: "300px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <TextField label="User status" name="active" disabled value={user.active ? "Active" : "Inactive"} />
        <TextField
          label="Subscription status"
          name="subscriptionStatus"
          disabled
          value={user.subscriptionActive ? "Active" : "Inactive"}
        />
        <TextField
          label="Registration date"
          name="registrationDate"
          disabled
          value={prettyDate(user.registrationDate)}
        />
      </Grid>
    </Box>
  );
};

export default UserProfile;
