import { LoadingState } from "../../types/common";
import {
  QuestionDetailsDTO,
  QuestionsFilterParameters,
  QuestionShortDTO,
  QuestionSortField,
} from "../../types/api/question";
import { SortOrder } from "../../types/api/common";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  activateQuestion,
  activateQuestionsList,
  addQuestion,
  deactivateQuestion,
  deactivateQuestionsList,
  fetchQuestion,
  fetchQuestionsList,
  setIds,
  setParameters,
  updateQuestion,
} from "../actions/questions";

type QuestionsStateLoadingKeys =
  | "fetchQuestionsList"
  | "fetchQuestion"
  | "addQuestion"
  | "updateQuestion"
  | "activateQuestionsList"
  | "deactivateQuestionsList"
  | "activateQuestion"
  | "deactivateQuestion";

type QuestionsLoadingState = LoadingState<QuestionsStateLoadingKeys>;

interface QuestionsState {
  questions: QuestionShortDTO[];
  question: QuestionDetailsDTO | null;
  parameters: QuestionsFilterParameters;
  totalPages: number;
  totalItems: number;
  ids: UniqueId[];
  loading: QuestionsLoadingState;
}

const initialState: QuestionsState = {
  questions: [],
  question: null,
  parameters: {
    page: 1,
    size: 10,
    sort: QuestionSortField.EditingDate,
    order: SortOrder.Desc,
    search: "",
  },
  totalPages: 0,
  totalItems: 0,
  ids: [],
  loading: {},
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestionsList.pending, (state) => {
        state.loading = { ...state.loading, fetchQuestionsList: true };
      })
      .addCase(fetchQuestionsList.fulfilled, (state, action) => {
        state.questions = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.ids = [];
        state.loading = { ...state.loading, fetchQuestionsList: false };
      })
      .addCase(fetchQuestionsList.rejected, (state) => {
        state.questions = [];
        state.loading = { ...state.loading, fetchQuestionsList: false };
      })
      .addCase(fetchQuestion.pending, (state) => {
        state.question = null;
        state.loading = { ...state.loading, fetchQuestion: true };
      })
      .addCase(fetchQuestion.fulfilled, (state, action) => {
        state.question = action.payload;
        state.loading = { ...state.loading, fetchQuestion: false };
      })
      .addCase(fetchQuestion.rejected, (state) => {
        state.question = null;
        state.loading = { ...state.loading, fetchQuestion: false };
      })
      .addCase(activateQuestionsList.pending, (state) => {
        state.loading = { ...state.loading, activateQuestionsList: true };
      })
      .addCase(activateQuestionsList.fulfilled, (state, action) => {
        action.payload.forEach((x) => {
          if (!x.success) {
            return;
          }
          const question = state.questions.find((question) => question.id === x.id);
          question && (question.active = true);
        });
        state.ids = [];
        state.loading = { ...state.loading, activateQuestionsList: false };
      })
      .addCase(activateQuestionsList.rejected, (state) => {
        state.loading = { ...state.loading, activateQuestionsList: false };
      })
      .addCase(deactivateQuestionsList.pending, (state) => {
        state.loading = { ...state.loading, deactivateQuestionsList: true };
      })
      .addCase(deactivateQuestionsList.fulfilled, (state, action) => {
        action.payload.forEach((x) => {
          if (!x.success) {
            return;
          }
          const question = state.questions.find((question) => question.id === x.id);
          question && (question.active = false);
        });
        state.ids = [];
        state.loading = { ...state.loading, deactivateQuestionsList: false };
      })
      .addCase(deactivateQuestionsList.rejected, (state) => {
        state.loading = { ...state.loading, deactivateQuestionsList: false };
      })
      .addCase(activateQuestion.pending, (state) => {
        state.loading = { ...state.loading, activateQuestion: true };
      })
      .addCase(activateQuestion.fulfilled, (state, action) => {
        if (state.question && action.payload.success) {
          state.question.active = true;
        }
        state.loading = { ...state.loading, activateQuestion: false };
      })
      .addCase(activateQuestion.rejected, (state) => {
        state.loading = { ...state.loading, activateQuestion: false };
      })
      .addCase(deactivateQuestion.pending, (state) => {
        state.loading = { ...state.loading, deactivateQuestion: true };
      })
      .addCase(deactivateQuestion.fulfilled, (state, action) => {
        if (state.question && action.payload.success) {
          state.question.active = false;
        }
        state.loading = { ...state.loading, deactivateQuestion: false };
      })
      .addCase(deactivateQuestion.rejected, (state) => {
        state.loading = { ...state.loading, deactivateQuestion: false };
      })
      .addCase(addQuestion.pending, (state) => {
        state.loading = { ...state.loading, addQuestion: true };
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.question = action.payload;
        state.loading = { ...state.loading, addQuestion: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(addQuestion.rejected, (state) => {
        state.loading = { ...state.loading, addQuestion: false };
      })
      .addCase(updateQuestion.pending, (state) => {
        state.loading = { ...state.loading, updateQuestion: true };
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        state.question = action.payload;
        state.loading = { ...state.loading, updateQuestion: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(updateQuestion.rejected, (state) => {
        state.loading = { ...state.loading, updateQuestion: false };
      })
      .addCase(setParameters, (state, action) => {
        state.parameters = action.payload;
      })
      .addCase(setIds, (state, action) => {
        state.ids = action.payload;
      });
  },
});

export const questionsStateSelector = (state: RootState) => state.questions;

export default questionsSlice.reducer;
