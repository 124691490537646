import { FC, useEffect, useState } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { acneAttackStateSelector } from "../../../../store/reducers/acne-attack";
import { fetchContent, updateContent } from "../../../../store/actions/acne-attack";
import { uiStateSelector } from "../../../../store/reducers/ui";
import urlTemplate from "../../../../url-template/urlTemplate";
import Loader from "../../../../ui-components/loader/Loader";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useFormik } from "formik";
import ConfirmDialog from "../../../../ui-components/confirm-dialog/ConfirmDialog";
import { AcneAttackContentDetailsDTO } from "../../../../types/api/acne-attack";
import AcneAttackContentForm, {
  AcneAttackContentFormValues,
  initialValues,
  validationSchema,
} from "../common/forms/AcneAttackContentForm";
import { isNotFound } from "../../../../utils/api";
import PageHeader from "../../../../ui-components/page-header/PageHeader";
import {
  getArticlesRelatedEntities,
  getQuestionsRelatedEntities,
  validateRelatedEntities,
} from "../../../../utils/validation";

interface UrlParams {
  id: string;
}

interface State {
  openCancelDialog: boolean;
  openSaveDialog: boolean;
  warningMessage: string | null;
}

const initialState: State = {
  openCancelDialog: false,
  openSaveDialog: false,
  warningMessage: null,
};

const EditAcneAttackPage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { content, loading } = useTypedSelector(acneAttackStateSelector);
  const { error } = useTypedSelector(uiStateSelector);
  const params = useParams<keyof UrlParams>();
  const navigate = useNavigate();
  const formik = useFormik<AcneAttackContentFormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const message = validateRelatedEntities([
        getArticlesRelatedEntities(values.articles, values.articleIds),
        getQuestionsRelatedEntities(values.questions, values.questionIds),
      ]);
      setState({ ...state, openSaveDialog: true, warningMessage: message });
    },
  });
  const { setValues } = formik;

  useEffect(() => {
    const id = Number(params.id);
    if (Number.isInteger(id)) {
      dispatch(fetchContent(id));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (content) {
      setValues({
        habitEn: content.habitEn,
        habitEs: content.habitEs,
        articleIds: content.articles.map((x) => x.id),
        questionIds: content.questions.map((x) => x.id),
        articles: [],
        questions: [],
      });
    }
  }, [content, setValues]);

  const handleSaveConfirm = () => {
    const { articles: _, questions: __, ...data } = formik.values;
    dispatch(
      updateContent({
        id: (content as AcneAttackContentDetailsDTO).id,
        data,
        back: () => navigate(urlTemplate.acneAttack.main.value),
      }),
    );
    handleSaveCancel();
  };

  const handleSaveCancel = () => {
    setState({ ...state, openSaveDialog: false });
  };

  if (isNotFound(error)) {
    return <Navigate to={urlTemplate.acneAttack.main.value} />;
  }

  if (loading.fetchContent) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <PageHeader backButton breadCrumbs={[urlTemplate.acneAttack.main, urlTemplate.acneAttack.edit]} />
      <AcneAttackContentForm
        formik={formik}
        sx={{
          width: "50%",
          m: "0 auto",
          mt: "24px",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            mt: "32px",
            "& button": {
              width: "175px",
            },
          }}
        >
          <Button variant="outlined" onClick={() => setState({ ...state, openCancelDialog: true })}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading.updateContent} type="submit">
            {loading.updateContent && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            Save
          </Button>
        </Grid>
      </AcneAttackContentForm>
      <ConfirmDialog
        open={state.openCancelDialog}
        handleClose={() => setState({ ...state, openCancelDialog: false })}
        handleConfirm={() => navigate(urlTemplate.acneAttack.main.value)}
        header="Cancel editing content"
        body="Are you sure you want to undo editing the content? All unsaved changes will be lost"
      />
      <ConfirmDialog
        open={state.openSaveDialog}
        handleClose={handleSaveCancel}
        handleConfirm={handleSaveConfirm}
        loading={loading.updateContent}
        header="Confirmation of changes"
        body={state.warningMessage || "Do you really want to save your changes?"}
      />
    </PageContainer>
  );
};

export default EditAcneAttackPage;
