import React, { FC } from "react";
import { Badge, Tab, Tabs } from "@mui/material";
import urlTemplate from "../../../../../url-template/urlTemplate";
import { CreateArticleTab } from "../CreateArticlePage";
import { HandleChange, Language } from "../../../../../types/common";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

interface Props {
  tab: CreateArticleTab;
  handleTabChange: HandleChange<CreateArticleTab>;
  errors: Record<Language, number>;
}

const CreateArticlePageHeader: FC<Props> = (props) => {
  return (
    <PageHeader backButton breadCrumbs={[urlTemplate.articles.main, urlTemplate.articles.create]}>
      <Tabs
        value={props.tab}
        onChange={(_, value) => props.handleTabChange(value)}
        sx={{
          width: "75%",
          display: "flex",
          margin: "0 auto",
        }}
      >
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            maxWidth: "initial",
            "& .MuiBadge-badge": {
              right: "-32px",
            },
          }}
          label={
            <Badge
              sx={{
                textTransform: "none",
              }}
              badgeContent={`Errors: ${props.errors[Language.En]}`}
              color="error"
              invisible={props.tab !== CreateArticleTab.ArticleEs || !props.errors[Language.En]}
            >
              English article
            </Badge>
          }
        />
        <Tab
          sx={{
            flex: 1,
            fontWeight: 600,
            maxWidth: "initial",
            "& .MuiBadge-badge": {
              right: "-32px",
            },
          }}
          label={
            <Badge
              sx={{
                textTransform: "none",
              }}
              badgeContent={`Errors ${props.errors[Language.Es]}`}
              color="error"
              invisible={props.tab !== CreateArticleTab.ArticleEn || !props.errors[Language.Es]}
            >
              Spanish article
            </Badge>
          }
        />
      </Tabs>
    </PageHeader>
  );
};

export default CreateArticlePageHeader;
