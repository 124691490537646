import React, { FC, MutableRefObject, useRef, useState } from "react";
import { Download } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { AxiosResponse } from "axios";
import { getFilename } from "../../utils/axios";

export interface DownloadButtonProps {
  func: () => Promise<AxiosResponse<Blob>>;

  title?: string;
}

const DownloadButton: FC<DownloadButtonProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const anchorRef = useRef() as MutableRefObject<HTMLAnchorElement>;

  const onClick = () => {
    setLoading(true);
    props
      .func()
      .then((response) => {
        anchorRef.current.href = URL.createObjectURL(response.data);
        const filename = getFilename(response);
        if (filename) {
          anchorRef.current.download = filename;
        }
        anchorRef.current.click();
      })
      .catch((e) => {
        console.error("Download file error", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <IconButton onClick={onClick} disabled={loading} title={props.title}>
      {loading ? <CircularProgress size={25} color="inherit" /> : <Download fontSize="large" />}
      <a ref={anchorRef} hidden />
    </IconButton>
  );
};

export default DownloadButton;
