import { FC, ReactNode, useEffect } from "react";
import { useFormik } from "formik";
import QuoteForm, { initialValues, QuoteFormValues, validationSchema } from "../forms/QuoteForm";
import { EditQuoteRequest, QuoteDTO } from "../../../../../types/api/quote";
import { Action, HandleChange } from "../../../../../types/common";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface Props {
  open: boolean;
  handleClose: Action;
  handleSave: HandleChange<EditQuoteRequest>;
  loading: boolean;

  header?: ReactNode;
  quote?: QuoteDTO | null;
}

const getInitialValues = (quote?: QuoteDTO | null): QuoteFormValues => {
  if (quote) {
    return {
      textEn: quote.textEn,
      textEs: quote.textEs,
    };
  } else {
    return initialValues;
  }
};

const EditQuoteDialog: FC<Props> = (props) => {
  const formik = useFormik<QuoteFormValues>({
    initialValues: getInitialValues(props.quote),
    validationSchema,
    onSubmit: (values) => {
      props.handleSave(values);
    },
  });
  const { setValues, resetForm } = formik;

  useEffect(() => {
    if (props.quote) {
      setValues({
        textEn: props.quote.textEn,
        textEs: props.quote.textEs,
      });
    }
  }, [props.quote, props.open, setValues]);

  useEffect(() => {
    if (!props.open) {
      resetForm();
    }
  }, [props.open, resetForm]);

  const handleSave = async () => {
    await formik.submitForm();
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{props.header}</DialogTitle>
      <DialogContent>
        <QuoteForm
          sx={{
            mt: "16px",
          }}
          formik={formik}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={props.loading}>
          {props.loading && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditQuoteDialog;
