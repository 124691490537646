import { FC, ReactNode } from "react";
import { Button, SxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";

export interface BackButtonProps {
  to?: URI;
  children?: ReactNode;
  title?: string;
  sx?: SxProps;
}

const BackButton: FC<BackButtonProps> = (props) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    props.to ? navigate(props.to) : navigate(-1);
  };

  return (
    <Button onClick={handleBackClick} variant="contained" title={props.title} sx={props.sx}>
      <ChevronLeft />
      {props.children || "Back"}
    </Button>
  );
};

export default BackButton;
