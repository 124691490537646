import { FC } from "react";
import { EditorTools, EditorToolsSettings } from "@progress/kendo-react-editor";
import FontSizeProps = EditorTools.FontSizeProps;

const options: Array<{ text: string; value: string }> = [
  {
    text: "8 (48pt)",
    value: "48pt",
  },
  {
    text: "9 (56pt)",
    value: "56pt",
  },
  {
    text: "10 (60pt)",
    value: "60pt",
  },
  {
    text: "11 (72pt)",
    value: "72pt",
  },
];

const settings = {
  ...EditorToolsSettings.fontSize,
  items: [...EditorToolsSettings.fontSize.items, ...options],
};

const Controls = EditorTools.createStyleDropDownList(settings);

const AdditionalFontSize: FC = (props: FontSizeProps) => {
  return <Controls {...props} />;
};

export default AdditionalFontSize;
