import authApiService from "./AuthApiService";
import accountApiService from "./AccountApiService";
import usersApiService from "./UsersApiService";
import articlesApiService from "./ArticlesApiService";
import commentApiService from "./CommentsApiService";
import quotesApiService from "./QuotesApiService";
import acneAttackApiService from "./AcneAttackApiService";
import maintainPerfectApiService from "./MaintainPerfectApiService";
import sectionsApiService from "./SectionsApiService";
import questionsApiService from "./QuestionsApiService";
import documentApiService from "./DocumentApiService";

const api = {
  auth: authApiService,
  account: accountApiService,
  users: usersApiService,
  articles: articlesApiService,
  comments: commentApiService,
  quotes: quotesApiService,
  acneAttack: acneAttackApiService,
  maintainPerfect: maintainPerfectApiService,
  sections: sectionsApiService,
  questions: questionsApiService,
  documents: documentApiService,
};

export default api;
