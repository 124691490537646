import axios, { AxiosRequestHeaders, CreateAxiosDefaults } from "axios";
import authStorage from "../../AuthStorage";
import { TokenResponse } from "../../../types/api/auth";
import { ErrorCode, ErrorResponse } from "../../../types/api/error";
import { Action, Language } from "../../../types/common";

const createClientConfig: CreateAxiosDefaults = {
  headers: {
    "Accept-Language": Language.En,
  },
};

const client = axios.create(createClientConfig);

export const configureClient = (onError: (error: ErrorResponse) => void, onLogout: Action) => {
  client.interceptors.request.use(
    (config) => {
      const authData = authStorage.get();
      if (authData) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${authData.accessToken}`,
        } as AxiosRequestHeaders;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const authData = authStorage.get();
      const data = error.response && error.response.data;

      if (isErrorResponse(data)) {
        if (data.code === ErrorCode.AuthBadToken) {
          // logout immediately if token in storage has wrong value
          onLogout();
        } else if (data.code === ErrorCode.AuthTokenExpired && !originalRequest._retry && authData) {
          // try to refresh token
          originalRequest._retry = true;
          try {
            console.debug("Access token is expired. Try to refresh tokens...");
            const tokenResponse = await refreshToken(authData.refreshToken);
            authStorage.set(tokenResponse);
            originalRequest.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
            console.debug("Tokens successfully refreshed");
            return client(originalRequest);
          } catch (_error) {
            console.debug("Tokens refresh fail. Logout");
            onLogout();
            return Promise.reject(error);
          }
        }
      }

      if (error.response && error.response.data) {
        // use callback if error response has any data
        onError(error.response.data);
      }

      return Promise.reject(error);
    },
  );

  const refreshToken = (refreshToken: Token): Promise<TokenResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("/api/v1/auth/refresh", {
          refreshToken,
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };
};

const isErrorResponse = (value: any): value is ErrorResponse => {
  if (typeof value === "object") {
    const fields: Array<keyof ErrorResponse> = ["code", "message"];
    return fields.every((x) => value[x] !== undefined);
  }
  return false;
};

export default client;
