import React, { FC, useEffect } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import UsersPageHeader from "./header/UsersPageHeader";
import UsersTable from "./table/UsersTable";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import { fetchUsersList } from "../../../../store/actions/users";

const UsersPage: FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchUsersList());
  }, [dispatch]);

  return (
    <PageContainer>
      <UsersPageHeader />
      <UsersTable />
    </PageContainer>
  );
};

export default UsersPage;
