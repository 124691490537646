import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import urlTemplate from "../../url-template/urlTemplate";
import LoginPage from "../pages/login/LoginPage";

const PublicRoutes: FC = () => {
  return (
    <Routes>
      <Route path={urlTemplate.login.main.value} element={<LoginPage />} />
      <Route path="*" element={<Navigate to={urlTemplate.login.main.value} />} />
    </Routes>
  );
};

export default PublicRoutes;
