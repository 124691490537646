import { FC, ReactNode } from "react";
import { useFormik } from "formik";
import { Box, Grid, SxProps, TextField } from "@mui/material";
import * as yup from "yup";
import { StringSchema } from "yup";
import { getRequiredStringSchema } from "../../../../../validation/schemas";

export interface QuoteFormValues {
  textEn: string;
  textEs: string;
}

export const initialValues: QuoteFormValues = {
  textEn: "",
  textEs: "",
};

const textSchema: StringSchema = getRequiredStringSchema("Quote text is required").max(
  255,
  "The length of the text must be no more than 255 characters",
);

export const validationSchema = yup.object().shape({
  textEn: textSchema,
  textEs: textSchema,
});

export type QuoteFormikHelpers = ReturnType<typeof useFormik<QuoteFormValues>>;

interface Props {
  formik: QuoteFormikHelpers;

  children?: ReactNode;
  sx?: SxProps;
}

const QuoteForm: FC<Props> = (props) => {
  const { formik } = props;

  return (
    <Box sx={props.sx} component="form" noValidate onSubmit={formik.handleSubmit}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <TextField
          sx={{ flex: 1 }}
          name="textEn"
          label="Inspirational Quote in English"
          value={formik.values.textEn}
          onChange={formik.handleChange}
          error={!!(formik.touched.textEn && formik.errors.textEn)}
          helperText={formik.errors.textEn || " "}
          multiline
          rows={5}
        />
        <TextField
          sx={{ flex: 1 }}
          name="textEs"
          label="Inspirational Quote in Spanish"
          value={formik.values.textEs}
          onChange={formik.handleChange}
          error={!!(formik.touched.textEs && formik.errors.textEs)}
          helperText={formik.errors.textEs || " "}
          multiline
          rows={5}
        />
      </Grid>
      {props.children}
    </Box>
  );
};

export default QuoteForm;
