import { AnswerContentDTO, AnswerItemContentDTO, AnswerType } from "../../../../../types/api/question";
import { QuestionFormikHelpers } from "./QuestionForm";
import { Grid, SxProps, TextField } from "@mui/material";
import { InputHTMLAttributes, ReactNode } from "react";
import { DeepFormikHelpers, getAnswerFormikHelpers, getAnswerItemFormikHelpers } from "../../../../../utils/form";

export interface AnswerFieldAttributes {
  label: string;

  readonly?: boolean;
  renderValue?: (value: any) => any;
  type?: InputHTMLAttributes<any>["type"];
  sx?: SxProps;
}

export type AnswerFields<T> = Partial<Record<keyof T, AnswerFieldAttributes>>;

interface Props<T extends AnswerContentDTO | AnswerItemContentDTO> {
  type: AnswerType;
  formik: QuestionFormikHelpers;

  fieldRows?: Array<AnswerFields<T>>;
  isArrayItem?: boolean;
  itemIndex?: number;
  renderOther?: (helpers: DeepFormikHelpers<T>) => ReactNode;
  rowSx?: SxProps;
}

const AnswerFormFields = <T extends AnswerContentDTO | AnswerItemContentDTO>(props: Props<T>): JSX.Element => {
  const helpers =
    props.isArrayItem && typeof props.itemIndex === "number"
      ? getAnswerItemFormikHelpers<T>(props.formik, props.type, props.itemIndex)
      : getAnswerFormikHelpers<T>(props.formik, props.type);
  const { values, touched, errors, getFieldName } = helpers;

  return (
    <>
      {props.fieldRows &&
        props.fieldRows.map((fields, i) => (
          <Grid
            key={i}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              width: "100%",
              ...props.rowSx,
            }}
          >
            {Object.entries(fields).map(([fieldKey, attrs]) => {
              const key = fieldKey as keyof T & string;
              const { type, label, sx, readonly, renderValue } = attrs as AnswerFieldAttributes;

              if (readonly) {
                const value = (renderValue && renderValue(values[key])) || values[key];
                return (
                  <TextField sx={{ flex: 1, ...sx }} disabled key={key} label={label} value={value} helperText=" " />
                );
              }

              return (
                <TextField
                  key={key}
                  sx={{ flex: 1, ...sx }}
                  name={getFieldName(key)}
                  label={label}
                  type={type}
                  value={values[key]}
                  onChange={props.formik.handleChange}
                  error={!!(touched[key] && errors[key])}
                  helperText={(errors[key] || " ") as string}
                />
              );
            })}
          </Grid>
        ))}
      {props.renderOther && props.renderOther(helpers)}
    </>
  );
};

export default AnswerFormFields;
