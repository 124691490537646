import {
  EditQuestionRequest,
  QuestionDetailsDTO,
  QuestionOptionDTO,
  QuestionsFilterParameters,
  QuestionShortDTO,
} from "../../types/api/question";
import { AxiosResponse } from "axios";
import { PageResponse, UpdateEntityStatusResponse } from "../../types/api/common";
import client from "./client/client";

export class QuestionsApiService {
  async getList(parameters: QuestionsFilterParameters): Promise<AxiosResponse<PageResponse<QuestionShortDTO>>> {
    return await client.get("/api/v1/admin/question/list", {
      params: parameters,
    });
  }

  async getOptions(text?: string): Promise<AxiosResponse<QuestionOptionDTO[]>> {
    return await client.get("/api/v1/admin/question/options", {
      params: { text },
    });
  }

  async get(id: UniqueId): Promise<AxiosResponse<QuestionDetailsDTO>> {
    return await client.get(`/api/v1/admin/question/${id}`);
  }

  async add(request: EditQuestionRequest): Promise<AxiosResponse<QuestionDetailsDTO>> {
    return await client.post("/api/v1/admin/question", request);
  }

  async update(id: UniqueId, request: EditQuestionRequest): Promise<AxiosResponse<QuestionDetailsDTO>> {
    return await client.post(`/api/v1/admin/question/${id}`, request);
  }

  async activate(ids: UniqueId[]): Promise<AxiosResponse<UpdateEntityStatusResponse[]>> {
    return await client.post("/api/v1/admin/question/activate", ids);
  }

  async deactivate(ids: UniqueId[]): Promise<AxiosResponse<UpdateEntityStatusResponse[]>> {
    return await client.post("/api/v1/admin/question/deactivate", ids);
  }
}

const questionsApiService = new QuestionsApiService();

export default questionsApiService;
