import { LoginRequest, TokenResponse } from "../../types/api/auth";
import { AxiosResponse } from "axios";
import client from "./client/client";

export class AuthApiService {
  async login(request: LoginRequest): Promise<AxiosResponse<TokenResponse>> {
    return await client.post("/api/v1/auth/login-admin", request);
  }
}

const authApiService = new AuthApiService();

export default authApiService;
