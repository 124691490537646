import { EntityOptionDTO, PageRequest, PageResponse } from "./common";

export interface MaintainPerfectContentShortDTO {
  id: UniqueId;
  topicEn: string;
  topicEs: string;
  startDate: DateString;
  relatedArticles: string;
}

export interface MaintainPerfectContentDetailsDTO {
  id: UniqueId;
  topicEn: string;
  topicEs: string;
  startDate: DateString;
  articles: EntityOptionDTO[];
}

export interface EditMaintainPerfectContentRequest {
  topicEn: string;
  topicEs: string;
  startDate: DateString;
  articleIds: UniqueId[];
}

export enum MaintainPerfectContentSortField {
  StartDate = "startDate",
}

export interface MaintainPerfectContentFilterParameters extends PageRequest<MaintainPerfectContentSortField> {
  search?: string;
}

export interface MaintainPerfectContentPageResponse extends PageResponse<MaintainPerfectContentShortDTO> {
  currentWeekPage?: number;
}
