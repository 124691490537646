import { PageRequest } from "./common";

export interface CommentDTO {
  id: UniqueId;
  username: string;
  userId: UniqueId;
  articleTitleEn: string;
  articleTitleEs: string;
  articleId: UniqueId;
  text: string;
  creationDate: DateString;
}

export enum CommentSortField {
  Username = "username",
  CreationDate = "creationDate",
  ArticleTitle = "articleTitle",
}

export interface CommentsFilterParameters extends PageRequest<CommentSortField> {
  userId: UniqueId | null;
  articleId: UniqueId | null;
}
