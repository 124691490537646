import { FC, useEffect } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import { fetchQuestionsList } from "../../../../store/actions/questions";
import QuestionsPageHeader from "./header/QuestionsPageHeader";
import QuestionsTable from "./table/QuestionsTable";

const QuestionsPage: FC = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchQuestionsList());
  }, [dispatch]);

  return (
    <PageContainer>
      <QuestionsPageHeader />
      <QuestionsTable />
    </PageContainer>
  );
};

export default QuestionsPage;
