import { LoadingState } from "../../types/common";
import { AcneAttackContentDetailsDTO, AcneAttackContentShortDTO } from "../../types/api/acne-attack";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { fetchContent, fetchContentList, updateContent } from "../actions/acne-attack";

type AcneAttackStateLoadingKeys = "fetchContentList" | "fetchContent" | "updateContent";
type AcneAttackLoadingState = LoadingState<AcneAttackStateLoadingKeys>;

interface AcneAttackState {
  contents: AcneAttackContentShortDTO[];
  content: AcneAttackContentDetailsDTO | null;
  loading: AcneAttackLoadingState;
}

const initialState: AcneAttackState = {
  contents: [],
  content: null,
  loading: {},
};

const acneAttackSlice = createSlice({
  name: "acneAttack",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentList.pending, (state) => {
        state.loading = { ...state.loading, fetchContentList: true };
      })
      .addCase(fetchContentList.fulfilled, (state, action) => {
        state.contents = action.payload;
        state.loading = { ...state.loading, fetchContentList: false };
      })
      .addCase(fetchContentList.rejected, (state) => {
        state.contents = [];
        state.loading = { ...state.loading, fetchContentList: false };
      })
      .addCase(fetchContent.pending, (state) => {
        state.content = null;
        state.loading = { ...state.loading, fetchContent: true };
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.content = action.payload;
        state.loading = { ...state.loading, fetchContent: false };
      })
      .addCase(fetchContent.rejected, (state) => {
        state.content = null;
        state.loading = { ...state.loading, fetchContent: false };
      })
      .addCase(updateContent.pending, (state) => {
        state.loading = { ...state.loading, updateContent: true };
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.content = action.payload;
        state.loading = { ...state.loading, updateContent: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(updateContent.rejected, (state) => {
        state.loading = { ...state.loading, updateContent: false };
      });
  },
});

export const acneAttackStateSelector = (state: RootState) => state.acneAttack;

export default acneAttackSlice.reducer;
