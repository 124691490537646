import { FC, useState } from "react";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import QuestionForm, { getInitialValues, QuestionFormValues, validationSchema } from "../common/forms/QuestionForm";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { questionsStateSelector } from "../../../../store/reducers/questions";
import { addQuestion } from "../../../../store/actions/questions";
import urlTemplate from "../../../../url-template/urlTemplate";
import { Button, CircularProgress, Divider, Grid } from "@mui/material";
import ConfirmDialog from "../../../../ui-components/confirm-dialog/ConfirmDialog";
import { removeEmptyFields } from "../../../../utils/object";
import PageHeader from "../../../../ui-components/page-header/PageHeader";
import {
  getArticlesRelatedEntities,
  getCategoriesRelatedEntities,
  validateRelatedEntities,
} from "../../../../utils/validation";

interface State {
  openCancelDialog: boolean;
  openSaveDialog: boolean;
  warningMessage: string | null;
}

const initialState: State = {
  openCancelDialog: false,
  openSaveDialog: false,
  warningMessage: null,
};

const CreateQuestionPage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { loading } = useTypedSelector(questionsStateSelector);
  const navigate = useNavigate();
  const formik = useFormik<QuestionFormValues>({
    initialValues: getInitialValues(),
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {
      // ignored
    },
  });

  const handleSaveConfirm = () => {
    if (!formik.isValid) {
      return;
    }
    const { maxSequenceNumber: _, articles: __, categories: ___, ...values } = formik.values;
    dispatch(
      addQuestion({
        data: {
          ...removeEmptyFields(values),
          answerContent: formik.values.answerContent[formik.values.answerType],
        },
        back: () => navigate(urlTemplate.questions.main.value),
      }),
    );
  };

  const handleSaveClick = async () => {
    await formik.submitForm();
    if (formik.isValid) {
      const relatedEntities = [];
      relatedEntities.push(getCategoriesRelatedEntities(formik.values.categories, [formik.values.categoryId]));
      formik.values.articleId && relatedEntities.push(getArticlesRelatedEntities(formik.values.articles, [formik.values.articleId]));
      const message = validateRelatedEntities(relatedEntities);
      setState({ ...state, openSaveDialog: true, warningMessage: message });
    }
  };

  return (
    <PageContainer>
      <PageHeader backButton breadCrumbs={[urlTemplate.questions.main, urlTemplate.questions.create]} />
      <Divider sx={{ mt: "16px" }} />
      <QuestionForm
        sx={{
          width: "75%",
          m: "0 auto",
          mt: "24px",
        }}
        formik={formik}
        question={null}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            mt: "32px",
            "& button": {
              width: "175px",
            },
          }}
        >
          <Button variant="outlined" onClick={() => setState({ ...state, openCancelDialog: true })}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading.addQuestion} onClick={handleSaveClick}>
            {loading.addQuestion && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            Save Changes
          </Button>
        </Grid>
      </QuestionForm>
      <ConfirmDialog
        open={state.openCancelDialog}
        handleClose={() => setState({ ...state, openCancelDialog: false })}
        handleConfirm={() => navigate(urlTemplate.sections.main.value)}
        header="Cancel adding an question"
        body="Do you really want to abort the process of adding an question?"
      />
      <ConfirmDialog
        open={state.openSaveDialog}
        handleClose={() => setState({ ...state, openSaveDialog: false })}
        handleConfirm={handleSaveConfirm}
        loading={loading.addQuestion}
        header="Confirmation of changes"
        body={state.warningMessage || "Do you really want to save your changes?"}
      />
    </PageContainer>
  );
};

export default CreateQuestionPage;
