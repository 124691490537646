import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../../../ui-components/page-container/PageContainer";
import CreateArticlePageHeader from "./header/CreateArticlePageHeader";
import ArticleForm, { initialValues, ArticleFormValues, validationSchema } from "../common/forms/ArticleForm";
import { Language } from "../../../../types/common";
import { useFormik } from "formik";
import { Button, CircularProgress, Divider, Grid } from "@mui/material";
import useTypedDispatch from "../../../../hooks/useTypedDispatch";
import { addArticle } from "../../../../store/actions/articles";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import { articlesStateSelector } from "../../../../store/reducers/articles";
import urlTemplate from "../../../../url-template/urlTemplate";
import ConfirmDialog from "../../../../ui-components/confirm-dialog/ConfirmDialog";
import { getQuestionsRelatedEntities, validateRelatedEntities } from "../../../../utils/validation";

export enum CreateArticleTab {
  ArticleEn,
  ArticleEs,
}

interface State {
  tab: CreateArticleTab;
  openCancelDialog: boolean;
  openSaveDialog: boolean;
  openSaveAndPublishDialog: boolean;
  warningMessage: string | null;
}

type SaveDialogKeys = keyof Pick<State, "openSaveDialog" | "openSaveAndPublishDialog">;

const initialState: State = {
  tab: CreateArticleTab.ArticleEn,
  openCancelDialog: false,
  openSaveDialog: false,
  openSaveAndPublishDialog: false,
  warningMessage: null,
};

const CreateArticlePage: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const formik = useFormik<ArticleFormValues>({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: () => {
      // ignored
    },
  });
  const dispatch = useTypedDispatch();
  const { loading } = useTypedSelector(articlesStateSelector);
  const navigate = useNavigate();
  const mainErrors = [formik.errors.preview];
  const englishErrors = [...mainErrors, formik.errors.titleEn, formik.errors.contentEn, formik.errors.linkEn].filter(
    (x) => !!x,
  );
  const spanishErrors = [...mainErrors, formik.errors.titleEs, formik.errors.contentEs, formik.errors.linkEs].filter(
    (x) => !!x,
  );

  const handleSaveConfirm = (publish: boolean | null = null) => {
    if (!formik.isValid) {
      return;
    }
    const { preview, id: _, questions: __, ...values } = formik.values;
    dispatch(
      addArticle({
        data: {
          request: {
            ...values,
            publish,
          },
          preview: preview as File,
        },
        back: () => navigate(urlTemplate.articles.main.value),
      }),
    );
    setState({ ...state, openSaveDialog: false, openSaveAndPublishDialog: false });
  };

  const handleSaveClick = async (dialogKey: SaveDialogKeys) => {
    await formik.submitForm();
    if (formik.isValid) {
      const message = validateRelatedEntities([
        getQuestionsRelatedEntities(formik.values.questions, formik.values.questionIds),
      ]);
      setState({ ...state, [dialogKey]: true, warningMessage: message });
    }
  };

  return (
    <PageContainer>
      <CreateArticlePageHeader
        tab={state.tab}
        handleTabChange={(tab) => setState({ ...state, tab })}
        errors={{
          [Language.En]: englishErrors.length,
          [Language.Es]: spanishErrors.length,
        }}
      />
      <Divider />
      <ArticleForm
        sx={{
          width: "75%",
          m: "0 auto",
          mt: "24px",
        }}
        language={state.tab === CreateArticleTab.ArticleEn ? Language.En : Language.Es}
        formik={formik}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            mt: "32px",
            "& button": {
              width: "175px",
            },
          }}
        >
          <Button variant="outlined" onClick={() => setState({ ...state, openCancelDialog: true })}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading.addArticle} onClick={() => handleSaveClick("openSaveDialog")}>
            {loading.addArticle && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            Save Changes
          </Button>
          <Button
            variant="contained"
            disabled={loading.addArticle}
            onClick={() => handleSaveClick("openSaveAndPublishDialog")}
          >
            {loading.addArticle && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            Save & Publish
          </Button>
        </Grid>
      </ArticleForm>
      <ConfirmDialog
        open={state.openCancelDialog}
        handleClose={() => setState({ ...state, openCancelDialog: false })}
        handleConfirm={() => navigate(urlTemplate.articles.main.value)}
        header="Cancel adding an article"
        body="Do you really want to abort the process of adding an article?"
      />
      <ConfirmDialog
        open={state.openSaveDialog}
        handleClose={() => setState({ ...state, openSaveDialog: false })}
        handleConfirm={() => handleSaveConfirm()}
        loading={loading.addArticle}
        header="Confirmation of changes"
        body={state.warningMessage || "Do you really want to save your changes?"}
      />
      <ConfirmDialog
        open={state.openSaveAndPublishDialog}
        handleClose={() => setState({ ...state, openSaveAndPublishDialog: false })}
        handleConfirm={() => handleSaveConfirm(true)}
        loading={loading.addArticle}
        header="Confirmation of changes"
        body={state.warningMessage || "Do you really want to save your changes and publish the article?"}
      />
    </PageContainer>
  );
};

export default CreateArticlePage;
