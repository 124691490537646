import { CSSProperties, FC, ReactNode } from "react";
import { HandleChange } from "../../types/common";
import {
  Editor as KendoEditor,
  EditorChangeEvent,
  EditorMountEvent,
  EditorTools,
  EditorUtils,
  ProseMirror,
} from "@progress/kendo-react-editor";
import { Box, SxProps } from "@mui/material";
import insertImagePlugin, { handleInsert, ValidateImage } from "./plugins/image";
import InsertImage, { InsertImageToolsProps } from "./tools/insert-image";
import AdditionalFontSize from "./tools/additional-font-size";

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

interface EditorStyles {
  container?: CSSProperties;
  content?: CSSProperties;
}

export interface EditorProps {
  content: HTMLString;
  handleChange: HandleChange<HTMLString>;

  validateImage?: ValidateImage;
  sx?: SxProps;
  styles?: EditorStyles;
  children?: ReactNode;
}

const Editor: FC<EditorProps> = (props) => {
  const styles = props.styles || ({} as EditorStyles);

  const handleChange = (e: EditorChangeEvent) => {
    props.handleChange(e.html);
  };

  const handleMount = (e: EditorMountEvent) => {
    const state = e.viewProps.state;
    const plugins = [
      ...state.plugins,
      insertImagePlugin(handleInsert, props.validateImage),
      EditorUtils.imageResizing(),
      ...EditorUtils.tableResizing(),
    ];

    return new ProseMirror.EditorView(
      { mount: e.dom },
      {
        ...e.viewProps,
        state: ProseMirror.EditorState.create({ doc: state.doc, plugins }),
      },
    );
  };

  const InsertImageTools = (otherProps: InsertImageToolsProps) => {
    return <InsertImage {...otherProps} validate={props.validateImage} />;
  };

  return (
    <Box sx={props.sx}>
      <KendoEditor
        onMount={handleMount}
        tools={[
          [Bold, Italic, Underline, Strikethrough],
          [Subscript, Superscript],
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          [Indent, Outdent],
          [OrderedList, UnorderedList],
          [AdditionalFontSize],
          FontName,
          FormatBlock,
          [Undo, Redo],
          [Link, Unlink, InsertImageTools, ViewHtml],
          [InsertTable],
          [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
          [DeleteRow, DeleteColumn, DeleteTable],
          [MergeCells, SplitCell],
        ]}
        resizable
        value={props.content}
        onChange={handleChange}
        style={{
          width: "100%",
          ...styles.container,
        }}
        contentStyle={{
          minHeight: "600px",
          ...styles.content,
        }}
      />
      {props.children}
    </Box>
  );
};

export default Editor;
