import { AnswerForm, getDefaultAnswerContent } from "./QuestionForm";
import { Button, Grid, Paper } from "@mui/material";
import { FieldArray, FormikProvider } from "formik";
import { AnswerType, DropdownAnswerContentDTO, DropdownAnswerContentItemDTO } from "../../../../../types/api/question";
import AnswerFormFields from "./AnswerFormFields";
import { getAnswerFormikHelpers } from "../../../../../utils/form";
import OrderControls from "../../../../../ui-components/order-controlrs/OrderControls";

const getDefaultItem = (): DropdownAnswerContentItemDTO => {
  const defaultAnswerContent = getDefaultAnswerContent(AnswerType.Dropdown) as DropdownAnswerContentDTO;
  return defaultAnswerContent.items[0];
};

const DropdownAnswerForm: AnswerForm = ({ formik }) => {
  const { values, getFieldName } = getAnswerFormikHelpers<DropdownAnswerContentDTO>(formik, AnswerType.Dropdown);
  const removeDisabled = formik.values.answerContent[AnswerType.Dropdown].items.length === 2;

  return (
    <FormikProvider value={formik}>
      <FieldArray name={getFieldName("items")}>
        {({ push, remove, swap }) => (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Button sx={{ width: "175px" }} onClick={() => push(getDefaultItem())} variant="contained">
              Add Answer Item
            </Button>
            {values.items.map((x, i, items) => (
              <Paper
                key={i}
                elevation={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  padding: "16px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <OrderControls
                    increaseControl={{
                      disabled: i === 0,
                      handleClick: () => swap(i, i - 1),
                    }}
                    decreaseControl={{
                      disabled: i === items.length - 1,
                      handleClick: () => swap(i, i + 1),
                    }}
                  />
                  <Button
                    sx={{
                      width: "175px",
                      alignSelf: "flex-end",
                    }}
                    variant="outlined"
                    disabled={removeDisabled}
                    onClick={() => remove(i)}
                  >
                    Remove Item
                  </Button>
                </Grid>
                <AnswerFormFields<DropdownAnswerContentItemDTO>
                  type={AnswerType.Dropdown}
                  formik={formik}
                  isArrayItem
                  itemIndex={i}
                  fieldRows={[
                    {
                      weight: {
                        type: "number",
                        label: `Option ${i + 1} Weight (%)`,
                        sx: {
                          flex: "none",
                          width: "calc(50% - 16px / 2)",
                        },
                      },
                    },
                    {
                      textEn: {
                        label: `Option ${i + 1} Text (EN)`,
                      },
                      textEs: {
                        label: `Option ${i + 1} Text (ES)`,
                      },
                    },
                  ]}
                />
              </Paper>
            ))}
          </Grid>
        )}
      </FieldArray>
    </FormikProvider>
  );
};

export default DropdownAnswerForm;
