import { FC, ReactNode } from "react";
import { Action } from "../../types/common";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export interface ConfirmDialogProps {
  open: boolean;
  handleClose: Action;

  handleConfirm?: Action;
  loading?: boolean;
  header?: ReactNode;
  body?: ReactNode;
  closeButtonContent?: ReactNode;
  confirmButtonContent?: ReactNode;
}

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>{props.header}</DialogTitle>
      <DialogContent>
        <DialogContentText whiteSpace="pre-line" lineHeight="150%">
          {props.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>{props.closeButtonContent || "Close"}</Button>
        {props.handleConfirm && (
          <Button onClick={props.handleConfirm} disabled={props.loading}>
            {props.loading && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
            {props.confirmButtonContent || "Confirm"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
