import { FC, FormEvent, useState } from "react";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { articlesStateSelector } from "../../../../../store/reducers/articles";
import {
  deleteArticlesList,
  fetchArticlesList,
  publishArticlesList,
  setParameters,
  unpublishArticlesList,
} from "../../../../../store/actions/articles";
import { Box, Button, Grid } from "@mui/material";
import SearchField from "../../../../../ui-components/search-field/SearchField";
import { NavLink } from "react-router-dom";
import urlTemplate from "../../../../../url-template/urlTemplate";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";

interface State {
  openPublishArticlesDialog: boolean;
  openUnpublishArticlesDialog: boolean;
  openDeleteArticlesDialog: boolean;
}

const initialState: State = {
  openPublishArticlesDialog: false,
  openUnpublishArticlesDialog: false,
  openDeleteArticlesDialog: false,
};

const ArticlesPageHeader: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { parameters, ids, loading } = useTypedSelector(articlesStateSelector);
  const actionsDisabled = ids.length === 0;

  const handleSearchStart = () => {
    dispatch(fetchArticlesList());
  };

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearchStart();
  };

  const handleSearchChange = (search: string) => {
    dispatch(setParameters({ ...parameters, search, page: 1 }));
  };

  const handlePublishConfirm = () => {
    dispatch(publishArticlesList());
    handlePublishClose();
  };

  const handlePublishClose = () => {
    setState({ ...state, openPublishArticlesDialog: false });
  };

  const handleUnpublishConfirm = () => {
    dispatch(unpublishArticlesList());
    handleUnpublishClose();
  };

  const handleUnpublishClose = () => {
    setState({ ...state, openUnpublishArticlesDialog: false });
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteArticlesList());
    handleDeleteClose();
  };

  const handleDeleteClose = () => {
    setState({ ...state, openDeleteArticlesDialog: false });
  };

  return (
    <PageHeader breadCrumbs={[urlTemplate.articles.main]}>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => setState({ ...state, openPublishArticlesDialog: true })}
          >
            Publish
          </Button>
          <Button
            variant="contained"
            disabled={actionsDisabled}
            onClick={() => setState({ ...state, openUnpublishArticlesDialog: true })}
          >
            Unpublish
          </Button>
          <Button
            variant="outlined"
            disabled={actionsDisabled}
            onClick={() => setState({ ...state, openDeleteArticlesDialog: true })}
          >
            Delete
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box component="form" noValidate onSubmit={handleSearchSubmit}>
            <SearchField
              placeholder="Article title"
              value={parameters.search as string}
              handleChange={handleSearchChange}
              handleSearchStart={handleSearchStart}
              delayMillis={500}
              startSearchLength={2}
            />
          </Box>
          <Button variant="contained" component={NavLink} to={urlTemplate.articles.create.value}>
            Add New
          </Button>
        </Grid>
        <ConfirmDialog
          open={!!(state.openPublishArticlesDialog || loading.publishArticlesList)}
          handleClose={handlePublishClose}
          handleConfirm={handlePublishConfirm}
          loading={loading.publishArticlesList}
          header="Publication of articles"
          body="Are you sure you want to publish the selected articles?"
        />
        <ConfirmDialog
          open={!!(state.openUnpublishArticlesDialog || loading.unpublishArticlesList)}
          handleClose={handleUnpublishClose}
          handleConfirm={handleUnpublishConfirm}
          loading={loading.unpublishArticlesList}
          header="Unpublication of articles"
          body="Are you sure you want to unpublish the selected articles?"
        />
        <ConfirmDialog
          open={!!(state.openDeleteArticlesDialog || loading.deleteArticlesList)}
          handleClose={handleDeleteClose}
          handleConfirm={handleDeleteConfirm}
          loading={loading.deleteArticlesList}
          header="Deleting articles"
          body="Are you sure you want to delete the selected articles? All comments related to articles will also be deleted"
        />
      </Grid>
    </PageHeader>
  );
};

export default ArticlesPageHeader;
