import { FC } from "react";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { articlesStateSelector } from "../../../../../store/reducers/articles";
import Table, { SortParameters, TableColumn } from "../../../../../ui-components/table/Table";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { prettyDate } from "../../../../../utils/date";
import { ArticlesFilterParameters, ArticleShortDTO, ArticleSortField } from "../../../../../types/api/articles";
import { fetchArticlesList, setIds, setParameters } from "../../../../../store/actions/articles";
import { PaginationParameters } from "../../../../../ui-components/pagination/Pagination";
import { enumToArray } from "../../../../../utils/enum";
import { useNavigate } from "react-router-dom";
import { getAppUrl } from "../../../../../url-template/urlTemplate";

const ArticlesTable: FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { articles, parameters, ids, totalItems, totalPages, loading } = useTypedSelector(articlesStateSelector);

  const fetchArticles = (parameters: ArticlesFilterParameters) => {
    dispatch(setParameters(parameters));
    dispatch(fetchArticlesList());
  };

  const handlePaginationChange = ({ page, size }: PaginationParameters) => {
    fetchArticles({ ...parameters, page, size });
  };

  const handleSortChange = ({ sort, order }: SortParameters<ArticleSortField>) => {
    fetchArticles({ ...parameters, sort, order });
  };

  const handleIdsChange = (ids: UniqueId[]) => {
    dispatch(setIds(ids));
  };

  const handleRowClick = (item: ArticleShortDTO) => {
    navigate(getAppUrl.articles.edit(item.id));
  };

  const columns: TableColumn<ArticleShortDTO>[] = [
    {
      field: "titleEn",
      alias: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "published",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => (params.value ? "Published" : "Draft"),
      flex: 1,
    },
    {
      field: "editingDate",
      headerName: "Last editing",
      renderCell: (params: GridRenderCellParams) => prettyDate(params.value),
      flex: 1,
    },
  ];

  return (
    <Table
      sx={{
        mt: "16px",
      }}
      getRowId={(row) => row.id}
      data={articles}
      cols={columns}
      handleSortChange={handleSortChange}
      order={parameters.order}
      sort={parameters.sort}
      sortFields={enumToArray<ArticleSortField>(ArticleSortField)}
      pagination={{
        handlePaginationChange,
        page: parameters.page,
        size: parameters.size,
        totalPages,
        totalItems,
      }}
      loading={loading.fetchArticlesList}
      selectedRows={ids}
      handleSelectedRowsChange={handleIdsChange}
      checkboxSelection
      handleRowClick={handleRowClick}
    />
  );
};

export default ArticlesTable;
