import { FC, ReactNode } from "react";
import { Grid, SxProps } from "@mui/material";
import BackButton, { BackButtonProps } from "../back-button/BackButton";
import Breadcrumbs, { BreadCrumbsProps } from "../breadcrumbs/Breadcrumbs";
import { AppLink } from "../../types/routes";

export interface PageHeaderProps {
  backButton?: BackButtonProps | boolean;
  breadCrumbs?: BreadCrumbsProps | AppLink[];
  children?: ReactNode;
  sx?: SxProps;
}

const PageHeader: FC<PageHeaderProps> = (props) => {
  const renderBackButton = () => {
    if (!props.backButton) {
      return <></>;
    }

    return <BackButton {...(typeof props.backButton === "object" ? props.backButton : {})} />;
  };

  const renderBreadCrumbs = () => {
    if (!props.breadCrumbs) {
      return <></>;
    }

    return <Breadcrumbs {...(Array.isArray(props.breadCrumbs) ? { items: props.breadCrumbs } : props.breadCrumbs)} />;
  };

  return (
    <Grid
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
        ...props.sx,
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "24px",
        }}
      >
        {renderBackButton()}
        {renderBreadCrumbs()}
      </Grid>
      {props.children}
    </Grid>
  );
};

export default PageHeader;
