export const prettyDate = (value: DateString | Date | number) => {
  return new Date(value).toLocaleDateString();
};

export const getFirstDayOfWeek = (value: Date | DateString) => {
  const date = new Date(value);
  const dayOfWeek = date.getDay();
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

export const toInputDateFormat = (value: Date | DateString) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dayOfMonth = date.getDate();
  const format = (value: number) => (value < 10 ? "0" + value : value);
  return [year, format(month), format(dayOfMonth)].join("-");
};
