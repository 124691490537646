import { FC, ReactNode } from "react";
import { StringSchema } from "yup";
import * as yup from "yup";
import { getEntityIdsSchema, getRequiredStringSchema } from "../../../../../validation/schemas";
import { useFormik } from "formik";
import { Box, Grid, SxProps, TextField } from "@mui/material";
import SelectEntityField from "../../../../common/SelectEntityField";
import api from "../../../../../services/api";
import { ArticleOptionDTO } from "../../../../../types/api/articles";
import { QuestionOptionDTO } from "../../../../../types/api/question";

export interface AcneAttackContentFormValues {
  habitEn: string;
  habitEs: string;
  articleIds: UniqueId[];
  questionIds: UniqueId[];
  // articles and questions used only for validation
  articles: ArticleOptionDTO[];
  questions: QuestionOptionDTO[];
}

export const initialValues: AcneAttackContentFormValues = {
  habitEn: "",
  habitEs: "",
  articleIds: [],
  questionIds: [],
  articles: [],
  questions: [],
};

const habitSchema: StringSchema = getRequiredStringSchema("Habit is required");

export const validationSchema = yup.object().shape({
  habitEn: habitSchema,
  habitEs: habitSchema,
  articleIds: getEntityIdsSchema("You must select at least one article"),
  questionIds: getEntityIdsSchema("You must select at least one question"),
});

export type AcneAttackContentFormikHelpers = ReturnType<typeof useFormik<AcneAttackContentFormValues>>;

interface Props {
  formik: AcneAttackContentFormikHelpers;

  children?: ReactNode;
  sx?: SxProps;
}

const AcneAttackContentForm: FC<Props> = (props) => {
  const { formik } = props;

  return (
    <Box sx={props.sx} component="form" noValidate onSubmit={formik.handleSubmit}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "16px",
          width: "100%",
        }}
      >
        <TextField
          sx={{
            flex: 1,
          }}
          name="habitEn"
          label="Habit text (EN)"
          value={formik.values.habitEn}
          onChange={formik.handleChange}
          error={!!(formik.touched.habitEn && formik.errors.habitEn)}
          helperText={formik.errors.habitEn || " "}
        />
        <TextField
          sx={{
            flex: 1,
          }}
          name="habitEs"
          label="Habit text (ES)"
          value={formik.values.habitEs}
          onChange={formik.handleChange}
          error={!!(formik.touched.habitEs && formik.errors.habitEs)}
          helperText={formik.errors.habitEs || " "}
        />
      </Grid>
      <SelectEntityField
        sx={{
          mt: "16px",
        }}
        value={formik.values.articleIds}
        name="articleIds"
        label="Related articles"
        handleIdChange={formik.handleChange}
        apiCall={api.articles.getOptions}
        multiple
        touched={formik.touched.articleIds}
        error={formik.errors.articleIds}
        renderEntity={(x) => (x.published ? x.title : `${x.title} (draft)`)}
        handleEntityFetch={(x) => formik.setFieldValue("articles", x)}
      />
      <SelectEntityField
        sx={{
          mt: "16px",
        }}
        value={formik.values.questionIds}
        name="questionIds"
        label="Related questions"
        handleIdChange={formik.handleChange}
        apiCall={api.questions.getOptions}
        multiple
        touched={formik.touched.questionIds}
        error={formik.errors.questionIds}
        renderEntity={(x) => (x.active ? x.title : `${x.title} (hidden)`)}
        handleEntityFetch={(x) => formik.setFieldValue("questions", x)}
      />
      {props.children}
    </Box>
  );
};

export default AcneAttackContentForm;
