import { createSlice } from "@reduxjs/toolkit";
import { AccountResponse } from "../../types/api/account";
import { LoadingState } from "../../types/common";
import { fetchAccount, login, logout } from "../actions/account";
import { RootState } from "../store";

type AccountStateLoadingKeys = "login" | "fetchAccount";
type AccountLoadingState = LoadingState<AccountStateLoadingKeys>;

interface AccountState {
  account: AccountResponse | null;
  loading: AccountLoadingState;
}

const initialState: AccountState = {
  account: null,
  loading: {},
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = { ...state.loading, login: true };
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = { ...state.loading, login: false };
      })
      .addCase(login.rejected, (state) => {
        state.loading = { ...state.loading, login: false };
      })
      .addCase(fetchAccount.pending, (state) => {
        state.account = null;
        state.loading = { ...state.loading, fetchAccount: true };
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.account = action.payload;
        state.loading = { ...state.loading, fetchAccount: false };
      })
      .addCase(fetchAccount.rejected, (state) => {
        state.account = null;
        state.loading = { ...state.loading, fetchAccount: false };
      })
      .addCase(logout, (state) => {
        state.account = null;
      });
  },
});

export const accountStateSelector = (state: RootState) => state.account;

export default accountSlice.reducer;
