import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../../services/api";
import { EditQuoteRequest, QuoteDTO, QuotesFilterParameters } from "../../types/api/quote";

export const fetchQuotesList = createAsyncThunk("@@quotes/fetchQuotesList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.quotes.getList(state.quotes.parameters);
  return response.data;
});

export const setParameters = createAction("@@quotes/setParameters", (payload: QuotesFilterParameters) => {
  return {
    payload,
  };
});

export const setIds = createAction("@@quites/setIds", (payload: UniqueId[]) => {
  return {
    payload,
  };
});

export const selectQuote = createAction("@@quotes/selectQuote", (payload: QuoteDTO | null) => {
  return {
    payload,
  };
});

export const addQuote = createAsyncThunk("@@quotes/addQuote", async (payload: EditQuoteRequest, { dispatch }) => {
  const response = await api.quotes.add(payload);
  dispatch(fetchQuotesList());
  return response.data;
});

export const updateQuote = createAsyncThunk(
  "@@quotes/updateQuote",
  async (payload: { id: UniqueId; data: EditQuoteRequest }) => {
    const response = await api.quotes.update(payload.id, payload.data);
    return response.data;
  },
);

export const deleteQuotesList = createAsyncThunk("@@quotes/deleteQuotesList", async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const response = await api.quotes.delete(state.quotes.ids);
  dispatch(fetchQuotesList());
  return response.data;
});
