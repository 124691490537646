import { LoadingState } from "../../types/common";
import {
  MaintainPerfectContentDetailsDTO,
  MaintainPerfectContentFilterParameters,
  MaintainPerfectContentShortDTO,
  MaintainPerfectContentSortField,
} from "../../types/api/maintain-perfect";
import { createSlice } from "@reduxjs/toolkit";
import { SortOrder } from "../../types/api/common";
import { addContent, fetchContent, fetchContentList, setParameters, updateContent } from "../actions/maintain-perfect";
import { RootState } from "../store";

type MaintainPerfectStateLoadingKeys = "fetchContentList" | "fetchContent" | "addContent" | "updateContent";
type MaintainPerfectLoadingState = LoadingState<MaintainPerfectStateLoadingKeys>;

interface MaintainPerfectState {
  contents: MaintainPerfectContentShortDTO[];
  content: MaintainPerfectContentDetailsDTO | null;
  parameters: MaintainPerfectContentFilterParameters;
  currentWeekPage: null | number;
  totalPages: number;
  totalItems: number;
  loading: MaintainPerfectLoadingState;
}

const initialState: MaintainPerfectState = {
  contents: [],
  content: null,
  parameters: {
    page: 1,
    size: 10,
    sort: MaintainPerfectContentSortField.StartDate,
    order: SortOrder.Desc,
    search: "",
  },
  currentWeekPage: null,
  totalPages: 0,
  totalItems: 0,
  loading: {},
};

const maintainPerfectSlice = createSlice({
  name: "maintainPerfect",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentList.pending, (state) => {
        state.loading = { ...state.loading, fetchContentList: true };
      })
      .addCase(fetchContentList.fulfilled, (state, action) => {
        state.contents = action.payload.content;
        state.currentWeekPage = action.payload.currentWeekPage || null;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.loading = { ...state.loading, fetchContentList: false };
      })
      .addCase(fetchContentList.rejected, (state) => {
        state.contents = [];
        state.loading = { ...state.loading, fetchContentList: false };
      })
      .addCase(fetchContent.pending, (state) => {
        state.content = null;
        state.loading = { ...state.loading, fetchContent: true };
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.content = action.payload;
        state.loading = { ...state.loading, fetchContent: false };
      })
      .addCase(fetchContent.rejected, (state) => {
        state.content = null;
        state.loading = { ...state.loading, fetchContent: false };
      })
      .addCase(addContent.pending, (state) => {
        state.loading = { ...state.loading, addContent: true };
      })
      .addCase(addContent.fulfilled, (state, action) => {
        state.loading = { ...state.loading, addContent: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(addContent.rejected, (state) => {
        state.loading = { ...state.loading, addContent: false };
      })
      .addCase(updateContent.pending, (state) => {
        state.loading = { ...state.loading, updateContent: true };
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.content = action.payload;
        state.loading = { ...state.loading, updateContent: false };
        action.meta.arg.back && action.meta.arg.back();
      })
      .addCase(updateContent.rejected, (state) => {
        state.loading = { ...state.loading, updateContent: false };
      })
      .addCase(setParameters, (state, action) => {
        state.parameters = action.payload;
      });
  },
});

export const maintainPerfectStateSelector = (state: RootState) => state.maintainPerfect;

export default maintainPerfectSlice.reducer;
