export const readFile = (file: File): Promise<{ src: Base64; file: File }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve({ src: reader.result, file });
      } else {
        reject(null);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const isFile = (value: any): value is File => {
  const commonFields = ["name", "lastModified", "size"];
  return typeof value === "object" && commonFields.every((x) => value[x] !== undefined);
};

export const getFileSizeMb = (file: File): number => {
  return file.size / 1024 / 1024;
};
