import React, { FC, FormEvent, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import SearchField from "../../../../../ui-components/search-field/SearchField";
import useTypedDispatch from "../../../../../hooks/useTypedDispatch";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { usersStateSelector } from "../../../../../store/reducers/users";
import {
  deactivateUsersList,
  fetchUsersList,
  reactivateUsersList,
  setParameters,
} from "../../../../../store/actions/users";
import ConfirmDialog from "../../../../../ui-components/confirm-dialog/ConfirmDialog";
import DownloadButton from "../../../../../ui-components/download-button/DownloadButton";
import api from "../../../../../services/api";
import PageHeader from "../../../../../ui-components/page-header/PageHeader";
import urlTemplate from "../../../../../url-template/urlTemplate";

interface State {
  openReactivateUsersDialog: boolean;
  openDeactivateUsersDialog: boolean;
}

const initialState: State = {
  openDeactivateUsersDialog: false,
  openReactivateUsersDialog: false,
};

const UsersPageHeader: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { parameters, ids, loading } = useTypedSelector(usersStateSelector);
  const activityActionsDisabled = ids.length === 0;

  const handleSearchStart = () => {
    dispatch(fetchUsersList());
  };

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearchStart();
  };

  const handleSearchChange = (search: string) => {
    dispatch(setParameters({ ...parameters, search, page: 1 }));
  };

  const handleReactivateConfirm = () => {
    dispatch(reactivateUsersList());
    handleReactivateClose();
  };

  const handleReactivateClose = () => {
    setState({ ...state, openReactivateUsersDialog: false });
  };

  const handleDeactivateConfirm = () => {
    dispatch(deactivateUsersList());
    handleDeactivateClose();
  };

  const handleDeactivateClose = () => {
    setState({ ...state, openDeactivateUsersDialog: false });
  };

  return (
    <PageHeader breadCrumbs={[urlTemplate.users.main]}>
      <Grid
        component="section"
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            variant="contained"
            disabled={activityActionsDisabled}
            onClick={() => setState({ ...state, openReactivateUsersDialog: true })}
          >
            Reactivate
          </Button>
          <Button
            variant="contained"
            disabled={activityActionsDisabled}
            onClick={() => setState({ ...state, openDeactivateUsersDialog: true })}
          >
            Deactivate
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <DownloadButton func={api.documents.getUsersDocument} title="Download users excel document" />
          <Box component="form" noValidate onSubmit={handleSearchSubmit}>
            <SearchField
              placeholder="User name or email"
              value={parameters.search as string}
              handleChange={handleSearchChange}
              handleSearchStart={handleSearchStart}
              delayMillis={500}
              startSearchLength={2}
            />
          </Box>
        </Grid>
        <ConfirmDialog
          open={!!(state.openReactivateUsersDialog || loading.reactivateUsersList)}
          handleClose={handleReactivateClose}
          handleConfirm={handleReactivateConfirm}
          loading={loading.reactivateUsersList}
          header="User reactivation"
          body="Do you really want to reactivate the selected users?"
        />
        <ConfirmDialog
          open={!!(state.openDeactivateUsersDialog || loading.deactivateUsersList)}
          handleClose={handleDeactivateClose}
          handleConfirm={handleDeactivateConfirm}
          loading={loading.deactivateUsersList}
          header="Users deactivation"
          body="Do you really want to deactivate the selected users?"
        />
      </Grid>
    </PageHeader>
  );
};

export default UsersPageHeader;
