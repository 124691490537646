import { AxiosResponse } from "axios";
import { AccountResponse } from "../../types/api/account";
import client from "./client/client";

export class AccountApiService {
  async get(): Promise<AxiosResponse<AccountResponse>> {
    return await client.get("/api/v1/admin/account");
  }
}

const accountApiService = new AccountApiService();

export default accountApiService;
