export type Action = () => void;

export type LoadingState<T extends string> = Partial<Record<T, boolean>>;

export type HandleChange<T> = (value: T) => void;

export enum Language {
  En = "en-US",
  Es = "es-ES",
}

export type LocaleFields<D extends {}, T extends keyof D> = Record<Language, keyof Pick<D, T>>;

export interface AddActionPayload<D extends {}> {
  data: D;
  back?: Action;
}

export interface UpdateActionPayload<D extends {}> extends AddActionPayload<D> {
  id: UniqueId;
}
