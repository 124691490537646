import { AxiosResponse } from "axios";

export const getFilename = (response: AxiosResponse): Filename | null => {
  const header = response.headers["content-disposition"] as string;
  if (!header) {
    return null;
  }
  const start = header.indexOf('"') + 1;
  const end = header.lastIndexOf('"');
  return header.substring(start, end);
};
