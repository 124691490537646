import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import authStorage from "../../services/AuthStorage";
import api from "../../services/api";
import { LoginRequest } from "../../types/api/auth";

export const login = createAsyncThunk("@@account/login", async (payload: LoginRequest, { dispatch }) => {
  const response = await api.auth.login(payload);
  authStorage.set(response.data);
  dispatch(fetchAccount());
  return response.data;
});

export const fetchAccount = createAsyncThunk("@@account/fetchAccount", async () => {
  const response = await api.account.get();
  return response.data;
});

export const logout = createAction("@@account/logout", () => {
  authStorage.remove();
  return {
    payload: null,
  };
});
