import { createSlice } from "@reduxjs/toolkit";
import { ErrorResponse } from "../../types/api/error";
import { clearError, showError } from "../actions/ui";
import { RootState } from "../store";

interface UiState {
  error: ErrorResponse | null;
}

const initialState: UiState = {
  error: null,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(showError, (state, action) => {
        state.error = action.payload;
      })
      .addCase(clearError, (state) => {
        state.error = null;
      });
  },
});

export const uiStateSelector = (state: RootState) => state.ui;

export default uiSlice.reducer;
