import { FC } from "react";
import { UserTab } from "../UserDetailsPage";
import { Box } from "@mui/material";
import useTypedSelector from "../../../../../hooks/useTypedSelector";
import { usersStateSelector } from "../../../../../store/reducers/users";
import CommentsSection from "../../../../common/comments/CommentsSection";

interface Props {
  tab: UserTab;
}

const UserActivity: FC<Props> = (props: Props) => {
  const { user } = useTypedSelector(usersStateSelector);

  if (props.tab !== UserTab.Activity || !user) {
    return <></>;
  }

  return (
    <Box
      component="section"
      sx={{
        width: "75%",
        margin: "0 auto",
      }}
    >
      <CommentsSection
        sx={{
          mt: "16px",
        }}
        userId={user.id}
        disableTableColumns={["username"]}
      />
    </Box>
  );
};

export default UserActivity;
