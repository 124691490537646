import { FC } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useTypedSelector from "../../../hooks/useTypedSelector";
import { uiStateSelector } from "../../../store/reducers/ui";
import useTypedDispatch from "../../../hooks/useTypedDispatch";
import { clearError } from "../../../store/actions/ui";

const ErrorDialog: FC = () => {
  const dispatch = useTypedDispatch();
  const { error } = useTypedSelector(uiStateSelector);

  const handleClose = () => {
    dispatch(clearError());
  };

  if (!error) {
    return <></>;
  }

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <DialogContentText>{error.message || "Unknown error"}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
