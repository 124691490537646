import { FC, useState } from "react";
import useTypedDispatch from "../../../hooks/useTypedDispatch";
import { login } from "../../../store/actions/account";
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useTypedSelector from "../../../hooks/useTypedSelector";
import { accountStateSelector } from "../../../store/reducers/account";
import PageContainer from "../../../ui-components/page-container/PageContainer";

interface LoginFormValues {
  email: Email;
  password: Password;
}

const loginFormInitialValues: LoginFormValues = {
  email: "",
  password: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().required("Email is required").email("Invalid email address"),
  password: yup.string().required("Password is required"),
});

interface State {
  showPassword: boolean;
}

const initialState: State = {
  showPassword: false,
};

const LoginPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { loading } = useTypedSelector(accountStateSelector);
  const [state, setState] = useState<State>(initialState);

  const handleLoginFormSubmit = (values: LoginFormValues) => {
    dispatch(login(values));
  };

  const handleShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  return (
    <PageContainer
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <img src="/logo192.png" alt="logotype" />
        <Formik
          initialValues={loginFormInitialValues}
          onSubmit={handleLoginFormSubmit}
          validationSchema={validationSchema}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => (
            <Box
              component="form"
              sx={{
                width: "300px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "16px",
              }}
              noValidate
              onSubmit={handleSubmit}
            >
              <TextField
                name="email"
                label="Email"
                type="email"
                value={values.email}
                onChange={handleChange}
                error={!!(touched.email && errors.email)}
                helperText={errors.email || " "}
              />
              <TextField
                name="password"
                label="Password"
                type={state.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange}
                error={!!(touched.password && errors.password)}
                helperText={errors.password || " "}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        {state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button type="submit" variant="contained" disabled={loading.login}>
                {loading.login && <CircularProgress size={20} color="inherit" sx={{ mr: "8px" }} />}
                Login
              </Button>
            </Box>
          )}
        </Formik>
      </Grid>
    </PageContainer>
  );
};

export default LoginPage;
