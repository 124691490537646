import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UserDTO, UsersFilterParameters } from "../../types/api/users";
import api from "../../services/api";
import { RootState } from "../store";

export const fetchUsersList = createAsyncThunk("@@users/fetchUsersList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.users.getList(state.users.parameters);
  return response.data;
});

export const setParameters = createAction("@@users/setParameters", (payload: UsersFilterParameters) => {
  return {
    payload,
  };
});

export const setIds = createAction("@@users/setIds", (payload: UniqueId[]) => {
  return {
    payload,
  };
});

export const fetchUser = createAsyncThunk("@@users/fetchUser", async (payload: UniqueId) => {
  const response = await api.users.get(payload);
  return response.data;
});

export const reactivateUsersList = createAsyncThunk("@@users/reactivateUsersList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.users.reactivate(state.users.ids);
  return response.data;
});

export const reactivateUser = createAsyncThunk("@@users/reactivateUser", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.users.reactivate([(state.users.user as UserDTO).id]);
  return response.data[0];
});

export const deactivateUsersList = createAsyncThunk("@@users/deactivateUsersList", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.users.deactivate(state.users.ids);
  return response.data;
});

export const deactivateUser = createAsyncThunk("@@users/deactivateUser", async (_, { getState }) => {
  const state = getState() as RootState;
  const response = await api.users.deactivate([(state.users.user as UserDTO).id]);
  return response.data[0];
});
