import React, { FC, useState, MouseEvent } from "react";
import { AppBar, Button, IconButton, MenuItem, Toolbar, Menu, Grid, Typography } from "@mui/material";
import { ArrowDropDown, Menu as MenuIcon } from "@mui/icons-material";
import useTypedSelector from "../../hooks/useTypedSelector";
import { accountStateSelector } from "../../store/reducers/account";
import LogoutDialog from "../common/dialogs/LogoutDialog";
import useTypedDispatch from "../../hooks/useTypedDispatch";
import { logout } from "../../store/actions/account";
import Navigation from "../navigation/Navigation";

interface State {
  menuAnchorEl: HTMLElement | null;
  logoutDialogOpen: boolean;
  navigationOpen: boolean;
}

const initialState: State = {
  menuAnchorEl: null,
  logoutDialogOpen: false,
  navigationOpen: false,
};

const Header: FC = () => {
  const [state, setState] = useState<State>(initialState);
  const dispatch = useTypedDispatch();
  const { account } = useTypedSelector(accountStateSelector);

  const handleOpenNavigation = () => {
    setState({ ...state, navigationOpen: true });
  };

  const handleCloseNavigation = () => {
    setState({ ...state, navigationOpen: false });
  };

  const handleMenuOpen = (e: MouseEvent<HTMLElement>) => {
    setState({ ...state, menuAnchorEl: e.currentTarget });
  };

  const handleMenuClose = () => {
    setState({ ...state, menuAnchorEl: null });
  };

  const handleLogoutClick = () => {
    setState({ ...state, menuAnchorEl: null, logoutDialogOpen: true });
  };

  const handleLogoutDialogClose = () => {
    setState({ ...state, logoutDialogOpen: false });
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <IconButton size="large" edge="start" color="inherit" title="Toggle sidebar" onClick={handleOpenNavigation}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h1" fontSize="20px">
            My Healthy Skin
          </Typography>
        </Grid>
        <Grid>
          <Button color="inherit" onClick={handleMenuOpen}>
            {account?.firstname}
            <ArrowDropDown
              sx={{
                ml: "8px",
              }}
            />
          </Button>
          <Menu
            open={!!state.menuAnchorEl}
            onClose={handleMenuClose}
            anchorEl={state.menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </Grid>
      </Toolbar>
      <LogoutDialog open={state.logoutDialogOpen} handleClose={handleLogoutDialogClose} handleLogout={handleLogout} />
      <Navigation open={state.navigationOpen} handleClose={handleCloseNavigation} />
    </AppBar>
  );
};

export default Header;
