import { TokenResponse } from "../types/api/auth";

export class AuthStorage {
  private readonly _rootKey = "authentication";

  set(data: TokenResponse) {
    localStorage.setItem(this._rootKey, JSON.stringify(data));
  }

  get(): TokenResponse | null {
    try {
      return JSON.parse(localStorage.getItem(this._rootKey) as string);
    } catch (error) {
      return null;
    }
  }

  remove() {
    localStorage.removeItem(this._rootKey);
  }
}

const authStorage = new AuthStorage();

export default authStorage;
