import { AxiosResponse } from "axios";
import client from "./client/client";

export class DocumentApiService {
  public async getUsersDocument(): Promise<AxiosResponse<Blob>> {
    return await client.get("/api/v1/admin/document/user", {
      responseType: "blob",
    });
  }

  public async getAnswersDocument(): Promise<AxiosResponse<Blob>> {
    return await client.get("/api/v1/admin/document/answer", {
      responseType: "blob",
    });
  }
}

const documentApiService = new DocumentApiService();

export default documentApiService;
